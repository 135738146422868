import { useTranslation } from "react-i18next";

import Models from "./Models";
import { VehiclesProps } from "./types";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";

import { DROPDOWN_SIZES } from "../../../../../../../../shared";

const Vehicles = ({
    isSubmitLoading,
    isVehiclesLoading,
    vehicles,
    makes,
    make,
    model,
    changeMake,
    changeModel
}: VehiclesProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Autocomplete
                fullWidth
                isRequired
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("Dialog##make")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                loading={isVehiclesLoading}
                readOnly={isSubmitLoading}
                value={make}
                options={makes}
                onChange={(_, value) => changeMake(value)}
            />

            {vehicles && make && (
                <Models
                    data={vehicles[make]}
                    value={model}
                    isLoading={isSubmitLoading}
                    change={changeModel}
                />
            )}
        </>
    );
};

export default Vehicles;
