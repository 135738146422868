import { useEffect, useState } from "react";

import {
    Make,
    Model,
    Vehicles
} from "../SecondSelectContent/CanVehicles/types";
import { sortVehicles } from "../functions";
import { SelectedTask } from "../types";

import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    TASK_TYPES,
    useApi,
    useIsMounted
} from "../../../../../../../shared";

const useCanVehicles = (selectedTask: SelectedTask) => {
    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState<Vehicles>(null);
    const [makes, setMakes] = useState<string[]>([]);
    const [make, setMake] = useState<Make>(null);
    const [model, setModel] = useState<Model>(null);

    useEffect(() => {
        setMake(null);
        setModel(null);

        const getVehicles = async () => {
            try {
                setLoading(true);

                const { data } = await getData(ENDPOINTS.Vehicles);

                if (isMounted) {
                    sortVehicles(data, setVehicles, setMakes);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        const isUploadCanOemFileTask =
            selectedTask?.type === TASK_TYPES.TxCanConfiguration;

        isUploadCanOemFileTask && getVehicles();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [selectedTask?.type]);

    const changeMake = (makeName: string) => {
        setMake(makeName);
        setModel(null);
    };

    const changeModel = (vehicleInfo: Model) => setModel(vehicleInfo);

    return {
        additionalData: {
            isVehiclesLoading: isLoading,
            vehicles,
            makes,
            make,
            model
        },
        changeMake,
        changeModel
    };
};

export { useCanVehicles };
