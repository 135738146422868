import { useTranslation } from "react-i18next";

import { ModelsProps } from "./types";

import { renderVehicleModel } from "../../functions";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";

import {
    DROPDOWN_SIZES,
    sortAndReturnArray
} from "../../../../../../../../shared";

const Models = ({ data, value, isLoading, change }: ModelsProps) => {
    const { t } = useTranslation();

    const sortedData = sortAndReturnArray(data, "model");

    return (
        <Autocomplete
            fullWidth
            isRequired
            size={DROPDOWN_SIZES.Medium}
            labelLeft={t("Dialog##vehicle model")}
            textFieldParams={{
                placeholder: t("Dialog##select")
            }}
            options={sortedData}
            value={value}
            getOptionLabel={option => renderVehicleModel(option)}
            renderOption={(props, option) => (
                <li {...props} key={option.vehicle_id}>
                    {renderVehicleModel(option)}
                </li>
            )}
            readOnly={isLoading}
            onChange={(_, value) => change(value)}
            customStyle={{
                marginTop: "16px"
            }}
        />
    );
};

export default Models;
