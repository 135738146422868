import { RectangleLoader } from "../../../../../../../../shared";

const Loader = () => {
    return (
        <>
            <RectangleLoader height={20} />
            <RectangleLoader height={20} />
        </>
    );
};

export default Loader;
