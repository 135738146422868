/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import { useDialog } from "../Actions/ActionBar/hooks";
import AddDevice from "../Actions/dialogs/Devices/AddDevice";
import AddProductCode from "../Actions/dialogs/ProductCodes/AddProductCode";
import { DEVICE_ACTIONS, PRODUCT_CODE_ACTIONS } from "../Actions/types";

import AuthContext from "../../context/auth/authContext";
import TableContext from "../../context/table/tableContext";
import TableIsEmptyIcon from "../../assets/customIcons/emptyTable/TableIsEmptyIcon";
import { TABLE_NAMES } from "../../shared";

const NoInitialResults = () => {
    const { isAdminUser, isAdminManagerUser } = useContext(AuthContext);
    const { tableName } = useContext(TableContext);

    const { t } = useTranslation();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const isAddDevice = tableName === TABLE_NAMES.Devices && isAdminUser;

    const isAddProductCode =
        tableName === TABLE_NAMES.ProductCodes && isAdminManagerUser;

    const isButtonDisplayed = isAddDevice || isAddProductCode;

    const handleOpen = () => {
        const action = isAddDevice
            ? DEVICE_ACTIONS.AddDevice
            : PRODUCT_CODE_ACTIONS.AddProductCode;

        openDialog(action);
    };

    return (
        <Fragment>
            <div>
                <TableIsEmptyIcon
                    css={css({
                        fontSize: "120px"
                    })}
                />
            </div>

            <div>
                {t(
                    `Table##empty table##no initial results##title##${tableName}`
                )}
            </div>

            <div>
                {t(
                    `Table##empty table##no initial results##description##${tableName}`
                )}
            </div>

            {isButtonDisplayed && (
                <Fragment>
                    <Button
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={handleOpen}
                    >
                        {t(`Table##empty table##button##add##${tableName}`)}
                    </Button>

                    <AddDevice
                        isOpen={isOpenDialog[DEVICE_ACTIONS.AddDevice]}
                        close={closeDialog}
                    />

                    <AddProductCode
                        isOpen={
                            isOpenDialog[PRODUCT_CODE_ACTIONS.AddProductCode]
                        }
                        close={closeDialog}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default NoInitialResults;
