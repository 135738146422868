/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { GeneralFileDataProps } from "./types";

import SingleDataItem from "../SingleDataItem";

import File from "../../../../../TableDetails/components/File";

const GeneralFileData = ({ id, file }: GeneralFileDataProps) => {
    const { t } = useTranslation();

    return (
        <>
            <h4>{t("General##file data")}</h4>

            <SingleDataItem name={t("Table##file id")} data={id} />

            <File
                data={file}
                css={css({
                    gridColumn: "1/-1",
                    marginTop: "4px"
                })}
            />
        </>
    );
};

export default GeneralFileData;
