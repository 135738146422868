import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isSpecificValueCorrect } from "./functions";
import { InnerComponentProps } from "./types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../../DesignComponents/InputField";

const SpecificLogs = ({
    order,
    value,
    isLoading,
    change
}: InnerComponentProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setError(null);
    }, [order]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isCorrectValue = isSpecificValueCorrect(newValue);

        const errorValue =
            newValue && !isCorrectValue ? t("Dialog##invalid value") : null;

        const getPendingValue = () => {
            const valueArray = newValue.split(",");
            return valueArray.filter(element => element.length > 0);
        };

        const pendingValue = {
            count: String(getPendingValue().length),
            pending: getPendingValue(),
            rawValue: newValue
        };

        setError(errorValue);
        change(pendingValue);
    };

    return (
        <InputField
            fullWidth
            required
            size={INPUT_FIELD_SIZES.Medium}
            labelLeft={t("Dialog##files to download")}
            placeholder="1,2,3,4... (max 10 values split by ',')"
            value={value}
            errorText={error}
            readOnly={isLoading}
            onChange={handleChange}
        />
    );
};

export default SpecificLogs;
