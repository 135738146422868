/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import useCompanyFilterContent from "./hooks/useCompanyFilterContent";
import CompanyFilterLoader from "./components/CompanyFilterLoader";
import Avatar from "./components/Avatar";
import FilterDesktop from "./FilterDesktop";
import FilterMobile from "./FilterMobile";

import {
    getCompanyFilterButtonTitle,
    getRootCompanyButtonTitle
} from "../functions";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import ParamsContext from "../../../context/params/paramsContext";
import AuthContext from "../../../context/auth/authContext";
import { FILTER_NAMES } from "../../../shared";

const CompanyFilter = () => {
    const { filterDropdownOptions } = useContext(ParamsContext);
    const { isSupportUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const {
        isLoading,
        isDialogOpen,
        anchorEl,
        rootCompanyId,
        companyFilteredValues,
        toSm,
        toMd,
        openDialog,
        closeDialog,
        openMenu,
        closeMenu
    } = useCompanyFilterContent();

    const buttonTitle = isSupportUser
        ? getRootCompanyButtonTitle(
              companyFilteredValues,
              filterDropdownOptions,
              rootCompanyId as string,
              t
          )
        : getCompanyFilterButtonTitle(
              companyFilteredValues,
              t("Button##all companies"),
              t("General##companies")
          );

    const renderButtons = () => {
        const handleClickFromMd = toMd ? openDialog : openMenu;

        return toSm ? (
            <Button
                id="companyFilterOpenBtnMobile"
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconOnly}
                icon={<Avatar filteredValues={companyFilteredValues} />}
                onClick={openDialog}
            />
        ) : (
            <Button
                id="companyFilterOpenBtn"
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconLeft}
                icon={<Avatar filteredValues={companyFilteredValues} />}
                onClick={handleClickFromMd}
            >
                <span
                    css={css({
                        maxWidth: "142px",
                        textOverflow: "ellipsis",
                        overflowX: "hidden"
                    })}
                >
                    {buttonTitle}
                </span>
            </Button>
        );
    };

    const renderContent = () =>
        toMd ? (
            <FilterMobile
                isOpen={isDialogOpen}
                isLoading={isLoading}
                close={closeDialog}
            />
        ) : (
            <FilterDesktop
                anchorEl={anchorEl}
                isLoading={isLoading}
                close={closeMenu}
            />
        );

    const rootCompanyOptions = filterDropdownOptions[FILTER_NAMES.RootCompany];

    const isShowLoader =
        isLoading &&
        !isDialogOpen &&
        !anchorEl &&
        (!isSupportUser || rootCompanyOptions === undefined);

    return isShowLoader ? (
        <CompanyFilterLoader />
    ) : (
        <>
            {renderButtons()}
            {renderContent()}
        </>
    );
};

export default CompanyFilter;
