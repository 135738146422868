import { SetStateAction } from "react";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import InfoBanner from "../InfoBanner";
import { UNSUPPORTED_FIRMWARE_LIST } from "../SecondSelectContent/GeneralDropdown/unsupportedFirmwareList";
import { CanAdapterType } from "../SecondSelectContent/CanAdapters/types";
import {
    SortedVehicles,
    Vehicle,
    VehicleInfo,
    Vehicles
} from "../SecondSelectContent/CanVehicles/types";
import { AdditionalData } from "../SecondSelectContent/GeneralDropdown/types";
import {
    CUSTOM_SECOND_SELECT,
    SecondSelectType
} from "../SecondSelectContent/types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../../DesignComponents/TextLink";

import {
    getFirstLetterUppercase,
    PLATFORMS,
    sortAndReturnArray
} from "../../../../../../../shared";

const renderCanAdapterType = (adapter: CanAdapterType) =>
    `${adapter.type} (${adapter.seo_name})`;

const renderVehicleModel = (option: VehicleInfo) => {
    const { model, year_start, year_end } = option;

    const yearEnd = year_end ? `-${year_end}` : "+";
    const year = `(${year_start}${yearEnd})`;

    return `${model} ${year}`;
};

const getParsedAttributes = (attributes: string | null) => {
    if (attributes) {
        try {
            const parsedData = JSON.parse(attributes);
            const firmwareVersion: string | null = parsedData?.version || null;
            const specId: number | null = parsedData?.spec_id || null;

            return { firmwareVersion, specId };
        } catch (error) {
            return null;
        }
    }

    return null;
};

const isUnsupportedFirmware = (attributes: string | null) => {
    const parsedData = getParsedAttributes(attributes);

    if (parsedData?.firmwareVersion) {
        const { firmwareVersion, specId } = parsedData;

        return UNSUPPORTED_FIRMWARE_LIST.some(
            item => item.fwVersion === firmwareVersion && item.specId === specId
        );
    }

    return false;
};

const hasDowngradeProtection = (attributes: string | null) => {
    const parsedData = getParsedAttributes(attributes);

    if (parsedData?.firmwareVersion) {
        const { firmwareVersion } = parsedData;

        const majorVersion = Number(firmwareVersion.split(".")[0]);
        return majorVersion >= 4 && majorVersion < 9;
    }

    return false;
};

const renderAdditionalBanner = (
    secondSelectName: SecondSelectType,
    data: AdditionalData,
    t: TFunction<"translation", undefined>
) => {
    const isUploadTlsCertificate =
        secondSelectName === CUSTOM_SECOND_SELECT.Certificate;

    if (isUploadTlsCertificate) {
        return (
            <InfoBanner title={t("Dialog##tls certificate transfer info")} />
        );
    }

    if (isUnsupportedFirmware(data.fileAttributes)) {
        return (
            <InfoBanner title={t("Dialog##upload firmware unsupported info")} />
        );
    }

    if (hasDowngradeProtection(data.fileAttributes)) {
        return (
            <InfoBanner
                title={
                    <Trans
                        i18nKey="Dialog##upload firmware downgrade protection info"
                        components={{
                            1: (
                                <TextLink
                                    href="https://wiki.teltonika-gps.com/view/FMB_firmware_errata_04.00.00"
                                    size={TEXT_LINK_SIZES.Small}
                                    target="_blank"
                                >
                                    Teltonika GPS Wiki
                                </TextLink>
                            )
                        }}
                    />
                }
            />
        );
    }

    return null;
};

const sortVehicles = (
    data: any,
    setVehicles: (value: SetStateAction<Vehicles>) => void,
    setMakes: (value: SetStateAction<string[]>) => void
) => {
    const allVehicles: Vehicle[] = data.vehicles;

    const sortedVehicles = allVehicles.reduce<SortedVehicles>(
        (previous, { make, model, vehicle_id, year_start, year_end }) => {
            const currentValue = {
                vehicle_id,
                model: getFirstLetterUppercase(model),
                year_start,
                year_end
            };

            const existingValue = previous[getFirstLetterUppercase(make)] || [];

            return {
                ...previous,
                ...(make
                    ? {
                          [getFirstLetterUppercase(make)]: [
                              ...existingValue,
                              currentValue
                          ]
                      }
                    : {})
            };
        },
        {}
    );

    setVehicles(sortedVehicles);

    setMakes(sortAndReturnArray(Object.keys(sortedVehicles)));
};

const renderPlatforms = (
    platforms: PLATFORMS[],
    t: TFunction<"translation", undefined>
) =>
    platforms
        .map(platform =>
            t("Dialog##platform compatibility##platform", {
                title: t(`General##${platform}`)
            })
        )
        .join(", ");

export {
    isUnsupportedFirmware,
    renderAdditionalBanner,
    renderCanAdapterType,
    renderPlatforms,
    renderVehicleModel,
    sortVehicles
};
