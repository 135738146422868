/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";

import ThemeContext from "../../../context/theme/themeContext";

const PaginationLoader = ({ ...props }) => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const loaderWidths = [
        { id: 1, width: 36 },
        { id: 2, width: 36 },
        { id: 3, width: 48 },
        { id: 4, width: 36 },
        { id: 5, width: 36 }
    ];

    return (
        <div
            {...props}
            css={css({
                display: "flex",
                alignItems: "center",

                span: {
                    height: "36px",
                    borderRadius: "6px",
                    background: gray100
                },

                "& > span + span": {
                    marginLeft: "8px"
                }
            })}
        >
            {loaderWidths.map(({ id, width }) => (
                <Skeleton
                    key={id}
                    variant="rectangular"
                    width={width}
                    height={id === 3 ? "20px" : "36px"}
                />
            ))}
        </div>
    );
};

export default PaginationLoader;
