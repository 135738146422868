import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { TitleInfo } from "./TitleInfo";

import { renderAvailableData } from "../functions";
import { DetailsData } from "../types";

import { CARD_ITEMS, CARDS } from "../../types";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import TableContext from "../../../../../context/table/tableContext";
import { renderCanOemFileVehicle } from "../../../../../shared";

const Can = () => {
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const { can, can_package: assignedCanPackage }: DetailsData = detailsData;

    const getCanOemFileVehicleValue = () => {
        if (can) {
            const { vehicle, vehicle_id } = can;

            return renderCanOemFileVehicle(vehicle, vehicle_id, t);
        }

        return null;
    };

    const getCanOemFilePackageValue = () => {
        if (can) {
            const { can_package, can_package_id } = can;

            if (can_package) {
                return can_package.name;
            }

            if (can_package_id !== null) {
                return `${t("General##unknown")} (${t("General##id")}: ${can_package_id})`;
            }

            return null;
        }

        return null;
    };

    const canOemFileVehicle = getCanOemFileVehicleValue();
    const canOemFilePackage = getCanOemFilePackageValue();

    const list = [
        {
            title: CARD_ITEMS.ChipVersion,
            value: can?.chip_version,
            isAvailable: Boolean(can?.chip_version)
        },
        {
            title: CARD_ITEMS.AssignedPackage,
            value: assignedCanPackage?.name,
            isAvailable: assignedCanPackage !== undefined
        },
        {
            title: CARD_ITEMS.CanOemFileVehicle,
            value: canOemFileVehicle,
            isAvailable: canOemFileVehicle !== null
        },
        {
            title: CARD_ITEMS.CanOemFilePackage,
            value: canOemFilePackage,
            isAvailable: canOemFilePackage !== null
        }
    ];

    return (
        <div>
            <div>
                {t(`General##${CARDS.Can}`)}
                <TitleInfo cardName={CARDS.Can} list={list} />
            </div>

            <div>
                {renderAvailableData(list).map(({ title, value }) => {
                    return (
                        <Fragment key={title}>
                            <span>{t(`Table##${title}`)}:</span>

                            <span>
                                {value}

                                <CopyButton
                                    size={BUTTON_SIZES.Tiny}
                                    copiedValue={String(value)}
                                />
                            </span>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export { Can };
