/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { isFtPlatform } from "../functions";
import { DetailsData } from "../types";

import { RectangleLoader } from "../../../../../shared";
import TableContext from "../../../../../context/table/tableContext";

const SingleLoader = () => {
    const { detailsData } = useContext(TableContext);

    const { model_platform }: DetailsData = detailsData;

    return (
        <div
            css={css({
                height: isFtPlatform(model_platform) ? "307px" : "214px",
                boxSizing: "border-box",

                "& > span:first-of-type": {
                    marginBottom: "18px"
                }
            })}
        >
            <RectangleLoader width={216} height={20} borderRadius="46px" />
            <RectangleLoader width={129} height={20} borderRadius="46px" />
        </div>
    );
};

export default SingleLoader;
