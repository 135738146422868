/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import Files from "./Files";
import { TxFwBundleFileDataProps } from "./types";

import SingleDataItem from "../../SingleDataItem";

import File from "../../../../../../TableDetails/components/File";

import AuthContext from "../../../../../../../context/auth/authContext";
import { useFileSize } from "../../../../../../../shared";

const TxFwBundleFileData = ({
    id,
    mainFile,
    additionalData
}: TxFwBundleFileDataProps) => {
    const { isSupportUser } = useContext(AuthContext);

    const { t } = useTranslation();
    const { renderFileSize } = useFileSize();

    const {
        file_size_bytes,
        included_file_ids = [],
        same_grade_file_ids = []
    } = additionalData;

    const atLeatsOneFileExists =
        included_file_ids.length > 0 || same_grade_file_ids.length > 0;

    return (
        <>
            <h4>{t("General##file data")}</h4>

            <SingleDataItem
                name={t("Attributes##file size bytes")}
                data={file_size_bytes ? renderFileSize(file_size_bytes) : "-"}
            />

            <SingleDataItem name={t("Table##file id")} data={id} />

            {!isSupportUser && (
                <File
                    data={mainFile}
                    css={css({
                        gridColumn: "1/-1",
                        marginTop: "4px"
                    })}
                />
            )}

            {isSupportUser && atLeatsOneFileExists && (
                <Files
                    includedIds={included_file_ids}
                    excludedIds={same_grade_file_ids}
                />
            )}
        </>
    );
};

export default TxFwBundleFileData;
