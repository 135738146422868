/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useState } from "react";

import ReceiveDevice from "./ReceiveDevice";
import MainScreen from "./MainScreen";
import TransferDevices from "./TransferDevices";

import { TRANSFER_DEVICE_WINDOWS } from "../types";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { maxDialogWidth, useMediaQueries } from "../../../../../shared";

const TransferDevice = ({ isOpen, close }: DialogProps) => {
    const { fromMd } = useMediaQueries();

    const [backdropCSS, setBackdropCSS] = useState("");

    const [modalType, setModalType] = useState<TRANSFER_DEVICE_WINDOWS>(
        TRANSFER_DEVICE_WINDOWS.Main
    );
    const [resize, setResize] = useState(false);

    const handleCloseTransferDevice = useCallback(() => {
        setBackdropCSS("");
        close(DEVICE_ACTIONS.TransferDevice);
    }, [close]);

    const handleChangeDialog = (modalName: TRANSFER_DEVICE_WINDOWS) => {
        modalName === TRANSFER_DEVICE_WINDOWS.TransferDevices &&
            setResize(true);

        setModalType(modalName);
    };

    const modalCSS = css(
        {
            "& .MuiDialog-container": {
                transition: backdropCSS
            },

            "& .MuiBackdrop-root": {
                transition: backdropCSS
            }
        },
        !resize && fromMd && maxDialogWidth
    );

    const changeBackdropCSS = (backdropAnimation: string) => {
        setBackdropCSS(backdropAnimation);
    };

    const handleResize = (value: boolean) => {
        setResize(value);
    };

    return (
        <>
            {modalType === TRANSFER_DEVICE_WINDOWS.Main && (
                <MainScreen
                    isOpen={isOpen}
                    modalCSS={modalCSS}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                    changeBackdrop={changeBackdropCSS}
                />
            )}

            {modalType === TRANSFER_DEVICE_WINDOWS.ReceiveDevices && (
                <ReceiveDevice
                    isOpen={isOpen}
                    modalCSS={modalCSS}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                />
            )}

            {modalType === TRANSFER_DEVICE_WINDOWS.TransferDevices && (
                <TransferDevices
                    isOpen={isOpen}
                    modalCSS={modalCSS}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                    removeResize={handleResize}
                />
            )}
        </>
    );
};
export default TransferDevice;
