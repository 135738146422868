import { Fragment } from "react";

import EditGroupInline from "../Actions/dialogs/DeviceGroups/Inline/EditGroup";
import MainContainer from "../MainContainer";
import Table from "../Table";
import useDeviceGroupInlineActions from "../Actions/InlineActions/hooks/useDeviceGroupInlineActions";

import {
    ID_TYPE,
    TABLE_NAMES,
    GROUP_COLUMNS as COLUMNS,
    TABLE_NAMES_WITH_FILTERS
} from "../../shared";

const DeviceGroups = () => {
    const { inlineActions, rowData, isOpen, closeDialog } =
        useDeviceGroupInlineActions();

    return (
        <Fragment>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.DeviceGroups}
                    nameForFilters={TABLE_NAMES_WITH_FILTERS.DeviceGroups}
                    initialColumns={COLUMNS}
                    inlineActions={inlineActions}
                    idType={ID_TYPE.Id}
                    hasActionBar
                />
            </MainContainer>

            <EditGroupInline
                data={rowData}
                isOpen={isOpen}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default DeviceGroups;
