import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Value } from "../types";

import FirmwareConfigurationDropdown from "../../FirmwareConfigurationDropdown";

import DialogActions from "../../../components/DialogActions";
import {
    DropdownValue,
    DROPDOWN_NAMES,
    EditInlineDialogProps,
    INPUT_NAMES
} from "../../../types";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";
import CompanyDropdown from "../../../../../QueryDropdown/components/CompanyDropdown";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../DesignComponents/BannerMessage";

import ParamsContext from "../../../../../../context/params/paramsContext";
import {
    ENDPOINTS,
    FILE_TYPES,
    TABLE_NAMES,
    useApi,
    useMediaQueries
} from "../../../../../../shared";

const EditGroupInline = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();
    const { toMd } = useMediaQueries();

    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState<Value>({
        [INPUT_NAMES.NameValue]: "",
        [DROPDOWN_NAMES.CompanyValue]: null,
        [DROPDOWN_NAMES.FirmwareValue]: null,
        [DROPDOWN_NAMES.ConfigurationValue]: null
    });

    useEffect(() => {
        if (data) {
            const {
                name,
                company: { id: companyId, name: companyName },
                firmware,
                configuration
            } = data;

            setValue({
                [INPUT_NAMES.NameValue]: name,
                [DROPDOWN_NAMES.CompanyValue]: {
                    id: companyId,
                    name: companyName
                },
                [DROPDOWN_NAMES.FirmwareValue]: firmware
                    ? { id: firmware.id, name: firmware.name }
                    : null,
                [DROPDOWN_NAMES.ConfigurationValue]: configuration
                    ? {
                          id: configuration.id,
                          name: configuration.name
                      }
                    : null
            });
        }
    }, [data]);

    const { nameValue, companyValue, firmwareValue, configurationValue } =
        value;

    const handleSubmit = async () => {
        const payload = {
            name: nameValue,
            company_id: companyValue?.id,
            firmware_file_id: firmwareValue ? firmwareValue.id : null,
            configuration_file_id: configurationValue
                ? configurationValue.id
                : null
        };

        try {
            setIsLoading(true);

            const response = await updateData(
                ENDPOINTS.Groups,
                data.id,
                payload
            );

            close();
            setReloadItems(TABLE_NAMES.DeviceGroups);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
            setIsLoading(false);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
        setValue({ ...value, [e.target.name]: e.target.value });

    const handleDropdownChange = (
        dropdownName: DROPDOWN_NAMES,
        dropdownValue: DropdownValue | null
    ) => {
        if (dropdownName === DROPDOWN_NAMES.CompanyValue) {
            setValue({
                ...value,
                [DROPDOWN_NAMES.CompanyValue]: dropdownValue,
                ...(!dropdownValue || dropdownValue.id !== companyValue?.id
                    ? {
                          [DROPDOWN_NAMES.FirmwareValue]: null,
                          [DROPDOWN_NAMES.ConfigurationValue]: null
                      }
                    : {})
            });
        } else {
            setValue({ ...value, [dropdownName]: dropdownValue });
        }
    };

    return (
        <Dialog
            description={t("Dialog##create or edit group description")}
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit group")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setValue({
                        [INPUT_NAMES.NameValue]: "",
                        [DROPDOWN_NAMES.CompanyValue]: null,
                        [DROPDOWN_NAMES.FirmwareValue]: null,
                        [DROPDOWN_NAMES.ConfigurationValue]: null
                    });

                    setIsLoading(false);
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={nameValue.trim() || companyValue}
                    cancelButtonTestId="cancel-edit-group-inline"
                    confirmationButtonTestId="save-edit-group-inline"
                />
            }
            extendToMaxWidth={toMd}
        >
            <InputField
                data-testid="edit-group-inline-name-input"
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                required
                readOnly={isLoading}
                name={INPUT_NAMES.NameValue}
                value={nameValue}
                onChange={handleInputChange}
                labelLeft={t("Dialog##name")}
                placeholder={t("Dialog##enter name")}
                customStyle={{ margin: "8px 0 16px 0" }}
            />

            <CompanyDropdown
                companyValue={companyValue}
                isLoading={isLoading}
                onChange={(_, val) =>
                    handleDropdownChange(DROPDOWN_NAMES.CompanyValue, val)
                }
                customStyle={{ marginBottom: "16px" }}
                isRootRequired
            />

            <FirmwareConfigurationDropdown
                dropdownFileType={FILE_TYPES.Firmware}
                fileTypeValue={firmwareValue}
                companyDropdownValue={companyValue}
                isLoading={isLoading}
                testId={`edit-group-inline-${FILE_TYPES.Firmware}-dropdown`}
                handleChange={data =>
                    handleDropdownChange(DROPDOWN_NAMES.FirmwareValue, data)
                }
            />

            <FirmwareConfigurationDropdown
                dropdownFileType={FILE_TYPES.Configuration}
                fileTypeValue={configurationValue}
                companyDropdownValue={companyValue}
                isLoading={isLoading}
                testId={`edit-group-inline-${FILE_TYPES.Configuration}-dropdown`}
                handleChange={data =>
                    handleDropdownChange(
                        DROPDOWN_NAMES.ConfigurationValue,
                        data
                    )
                }
            />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={t("Dialog##edit group banner message")}
                textWidth="min-content"
                externalCustomStyle={{ marginBottom: "16px" }}
            />
        </Dialog>
    );
};

export default EditGroupInline;
