export enum TASK_ATTRIBUTES {
    Completed = "completed",
    Count = "count",
    Current = "current",
    Date = "date",
    Empty = "empty",
    Failed = "failed",
    Feature = "feature",
    FileSizeBytes = "file_size_bytes",
    FileType = "file_type",
    FileVersion = "file_version",
    FingerprintId = "fingerprint_id",
    FirmwareDate = "firmware_date",
    IncludedFileIds = "included_file_ids",
    Message = "message",
    Order = "order",
    Pending = "pending",
    Price = "price",
    ProtocolId = "protocol_id",
    SameGradeFileIds = "same_grade_file_ids",
    Start = "start",
    VehicleId = "vehicle_id",
    Vin = "vin"
}
