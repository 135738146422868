/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "./Loader";

import { ListProps } from "../types";

import SingleDataItem from "../../../SingleDataItem";

import File from "../../../../../../../TableDetails/components/File";

import { ENDPOINTS, useApi } from "../../../../../../../../shared";

const List = ({ title, ids }: ListProps) => {
    const { t } = useTranslation();
    const { getData, handleResponse } = useApi();

    const [files, setFiles] = useState<{}[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const getFiles = async () => {
            const getEndpoints = () =>
                ids.map(id => getData(`${ENDPOINTS.Files}/${id}`));

            const endpoints = getEndpoints();

            try {
                const response = await Promise.all(endpoints);

                const allFiles = response.map(({ data }) => {
                    return data;
                });

                setFiles(allFiles);
            } catch (error) {
                handleResponse(error);
            }

            setLoading(false);
        };

        getFiles();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <h4
                css={css({
                    marginTop: "20px"
                })}
            >
                {title}
            </h4>

            {isLoading
                ? ids.map(id => <Loader key={id} />)
                : files.map((file: any) => (
                      <SingleDataItem
                          key={file.id}
                          name={t(
                              `Attributes##txFwBundleFileTypes##${file.type}`
                          )}
                          data={<File data={file} />}
                          isFirstCentered
                      />
                  ))}
        </>
    );
};

export default List;
