import { useTranslation } from "react-i18next";

import List from "./List";

import { FilesProps } from "../types";

const Files = ({ includedIds, excludedIds }: FilesProps) => {
    const { t } = useTranslation();

    return (
        <>
            {includedIds.length > 0 && (
                <List
                    title={t("Attributes##included files")}
                    ids={includedIds}
                />
            )}

            {excludedIds.length > 0 && (
                <List
                    title={t("Attributes##excluded files")}
                    ids={excludedIds}
                />
            )}
        </>
    );
};

export default Files;
