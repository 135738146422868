/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { InfoTooltipProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipLarge
} from "../../DesignComponents/Tooltips";

import ThemeContext from "../../../context/theme/themeContext";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";

const InfoTooltip = ({ title, isLarge, ...props }: InfoTooltipProps) => {
    const {
        colorsFacelift: { blue700 }
    } = useContext(ThemeContext);

    return (
        <TooltipLarge title={title} placement={TOOLTIP_PLACEMENT.Top}>
            <span {...props} css={css({ display: "flex" })}>
                <InfoIcon
                    css={css({
                        color: blue700,
                        fontSize: isLarge ? "24px" : "16px"
                    })}
                />
            </span>
        </TooltipLarge>
    );
};

export { InfoTooltip };
