import { useContext, useEffect } from "react";

import { useGetSingleTaskGroupEndpoint } from ".";

import TableContext from "../../../context/table/tableContext";
import {
    BatchTaskStatus,
    isRefreshableTaskType,
    useApi,
    useIsMounted
} from "../../../shared";

const useTaskGroupUpdate = (
    id: number,
    taskStats: BatchTaskStatus[],
    isSelected: boolean
) => {
    const { updateSingleRowData } = useContext(TableContext);

    const { getData, isCanceled, handleResponse } = useApi();
    const { getSingleTaskGroupEndpoint } = useGetSingleTaskGroupEndpoint();
    const isMounted = useIsMounted();

    const getRequestStatus = () => {
        const isAtLeastOneRefreshable = taskStats.some(
            ({ status_id }: BatchTaskStatus) => isRefreshableTaskType(status_id)
        );

        return isSelected && isAtLeastOneRefreshable;
    };

    const isReadyToRequest = getRequestStatus();

    useEffect(() => {
        const getBatchData = async () => {
            try {
                const endpoint = getSingleTaskGroupEndpoint(id);

                const { data: batchData } = await getData(endpoint);

                if (isMounted) {
                    updateSingleRowData(batchData);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }
        };

        isReadyToRequest && getBatchData();

        const timer = setInterval(() => {
            isReadyToRequest && getBatchData();
        }, 60000);

        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [isReadyToRequest]);
};

export { useTaskGroupUpdate };
