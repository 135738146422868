import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "./Loader";

import { VehicleDataProps } from "../../types";

import SingleDataItem from "../../../SingleDataItem";

import {
    ENDPOINTS,
    renderCanOemFileVehicle,
    useApi,
    useIsMounted
} from "../../../../../../../../shared";

const VehicleData = ({ id }: VehicleDataProps) => {
    const { t } = useTranslation();
    const { getData, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const getVehicleData = async () => {
            try {
                const { data } = await getData(`${ENDPOINTS.Vehicles}/${id}`);

                const canOemFileVehicle = renderCanOemFileVehicle(data, id, t);

                setMessage(canOemFileVehicle);
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    const canOemFileVehicle = renderCanOemFileVehicle(
                        null,
                        id,
                        t
                    );

                    setMessage(canOemFileVehicle);
                }
            }

            setLoading(false);
        };

        getVehicleData();

        // eslint-disable-next-line
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <SingleDataItem name={t("Attributes##vehicle")} data={message || "-"} />
    );
};

export default VehicleData;
