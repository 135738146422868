import { Fragment } from "react";

import useFileInlineActions from "../Actions/InlineActions/hooks/useFileInlineActions";
import EditFileInline from "../Actions/dialogs/Files/Inline/EditFile";
import MainContainer from "../MainContainer";
import Table from "../Table";

import {
    ID_TYPE,
    TABLE_NAMES,
    FILE_COLUMNS as COLUMNS,
    TABLE_NAMES_WITH_FILTERS
} from "../../shared";

const Files = () => {
    const { inlineActions, rowData, isOpen, closeDialog } =
        useFileInlineActions();

    return (
        <Fragment>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.Files}
                    nameForFilters={TABLE_NAMES_WITH_FILTERS.Files}
                    initialColumns={COLUMNS}
                    inlineActions={inlineActions}
                    idType={ID_TYPE.Id}
                    sortFromNewest
                    hasActionBar
                />
            </MainContainer>

            <EditFileInline
                data={rowData}
                isOpen={isOpen}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default Files;
