import { useState } from "react";
import { useTranslation } from "react-i18next";

import ReceiveDeviceContent from "./ReceiveDeviceContent";
import ReceiveDeviceActions from "./ReceiveDeviceActions";

import { DeviceTransferModalProps, TRANSFER_DEVICE_WINDOWS } from "../../types";

import Dialog from "../../../../../DesignComponents/Dialog";

import { ENDPOINTS, useApi, useMediaQueries } from "../../../../../../shared";

const ReceiveDevice = ({
    isOpen,
    close,
    changeDialogWindow,
    modalCSS
}: DeviceTransferModalProps) => {
    const { getData, handleResponse } = useApi();

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);
    const [receiveToken, setReceiveToken] = useState(null);

    const generateToken = async () => {
        try {
            setLoading(true);

            const response = await getData(
                `${ENDPOINTS.Tokens}/deviceTransferToken`
            );

            setReceiveToken(response.data);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    return (
        <Dialog
            data-testid="receive-devices-dialog"
            title={t("Button##receive devices")}
            description={
                !receiveToken
                    ? t("Dialog##device transfer##receive devices##description")
                    : t("Dialog##device transfer##receive devices##copy token")
            }
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={close}
            submit={generateToken}
            actions={
                <ReceiveDeviceActions
                    loading={isLoading}
                    token={receiveToken}
                    close={close}
                    goToMainScreen={() =>
                        changeDialogWindow(TRANSFER_DEVICE_WINDOWS.Main)
                    }
                />
            }
            css={modalCSS}
            extendToMaxWidth={toMd}
            TransitionProps={{
                onExited: () => {
                    changeDialogWindow(TRANSFER_DEVICE_WINDOWS.Main);
                }
            }}
        >
            <ReceiveDeviceContent token={receiveToken} />
        </Dialog>
    );
};
export default ReceiveDevice;
