import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { SetContentProps, TABLE_VIEW_CONTENT } from "./types";

import ListChoice from "../../DesignComponents/ListChoice";

import TableContext from "../../../context/table/tableContext";

const InitialContent = ({ setContent }: SetContentProps) => {
    const { columns } = useContext(TableContext);

    const { t } = useTranslation();

    return (
        <>
            {columns.length > 0 && (
                <ListChoice
                    title={t("Button##select columns to show")}
                    iconRight={<ChevronRightRoundedIcon />}
                    onClick={() => setContent(TABLE_VIEW_CONTENT.Columns)}
                />
            )}

            <ListChoice
                title={t("Button##rows count per page")}
                iconRight={<ChevronRightRoundedIcon />}
                onClick={() => setContent(TABLE_VIEW_CONTENT.Rows)}
            />
        </>
    );
};

export default InitialContent;
