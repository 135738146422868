import SingleLoader from "./SingleLoader";

const Loader = () => {
    return (
        <>
            <SingleLoader />
            <SingleLoader />
        </>
    );
};

export default Loader;
