/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import { SingleCardProps } from "./types";

import ThemeContext from "../../../../../../../../../context/theme/themeContext";

const SingleCard = ({ title, value }: SingleCardProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark }
    } = useContext(ThemeContext);

    return (
        <Grid
            container
            css={css({
                padding: "10px 0",
                borderBottom: `1px solid ${gray200}`,
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                wordBreak: "break-word",

                "& > div:first-of-type": {
                    color: gray700,
                    paddingRight: "8px"
                },

                "& > div:last-of-type": {
                    color: textDark,
                    padding: "0 8px",
                    fontWeight: "600"
                }
            })}
        >
            <Grid size={{ xs0: 6 }}>{title}:</Grid>
            <Grid size={{ xs0: 6 }}>{value}</Grid>
        </Grid>
    );
};

export default SingleCard;
