/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MuiTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { HeadTitleInfo } from "../../components";
import { useSorting } from "../../hooks";

import Checkbox from "../../../DesignComponents/Checkbox";
import TableSortLabel from "../../../DesignComponents/TableSortLabel";
import ParamsContext from "../../../../context/params/paramsContext";
import TableContext from "../../../../context/table/tableContext";
import { getIds } from "../../../../shared/functions/functions";
import {
    Column,
    ORDERS,
    tableColumnDropShadow,
    tableColumnRightDropShadow
} from "../../../../shared";

const TableHead = ({ scrollHorizontal }: any) => {
    const { queryParams, setQueryParams } = useContext(ParamsContext);

    const {
        isInnerTable,
        isNotSelectable,
        selectableRows,
        innerTableQueryParams,
        idType,
        columns,
        inlineActions,
        setInnerTableParams,
        selectMultipleRows
    } = useContext(TableContext);

    const { t } = useTranslation();

    const { order, sort, sortColumn } = useSorting(
        isInnerTable ? innerTableQueryParams : queryParams,
        isInnerTable ? setInnerTableParams : setQueryParams
    );

    const selectAllRows = () => {
        const ids = getIds(selectableRows, idType);
        selectMultipleRows(ids);
    };

    return (
        <MuiTableHead>
            <TableRow>
                {/* Checkbox */}
                {!isNotSelectable && (
                    <TableCell
                        css={css({
                            position: "sticky",
                            left: "0",
                            zIndex: "3"
                        })}
                    >
                        <div
                            css={css({
                                height: "24px"
                            })}
                        >
                            <Checkbox
                                checked={false}
                                onChange={selectAllRows}
                                disabled={selectableRows.length === 0}
                            />
                        </div>
                    </TableCell>
                )}

                {/* Column names */}
                {columns.map(
                    ({
                        name,
                        sortResource,
                        isActive,
                        isSticky,
                        restriction
                    }: Column) => {
                        const isActiveSorting = sort === sortResource;

                        const sortDirection = isActiveSorting
                            ? order
                            : ORDERS.Descending;

                        const setLeftPosition = () => {
                            if (isSticky) {
                                return isNotSelectable ? "0" : "60px";
                            }

                            return "unset";
                        };

                        return (
                            isActive && (
                                <TableCell
                                    key={name}
                                    css={css([
                                        {
                                            left: setLeftPosition(),
                                            zIndex: isSticky ? "3" : "2",

                                            "& .MuiTableSortLabel-icon": {
                                                fontSize: "16px",
                                                marginRight: "0",
                                                opacity: "inherit !important"
                                            }
                                        },
                                        isSticky &&
                                            tableColumnRightDropShadow(
                                                scrollHorizontal
                                            )
                                    ])}
                                >
                                    {sortResource ? (
                                        <TableSortLabel
                                            isActive={isActiveSorting}
                                            direction={sortDirection}
                                            onClick={() =>
                                                sortColumn(sortResource)
                                            }
                                        >
                                            <HeadTitleInfo
                                                restriction={restriction}
                                                columnWithAdditionalInfo={name}
                                                css={css({
                                                    marginRight: "4px"
                                                })}
                                            />

                                            {t(`Table##${name}`)}
                                        </TableSortLabel>
                                    ) : (
                                        <span
                                            css={css({
                                                display: "flex",
                                                alignItems: "center"
                                            })}
                                        >
                                            <HeadTitleInfo
                                                restriction={restriction}
                                                columnWithAdditionalInfo={name}
                                                css={css({
                                                    marginRight: "4px"
                                                })}
                                            />

                                            {t(`Table##${name}`)}
                                        </span>
                                    )}
                                </TableCell>
                            )
                        );
                    }
                )}

                {inlineActions.length > 0 && (
                    <TableCell
                        sx={[
                            {
                                position: "sticky",
                                right: "0",
                                width: "0 !important",
                                padding: "0 !important"
                            },
                            tableColumnDropShadow(scrollHorizontal)
                        ]}
                    />
                )}
            </TableRow>
        </MuiTableHead>
    );
};

export default TableHead;
