import { useTranslation } from "react-i18next";

import { FileDeletedProps } from "./types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../DesignComponents/BannerMessage";

const FileDeleted = ({ isSubtitle, ...props }: FileDeletedProps) => {
    const { t } = useTranslation();

    return (
        <BannerMessage
            {...props}
            status={BANNER_MESSAGE_STATUSES.Warning}
            title={t("General##file deleted")}
            externalCustomStyle={
                isSubtitle
                    ? {
                          padding: "3px 7px",
                          display: "inline-flex",
                          whiteSpace: "nowrap"
                      }
                    : {}
            }
        />
    );
};

export default FileDeleted;
