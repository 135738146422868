import { useReducer, useMemo, useCallback } from "react";

import DropzoneContext from "./dropzoneContext";
import DropzoneReducer from "./dropzoneReducer";
import {
    SET_FILE,
    SET_FILES,
    SET_MESSAGE,
    SET_FILE_UPLOAD_STATUS,
    REMOVE_FILE,
    REMOVE_ALL_FILES
} from "./dropzoneActions";
import { DropzoneStateProps, Message } from "./types";

import useResponse from "../../shared/hooks/useApi/useResponse";
import { FILE_UPLOAD_STATUS } from "../../shared";

const DropzoneState = ({ children }: DropzoneStateProps) => {
    const initialState = {
        file: null,
        lineCount: 0,
        multipleFiles: [],
        messages: []
    };

    const { getCriticalMessage } = useResponse();
    const [state, dispatch] = useReducer(DropzoneReducer, initialState);

    const setFile = (file: File | null, fileData?: number[][]) =>
        dispatch({
            type: SET_FILE,
            payload: {
                file,
                fileData: fileData || []
            }
        });

    const setFiles = (files: File[]) =>
        dispatch({
            type: SET_FILES,
            payload: files
        });

    const setMessage = (
        message: Message | Message[],
        override: boolean = false
    ) =>
        dispatch({
            type: SET_MESSAGE,
            payload: {
                message: Array.isArray(message) ? message : [message],
                override
            }
        });

    const setStatus = useCallback(
        (fileUploadStatus: FILE_UPLOAD_STATUS, id: string, rawError?: any) => {
            const error = rawError ? getCriticalMessage(rawError) : null;

            dispatch({
                type: SET_FILE_UPLOAD_STATUS,
                payload: { fileUploadStatus, id, errorMessage: error?.message }
            });
        },
        [getCriticalMessage]
    );

    const removeFile = (id: string) =>
        dispatch({ type: REMOVE_FILE, payload: id });

    const removeAllFiles = () => dispatch({ type: REMOVE_ALL_FILES });

    const value = useMemo(
        () => ({
            file: state.file,
            lineCount: state.lineCount,
            multipleFiles: state.multipleFiles,
            messages: state.messages,
            setFile,
            setFiles,
            setMessage,
            setStatus,
            removeFile,
            removeAllFiles
        }),
        [
            state.file,
            state.lineCount,
            state.multipleFiles,
            state.messages,
            setStatus
        ]
    );

    return (
        <DropzoneContext.Provider value={value}>
            {children}
        </DropzoneContext.Provider>
    );
};

export default DropzoneState;
