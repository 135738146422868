import { useEffect, useState } from "react";

import AdapterTypes from "./AdapterTypes";
import FirmwareDate from "./FirmwareDate";
import { CanAdaptersProps, PayloadValue, ProtocolId } from "./types";

import { PAYLOAD_ID } from "../../types";

import { FirmwareDateValue } from "../../../../../../../DatePicker/types";

import { INVALID_DATE, TASK_TYPES } from "../../../../../../../../shared";

const CanAdapters = ({
    isLoading,
    type,
    additionalData,
    changePayload,
    changeDateValue,
    changeAdapterValue
}: CanAdaptersProps) => {
    const {
        adapter,
        dateValue: { rawDate, stringifiedDate }
    } = additionalData;

    const [payloadValue, setPayloadValue] = useState<PayloadValue>({
        protocol_id: null,
        firmware_date: null
    });

    const { protocol_id, firmware_date } = payloadValue;

    useEffect(() => {
        setPayloadValue({
            protocol_id: adapter ? adapter.protocol_id : null,
            firmware_date: stringifiedDate
        });
    }, [adapter, stringifiedDate]);

    useEffect(() => {
        if (
            firmware_date !== INVALID_DATE &&
            (type === TASK_TYPES.TxCanAdapterUpdate
                ? true
                : protocol_id !== null)
        ) {
            const canAdaptersPayload = {
                firmware_date,
                ...(type !== TASK_TYPES.TxCanAdapterUpdate && {
                    protocol_id
                })
            };

            changePayload({
                id: PAYLOAD_ID.Attributes,
                value: JSON.stringify(canAdaptersPayload)
            });
        } else {
            changePayload(null);
        }

        // eslint-disable-next-line
    }, [protocol_id, firmware_date, type]);

    const changePayloadValue = (value: ProtocolId | FirmwareDateValue) =>
        setPayloadValue({ ...payloadValue, ...value });

    return (
        <>
            {type !== TASK_TYPES.TxCanAdapterUpdate && (
                <AdapterTypes
                    taskType={type}
                    isSubmitLoading={isLoading}
                    value={adapter}
                    changePayload={changePayloadValue}
                    changeType={changeAdapterValue}
                />
            )}

            <FirmwareDate
                key={type}
                isLoading={isLoading}
                value={rawDate}
                changePayload={changePayloadValue}
                changeValue={changeDateValue}
            />
        </>
    );
};

export default CanAdapters;
