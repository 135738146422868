import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import TransferDevicesContent from "./TransferDevicesContent";

import { useTokenValidation } from "../../hooks";
import {
    DeviceTransferModalProps,
    TRANSFER_DEVICE_WINDOWS,
    TransferDevicesValidationProps
} from "../../types";

import TriSource from "../../../components/TriSource/TriSource";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

import {
    BackgroundActionData,
    ENDPOINTS,
    TABLE_NAMES,
    TRI_SOURCES,
    useApi,
    useMediaQueries
} from "../../../../../../shared";
import AlertContext from "../../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import TriSourceContext from "../../../../../../context/triSource/triSourceContext";
import TableContext from "../../../../../../context/table/tableContext";

const TransferDevices = ({
    isOpen,
    modalCSS,
    close,
    changeDialogWindow,
    removeResize
}: DeviceTransferModalProps &
    Omit<TransferDevicesValidationProps, "token">) => {
    const { setBackgroundActionId } = useContext(AlertContext);
    const { setReloadItems } = useContext(ParamsContext);

    const { activeSource, sourcePayload, isSourceReady } =
        useContext(TriSourceContext);

    const { deselectAllRows } = useContext(TableContext);

    const { postData, handleResponse } = useApi();

    const { toMd } = useMediaQueries();

    const { t } = useTranslation();

    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [token, setToken] = useState("");

    const {
        tokenError,
        isTokenLoading,
        validationData,
        validateToken,
        clearTokenError,
        clearValidationData
    } = useTokenValidation({ token, removeResize });

    const isReady =
        isSourceReady &&
        sourcePayload &&
        token.trim().length > 0 &&
        !tokenError;

    const handleToken = (data: string) => setToken(data);

    const getFormData = () => {
        const formData = new FormData();

        formData.append("source", activeSource);
        formData.append("file", sourcePayload as Blob);
        formData.append("data[token]", token);

        return formData;
    };

    const getPayloadData = () => {
        return {
            source: activeSource,
            [activeSource]: sourcePayload,
            data: { token }
        };
    };

    const deselectAndClose = () => {
        deselectAllRows();
        close();
    };

    const submitTransferDevices = async () => {
        try {
            setSubmitLoading(true);

            const payloadData =
                activeSource === TRI_SOURCES.FromFile
                    ? getFormData()
                    : getPayloadData();

            const { data }: { data: BackgroundActionData } = await postData(
                `${ENDPOINTS.Devices}/transfer`,
                payloadData
            );

            setBackgroundActionId(
                data,
                () => {
                    deselectAndClose();
                    setReloadItems(TABLE_NAMES.Devices);
                },
                () => setSubmitLoading(false),
                () => deselectAndClose()
            );
        } catch (error) {
            handleResponse(error);
            setSubmitLoading(false);
        }
    };

    const goToMainDialog = () => {
        changeDialogWindow(TRANSFER_DEVICE_WINDOWS.Main);
        removeResize(false);
    };

    return (
        <TriSource
            title={t("Dialog##transfer devices")}
            description={t(
                validationData
                    ? "Dialog##device transfer##transfer devices##confirmation"
                    : "Dialog##device transfer##transfer devices##device token"
            )}
            confirmationDescription={t(
                "Dialog##confirmations##transfer devices confirmation"
            )}
            hideTriSource={!!validationData}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={close}
            isBulkActionLoading={isSubmitLoading}
            isLoading={isSubmitLoading}
            submit={validationData ? submitTransferDevices : validateToken}
            actions={
                <>
                    <Button
                        fullWidth
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={
                            validationData
                                ? clearValidationData
                                : goToMainDialog
                        }
                        disabled={isSubmitLoading}
                    >
                        {t("Button##back")}
                    </Button>

                    <Button
                        data-testid={
                            validationData
                                ? undefined
                                : "transfer-devices-next-button"
                        }
                        fullWidth
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        type="submit"
                        isLoading={
                            validationData ? isSubmitLoading : isTokenLoading
                        }
                        disabled={validationData ? false : !isReady}
                        loaderText={
                            validationData
                                ? t("Button##transferring")
                                : undefined
                        }
                    >
                        {t("Button##next")}
                    </Button>
                </>
            }
            TransitionProps={{
                onExited: () => {
                    setSubmitLoading(false);
                    goToMainDialog();
                }
            }}
            css={modalCSS}
            extendToMaxWidth={toMd}
        >
            <TransferDevicesContent
                tokenError={tokenError}
                tokenCompany={validationData}
                isLoading={isSubmitLoading}
                setPayload={handleToken}
                clearError={clearTokenError}
            />
        </TriSource>
    );
};
export default TransferDevices;
