/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect, Children, cloneElement } from "react";
import { useTranslation } from "react-i18next";

import { DialogWithConfirmationProps } from "./types";

import { FILE_ACTIONS } from "../../../types";

import Dialog, {
    CombinedDialogProps
} from "../../../../DesignComponents/Dialog";
import Tag, { TAG_COLORS, TAG_SIZES } from "../../../../DesignComponents/Tag";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../DesignComponents/BannerMessage";

import AlertContext from "../../../../../context/alert/alertContext";
import TableContext from "../../../../../context/table/tableContext";
import { TRI_SOURCES, useFormattedNumber } from "../../../../../shared";

const DialogWithConfirmation = ({
    count,
    confirmationDescription,
    source,
    isBulkActionLoading,
    ...props
}: DialogWithConfirmationProps & CombinedDialogProps) => {
    const { hasCloseBtn, hasAutoHide, removeAlert } = useContext(AlertContext);
    const { tableName } = useContext(TableContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    const [needConfirmation, setNeedConfirmation] = useState(count > 20);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [enteredNumber, setEnteredNumber] = useState("");

    const isConfirmed = enteredNumber.trim() === String(count);

    useEffect(() => {
        setNeedConfirmation(count > 20);
    }, [count]);

    const handleSubmit = () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        if (needConfirmation) {
            setShowConfirmation(true);
        } else {
            const { submit } = props;

            return submit && submit();
        }
    };

    const handleEnteredNumberChange = (e: { target: { value: any } }) => {
        const value = e.target.value.trim();

        setEnteredNumber(value);
        setNeedConfirmation(value !== String(count));
    };

    const sourceCheck = () => {
        if (source === TRI_SOURCES.FromFile) {
            return {
                tagTitle: `${t(
                    "Dialog##confirmations##number of lines"
                )}: ${count}`,
                inputLabel: t("Dialog##confirmations##file")
            };
        } else {
            return {
                tagTitle: `${t(
                    `Dialog##selected ${tableName}`
                )}: ${renderFormattedNumber(count)}`,
                inputLabel: t(`Dialog##confirmations##${source}##${tableName}`)
            };
        }
    };

    const { tagTitle, inputLabel } = sourceCheck();

    return (
        <Dialog
            {...props}
            isTitleSeparator
            isActionsSeparator
            title={
                showConfirmation
                    ? t("Dialog##confirmations##are you sure")
                    : props.title
            }
            description={
                showConfirmation ? confirmationDescription : props.description
            }
            close={isBulkActionLoading ? undefined : props.close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: node => {
                    !hasCloseBtn && !hasAutoHide && removeAlert();
                    setShowConfirmation(false);

                    props.TransitionProps?.onExited &&
                        props.TransitionProps?.onExited(node);
                }
            }}
            actions={Children.map(props.actions.props.children, child => {
                const isSubmitBtn = child.props.type === "submit";

                return cloneElement(child, {
                    css: [
                        child.props.css,
                        {
                            transition: "unset",
                            "&:hover": {
                                transition:
                                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                            }
                        }
                    ],
                    ...(showConfirmation && isSubmitBtn
                        ? {
                              disabled: !isConfirmed
                          }
                        : {})
                });
            })}
        >
            <div
                css={css({
                    padding: "8px 0"
                })}
            >
                {props.title.toLowerCase() === FILE_ACTIONS.DeleteFiles && (
                    <BannerMessage
                        status={BANNER_MESSAGE_STATUSES.Warning}
                        title={t("Dialog##delete file warning")}
                        css={css({
                            marginBottom: "16px"
                        })}
                    />
                )}

                {showConfirmation ? (
                    <>
                        <Tag
                            size={TAG_SIZES.Medium}
                            color={TAG_COLORS.Primary}
                            title={tagTitle}
                            css={css({
                                marginBottom: "8px"
                            })}
                        />

                        <InputField
                            fullWidth
                            required
                            size={INPUT_FIELD_SIZES.Medium}
                            value={enteredNumber}
                            placeholder="0"
                            onChange={handleEnteredNumberChange}
                            labelLeft={inputLabel}
                        />
                    </>
                ) : (
                    props.children
                )}
            </div>
        </Dialog>
    );
};

export default DialogWithConfirmation;
