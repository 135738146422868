import { useContext } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import { ENDPOINTS, FILTER_NAMES } from "../../../shared";

const useGetSingleTaskGroupEndpoint = () => {
    const { queryParams } = useContext(ParamsContext);

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];
    const companyIds = queryParams[FILTER_NAMES.CompanyId];

    const rootQuery = `?${FILTER_NAMES.RootCompany}=${rootCompanyId}`;

    const companyIdQuery = companyIds
        ? `&${FILTER_NAMES.CompanyId}=${companyIds}`
        : "";

    const getSingleTaskGroupEndpoint = (id: number) =>
        `${ENDPOINTS.Batches}/${id}${rootQuery}${companyIdQuery}`;

    return { getSingleTaskGroupEndpoint };
};

export { useGetSingleTaskGroupEndpoint };
