import { Fragment } from "react";

import EditCompanyInline from "../Actions/dialogs/Companies/Inline/EditCompany";
import MainContainer from "../MainContainer";
import Table from "../Table";
import useCompanyInlineActions from "../Actions/InlineActions/hooks/useCompanyInlineActions";

import { ID_TYPE, TABLE_NAMES, COMPANY_COLUMNS as COLUMNS } from "../../shared";

const Companies = () => {
    const { inlineActions, rowData, isOpen, closeDialog } =
        useCompanyInlineActions();

    return (
        <Fragment>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.Companies}
                    initialColumns={COLUMNS}
                    inlineActions={inlineActions}
                    idType={ID_TYPE.Id}
                    hideFilters
                    hasActionBar
                    hasDetails
                />
            </MainContainer>

            <EditCompanyInline
                data={rowData}
                isOpen={isOpen}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default Companies;
