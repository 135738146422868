import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { TitleInfo } from "./TitleInfo";

import { VIN_PARSING_LIMIT } from "../constants";
import { renderAvailableData } from "../functions";
import { DetailsData } from "../types";

import { CARD_ITEMS, CARDS } from "../../types";

import DateTime from "../../../../DateTime";
import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import TableContext from "../../../../../context/table/tableContext";

const Obd = () => {
    const { detailsData } = useContext(TableContext);

    const {
        obd: { chip_version, vin, period_requests, period_start }
    }: DetailsData = detailsData;

    const { t } = useTranslation();

    const list = [
        { title: CARD_ITEMS.Vin, value: vin, isAvailable: vin },
        {
            title: CARD_ITEMS.ObdChipFirmwareVersion,
            value: chip_version,
            isAvailable: chip_version
        },
        {
            title: CARD_ITEMS.ExaustedVinParsingLimit,
            customRender: <DateTime date={period_start} />,
            isAvailable: period_requests >= VIN_PARSING_LIMIT
        }
    ];

    return (
        <div>
            <div>
                {t(`Table##${CARDS.Obd}`)}
                <TitleInfo cardName={CARDS.Obd} list={list} />
            </div>

            <div>
                {renderAvailableData(list).map(
                    ({ title, value, customRender }) => {
                        return (
                            <Fragment key={title}>
                                <span>{t(`Table##${title}`)}:</span>

                                <span>
                                    {customRender || (
                                        <>
                                            {value}
                                            <CopyButton
                                                size={BUTTON_SIZES.Tiny}
                                                copiedValue={String(value)}
                                            />
                                        </>
                                    )}
                                </span>
                            </Fragment>
                        );
                    }
                )}
            </div>
        </div>
    );
};

export { Obd };
