import { useReducer, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Group from "./Group";
import Description from "./Description";
import GeneralReducer from "./generalReducer";
import { CHANGE_VALUE, CHANGE_EDITABLE } from "./generalActions";
import { ChangedValue, InputName } from "./types";

import { TitleInfo } from "../TitleInfo";

import { isFtPlatform, renderAvailableData } from "../../functions";
import { DetailsData } from "../../types";

import { CARD_ITEMS, CARDS } from "../../../types";

import CopyButton from "../../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../../DesignComponents/Button";

import TableContext from "../../../../../../context/table/tableContext";

const General = () => {
    const { detailsData } = useContext(TableContext);

    const {
        company_name,
        group_id,
        group_name,
        description,
        serial,
        current_firmware,
        dtb_version,
        ble_firmware,
        current_configuration,
        spec_id,
        gnss_version,
        bootloader_version,
        product_code,
        model_platform
    }: DetailsData = detailsData;

    const { t } = useTranslation();

    const initialState = {
        isEditable: {
            group: false,
            description: false
        },
        value: {
            group: null,
            description: ""
        }
    };

    const [state, dispatch] = useReducer(GeneralReducer, initialState);

    const { isEditable, value } = state;

    const changeEditable = (name: InputName, inputValue: boolean) =>
        dispatch({
            type: CHANGE_EDITABLE,
            payload: {
                name,
                value: inputValue,
                description: description || "",
                group_id,
                group_name
            }
        });

    const changeValue = (
        name: InputName,
        newValue: ChangedValue,
        isUpdate?: boolean
    ) =>
        dispatch({
            type: CHANGE_VALUE,
            payload: { name, value: newValue, isUpdate: Boolean(isUpdate) }
        });

    const getFirmwareAndSpecIdValue = () => {
        const specIdValue =
            spec_id && spec_id > 1
                ? `(${t("Table##spec_id")}: ${spec_id})`
                : "";

        return `${current_firmware} ${specIdValue}`;
    };

    const list = [
        {
            title: CARD_ITEMS.Company,
            value: company_name,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.Group,
            customRender: (
                <Group
                    isEditable={isEditable.group}
                    value={value.group}
                    toggleEditable={changeEditable}
                    changeValue={changeValue}
                />
            ),
            isAvailable: true
        },
        {
            title: CARD_ITEMS.Description,
            customRender: (
                <Description
                    isEditable={isEditable.description}
                    value={value.description}
                    toggleEditable={changeEditable}
                    changeValue={changeValue}
                />
            ),
            isAvailable: true
        },
        {
            title: CARD_ITEMS.Serial,
            value: String(serial),
            isAvailable: serial !== null
        },
        {
            title: CARD_ITEMS.ProductCode,
            value: product_code,
            isAvailable: product_code !== null
        },
        {
            title: CARD_ITEMS.Firmware,
            value: getFirmwareAndSpecIdValue(),
            isAvailable: current_firmware !== null
        },
        {
            title: CARD_ITEMS.Configuration,
            value: current_configuration,
            isAvailable:
                !isFtPlatform(model_platform) && current_configuration !== null
        },
        {
            title: CARD_ITEMS.Dtb,
            value: dtb_version,
            isAvailable: dtb_version !== null
        },
        {
            title: CARD_ITEMS.BleFw,
            value: ble_firmware,
            isAvailable: ble_firmware !== null
        },
        {
            title: CARD_ITEMS.GnssVersion,
            value: gnss_version,
            isAvailable: gnss_version !== null
        },
        {
            title: CARD_ITEMS.Bootloader,
            value: bootloader_version,
            isAvailable: bootloader_version !== null
        }
    ];

    return (
        <div>
            <div>
                {t(`Table##${CARDS.General}`)}
                <TitleInfo cardName={CARDS.General} list={list} />
            </div>

            <div>
                {renderAvailableData(list).map(
                    ({ title, customRender, value }) => {
                        return (
                            <Fragment key={title}>
                                {customRender || (
                                    <>
                                        <span>{t(`Table##${title}`)}:</span>

                                        <span>
                                            {String(value)}{" "}
                                            <CopyButton
                                                size={BUTTON_SIZES.Tiny}
                                                copiedValue={String(value)}
                                            />
                                        </span>
                                    </>
                                )}
                            </Fragment>
                        );
                    }
                )}
            </div>
        </div>
    );
};

export { General };
