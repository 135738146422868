/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import GoBack from "./GoBack";
import { RowsPerPageOption, SetContentProps } from "./types";

import Autocomplete from "../../DesignComponents/Autocomplete";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import { DROPDOWN_SIZES } from "../../../shared";

const RowsPerPage = ({ setContent }: SetContentProps) => {
    const { setQueryParams } = useContext(ParamsContext);

    const { isInnerTable, totalRows, rowsPerPage, setInnerTableParams } =
        useContext(TableContext);

    const { t } = useTranslation();

    const options: RowsPerPageOption[] =
        totalRows > 50 ? ["25", "50", "100"] : ["25", "50"];

    const handleChange = (value: RowsPerPageOption) => {
        const payload = { per_page: value };

        (isInnerTable ? setInnerTableParams : setQueryParams)({
            params: payload
        });
    };

    return (
        <>
            <GoBack setContent={setContent} />

            <ul
                css={css({
                    padding: "6px 18px 14px 16px"
                })}
            >
                <li>
                    <Autocomplete
                        fullWidth
                        disableClearable
                        size={DROPDOWN_SIZES.Medium}
                        labelLeft={t("Table##rows per page")}
                        options={options}
                        value={String(rowsPerPage)}
                        onChange={(_, value) => handleChange(value)}
                    />
                </li>
            </ul>
        </>
    );
};

export default RowsPerPage;
