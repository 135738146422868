/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";

import { CompanyLinkProps } from "../types/interfaces";

import ThemeContext from "../../../../context/theme/themeContext";
import ParamsContext from "../../../../context/params/paramsContext";
import {
    FILTER_NAMES,
    LinkLoader,
    NOT_FILTER_NAMES,
    useFormattedNumber
} from "../../../../shared";

const CompanyLink = ({
    data,
    counter,
    isInitialLoad,
    isLoading,
    companyId,
    companyName
}: CompanyLinkProps) => {
    const {
        colorsFacelift: { gray100, gray200, gray700, textDark }
    } = useContext(ThemeContext);

    const { queryParams, updateFilterParams } = useContext(ParamsContext);

    const { t } = useTranslation();

    const { renderFormattedNumber } = useFormattedNumber();

    const { id: linkId, path, icon: linkIcon } = data;

    const notEmpty = counter[linkId] > 0;
    const titleName = linkId;

    const component = () => {
        if (!notEmpty) {
            return "div";
        }

        return RouterLink;
    };

    const Icon = linkIcon;

    const filterChildCompanies = () => {
        if (notEmpty) {
            const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

            const getFilteredValues = () => ({
                filterName: FILTER_NAMES.CompanyId,
                value: [{ id: companyId, name: companyName }]
            });

            updateFilterParams(
                {
                    [FILTER_NAMES.RootCompany]: rootCompanyId,
                    [FILTER_NAMES.CompanyId]: String(companyId),
                    [NOT_FILTER_NAMES.Selected]: ""
                },
                getFilteredValues()
            );
        }
    };

    return (
        <Link
            component={component()}
            to={`${path}?${FILTER_NAMES.CompanyId}=${companyId}`}
            onClick={filterChildCompanies}
            underline="none"
            css={css({
                opacity: !isInitialLoad && isLoading ? "0.4" : "1",
                cursor: `${notEmpty ? "pointer" : "auto"}`,
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
                borderRadius: "10px",
                padding: "4px 8px 4px 4px",
                minWidth: "156px",
                boxSizing: "border-box",
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "&:hover": {
                    background: notEmpty ? `${gray100}` : "unset"
                }
            })}
        >
            {isInitialLoad ? (
                <LinkLoader />
            ) : (
                <>
                    <div
                        css={css({
                            borderRadius: "8px",
                            padding: "7px",
                            border: `1px solid ${gray200}`,
                            color: gray700,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            "& > svg": {
                                fontSize: "24px"
                            }
                        })}
                    >
                        <Icon />
                    </div>

                    <div
                        css={css({
                            display: "flex",
                            flexDirection: "column",

                            "& > span:first-of-type": {
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px"
                            },

                            "& > span:last-of-type": {
                                color: gray700,
                                fontSize: "10px",
                                fontWeight: 700,
                                lineHeight: "12px",
                                letterSpacing: "1px"
                            }
                        })}
                    >
                        <span>{t(`Table##${titleName}`)}</span>

                        <span>
                            {renderFormattedNumber(counter[linkId])}{" "}
                            {t(`Table##items`)}
                        </span>
                    </div>
                </>
            )}
        </Link>
    );
};

export default CompanyLink;
