import { useContext, useEffect, useState } from "react";

import {
    Accessories,
    Can,
    CanAdapter,
    Carrier,
    ConfigurationDetailsAndSim,
    General,
    Obd
} from "./Cards";
import Loader from "./Loader";
import { VIN_PARSING_LIMIT } from "./constants";
import { DetailsData, DetailsProps } from "./types";

import DetailsContainer from "../../components/DetailsContainer";

import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    useApi,
    useIsMounted
} from "../../../../shared";

const Details = ({ imei }: DetailsProps) => {
    const { isAdminUser, isCarrierUser } = useContext(AuthContext);
    const { detailsData, setDetailsData } = useContext(TableContext);

    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const {
        obd,
        can_adapter,
        accessories,
        carrier,
        can,
        can_package
    }: DetailsData = detailsData;

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const getAdditionalData = async () => {
            try {
                setLoading(true);

                const { data } = await getData(`${ENDPOINTS.Devices}/${imei}`);

                const { bootloader_version, can, can_package, dtb_version } =
                    data;

                if (isMounted) {
                    setDetailsData({
                        ...detailsData,
                        bootloader_version,
                        can,
                        can_package,
                        dtb_version,
                        one_wire_authorization_list:
                            data["1_wire_authorization_list"]
                    });
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getAdditionalData();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    const renderObd =
        !!obd?.vin ||
        !!obd?.chip_version ||
        obd?.period_requests >= VIN_PARSING_LIMIT;

    const renderCan =
        !!can_package ||
        !!can?.can_package ||
        !!can?.chip_version ||
        !!can?.vehicle ||
        !!can?.vehicle_id ||
        !!can?.can_package_id;

    const renderCarrier = (isAdminUser || isCarrierUser) && carrier;

    return (
        <DetailsContainer>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <General />
                    <ConfigurationDetailsAndSim />

                    {renderObd && <Obd />}
                    {can_adapter && <CanAdapter />}
                    {renderCan && <Can />}
                    {accessories && <Accessories />}
                    {renderCarrier && <Carrier />}
                </>
            )}
        </DetailsContainer>
    );
};

export default Details;
