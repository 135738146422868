import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { LEVEL_CHANGES } from "./constants";
import { RevertLevelProps } from "./types";

import { isUserAndRootSameCompany, Revert } from "../shared";

import ParamsContext from "../../../../../context/params/paramsContext";
import AuthContext from "../../../../../context/auth/authContext";

const RevertLevel = ({ date, defaultLevel, resetData }: RevertLevelProps) => {
    const { rootCompany, triggerRootCompany } = useContext(ParamsContext);
    const { user, changeUserCompanyLevel } = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <Revert
            title={t("Dialog##company level manual revert##title", {
                date
            })}
            description={t("Dialog##company level manual revert##description")}
            buttonText={t("Button##revert manual level change")}
            confirmationTitle={t(
                "Dialog##company level manual revert##confirmation##title"
            )}
            confirmationDescription={t(
                "Dialog##company level manual revert##confirmation##description",
                { level: defaultLevel }
            )}
            deleteDataQuery={LEVEL_CHANGES}
            successMessage={t("Flash##company level successfully reverted")}
            successCallback={() => {
                isUserAndRootSameCompany(user?.company_id, rootCompany?.id) &&
                    changeUserCompanyLevel(defaultLevel);

                triggerRootCompany(false);
                resetData(false);
            }}
        />
    );
};

export default RevertLevel;
