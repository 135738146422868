/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";

import { useContext } from "react";

import ThemeContext from "../../../context/theme/themeContext";

const useSharedStyles = () => {
    const {
        colorsFacelift: { gray700, textDark }
    } = useContext(ThemeContext);

    const sharedStyles: CSSObject = {
        "& > div:first-of-type": {
            display: "flex",
            alignItems: "center",
            color: textDark,
            fontWeight: "700",
            lineHeight: "20px",

            "& > span": {
                marginLeft: "8px"
            }
        },

        "& > div:last-of-type": {
            display: "inline-grid",
            gridTemplateColumns: "auto auto",
            gridColumnGap: "24px",
            gridRowGap: "16px",
            wordBreak: "break-word",
            fontWeight: "600",
            marginTop: "16px",

            "& > span": {
                display: "flex",
                lineHeight: "20px",

                "& > form": {
                    display: "inherit",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "8px"
                },

                "& form > button, & > button": {
                    alignSelf: "flex-start",
                    marginLeft: "8px"
                }
            },

            "& > span:nth-of-type(odd)": {
                color: gray700,
                padding: "2px 0"
            },

            "& > span:nth-of-type(even)": {
                color: textDark,
                alignItems: "center"
            },

            "@media (max-width: 599px)": {
                display: "block",

                "& > span:nth-of-type(2n + 1)": {
                    marginTop: "16px"
                }
            },

            "@media (min-width: 840px) and (max-width: 979px)": {
                display: "block",

                "& > span:nth-of-type(2n + 1)": {
                    marginTop: "16px"
                }
            }
        }
    };

    return { sharedStyles };
};

export { useSharedStyles };
