/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { TitleInfo } from "./TitleInfo";

import { isFtPlatform, renderAvailableData } from "../functions";
import { DetailsData } from "../types";

import { CARD_ITEMS, CARDS } from "../../types";

import { useSharedStyles } from "../../../hooks";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import ThemeContext from "../../../../../context/theme/themeContext";
import TableContext from "../../../../../context/table/tableContext";
import { getEnvironment } from "../../../../../shared";

const ConfigurationDetailsAndSim = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { sharedStyles } = useSharedStyles();

    const { isProductionEnv } = getEnvironment();

    const {
        model_platform,
        one_wire_authorization_list,
        current_configuration,
        gsm_number,
        iccid,
        imsi
    }: DetailsData = detailsData;

    const renderValue = (value: string | null) =>
        value !== null ? (
            <span>
                {value}{" "}
                <CopyButton size={BUTTON_SIZES.Tiny} copiedValue={value} />
            </span>
        ) : (
            <span>-</span>
        );

    const configurationDetailsList = [
        {
            title: CARD_ITEMS.MainConfiguration,
            value: current_configuration,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.OneWireAuthorization,
            value: one_wire_authorization_list,
            isAvailable: !isProductionEnv
        }
    ];

    const simList = [
        { title: CARD_ITEMS.GsmNumber, value: gsm_number, isAvailable: true },
        {
            title: CARD_ITEMS.Iccid,
            value: iccid,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.Imsi,
            value: imsi,
            isAvailable: true
        }
    ];

    return (
        <div
            className="configuration-and-sim"
            css={css({
                fontSize: "14px",
                letterSpacing: "0.1px",
                padding: "0",

                "& > div": {
                    padding: "16px 24px",

                    ...sharedStyles
                }
            })}
        >
            {/* Configuration details - only for FT platform */}
            {isFtPlatform(model_platform) && (
                <div
                    css={css({
                        borderBottom: `1px solid ${gray200}`
                    })}
                >
                    <div>
                        {t(`Table##${CARDS.ConfigurationDetails}`)}

                        <TitleInfo
                            cardName={CARDS.ConfigurationDetails}
                            list={configurationDetailsList}
                        />
                    </div>

                    <div>
                        {renderAvailableData(configurationDetailsList).map(
                            ({ title, value }) => {
                                return (
                                    <Fragment key={title}>
                                        <span>{t(`Table##${title}`)}:</span>

                                        {renderValue(value as string | null)}
                                    </Fragment>
                                );
                            }
                        )}
                    </div>
                </div>
            )}

            {/* SIM */}
            <div>
                <div>
                    {t(`Table##${CARDS.Sim}`)}
                    <TitleInfo cardName={CARDS.Sim} list={simList} />
                </div>

                <div>
                    {renderAvailableData(simList).map(({ title, value }) => {
                        return (
                            <Fragment key={title}>
                                <span>{t(`Table##${title}`)}:</span>

                                {renderValue(value as string | null)}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export { ConfigurationDetailsAndSim };
