export enum EDIT_TYPE {
    Description = "description",
    Group = "group",
    Company = "company"
}

export enum CARDS {
    Accessories = "accessories",
    Can = "can",
    CanAdapter = "can_adapter",
    Carrier = "carrier",
    ConfigurationDetails = "configuration details",
    General = "general",
    Obd = "obd",
    Sim = "sim"
}

export enum CARD_ITEMS {
    AssignedPackage = "assigned package",
    BleFw = "ble_fw",
    Bootloader = "bootloader",
    CanBootstring = "can bootstring",
    CanOemFilePackage = "can oem file package",
    CanOemFileVehicle = "can oem file vehicle",
    CanProgramNumber = "program number",
    CanSoftwareDate = "software date",
    CanType = "type",
    CarrierModuleId = "carrier module id",
    CarrierRevision = "carrier revision",
    CarrierSerialId = "carrier serial id",
    CarrierSettingsFilename = "carrier settings filename",
    CarrierUnitStatus = "carrier unit status",
    CarrierUnitType = "carrier unit type",
    ChipVersion = "firmware",
    Company = "company",
    Configuration = "configuration",
    Description = "description",
    Dtb = "dtb",
    ExaustedVinParsingLimit = "exausted vin parsing limit",
    Firmware = "firmware",
    GnssVersion = "gnss version",
    Group = "group",
    GsmNumber = "gsm number",
    Iccid = "iccid",
    Imsi = "imsi",
    Interface = "interface",
    MainConfiguration = "main configuration",
    ObdChipFirmwareVersion = "obd chip firmware version",
    OneWireAuthorization = "1_wire_authorization",
    ProductCode = "product code",
    Serial = "serial",
    Type = "type",
    Vin = "vin"
}
