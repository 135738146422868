import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import SingleInfo from "./TitleInfo/SingleInfo";

import { DetailsData } from "../types";

import { CARD_ITEMS, CARDS } from "../../types";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";
import { InfoTooltip } from "../../../../Table/components";

import TableContext from "../../../../../context/table/tableContext";
import { Accessory } from "../../../../../shared";

const Accessories = () => {
    const { detailsData } = useContext(TableContext);

    const {
        accessories: { data }
    }: DetailsData = detailsData;

    const { t } = useTranslation();

    const renderAccessories = (stringifiedData: string) => {
        try {
            const parsedData = JSON.parse(stringifiedData);

            return parsedData.map(({ Id, Type, Interface }: Accessory) => (
                <Fragment key={Id}>
                    <>
                        <span>{t(`Table##${CARD_ITEMS.Type}`)}:</span>

                        <span>
                            {Type}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={Type}
                            />
                        </span>
                    </>

                    <>
                        <span>{t(`Table##${CARD_ITEMS.Interface}`)}:</span>

                        <span>
                            {Interface}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={Interface}
                            />
                        </span>
                    </>
                </Fragment>
            ));
        } catch (error) {
            return null;
        }
    };

    const tooltipList = [CARD_ITEMS.Type, CARD_ITEMS.Interface];

    return (
        <div>
            <div>
                {t(`Table##${CARDS.Accessories}`)}

                <InfoTooltip
                    title={
                        <ul>
                            {tooltipList.map(tooltip => (
                                <SingleInfo
                                    key={tooltip}
                                    cardName={CARDS.Accessories}
                                    title={tooltip}
                                />
                            ))}
                        </ul>
                    }
                />
            </div>

            <div>{renderAccessories(data)}</div>
        </div>
    );
};

export { Accessories };
