/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";

import { SingleDataItemProps } from "./types";

import ThemeContext from "../../../../../context/theme/themeContext";

const SingleDataItem = ({
    name,
    data,
    icon,
    isRestricted,
    isFirstCentered
}: SingleDataItemProps) => {
    const {
        colorsFacelift: { textPlaceholder, gray700, textDarkDisabled }
    } = useContext(ThemeContext);

    const commonStyles: CSSObject = {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        wordBreak: "break-word"
    };

    return (
        <>
            <span
                css={css({
                    ...commonStyles,
                    color: textPlaceholder,
                    display: "flex",
                    alignItems: isFirstCentered ? "center" : "unset"
                })}
            >
                {icon}
                {name}:
            </span>

            <span
                css={css({
                    ...commonStyles,
                    color: isRestricted ? textDarkDisabled : gray700,
                    overflowX: "hidden"
                })}
            >
                {data}
            </span>
        </>
    );
};

export default SingleDataItem;
