/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { SingleTitleInfoProps } from "./types";

const SingleTitleInfo = ({ cardName, title }: SingleTitleInfoProps) => {
    const { t } = useTranslation();

    return (
        <li>
            <span
                css={css({
                    fontWeight: "700"
                })}
            >
                {t(`Table##${title}`)}
            </span>

            {" - "}

            <span>
                {t(`Table##device details info##${cardName}##${title}`)}
            </span>
        </li>
    );
};

export default SingleTitleInfo;
