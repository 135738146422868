/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { FormEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";

import { BorderFrame, Buttons, InnerBottom, InnerTop } from "../components";
import { SelectAuthenticationMethodProps } from "../types";

import Radio from "../../../../DesignComponents/Radio";
import Badge, { BADGE_COLORS } from "../../../../DesignComponents/Badge";

import ThemeContext from "../../../../../context/theme/themeContext";
import AuthContext from "../../../../../context/auth/authContext";
import {
    AUTHENTICATION_TYPES,
    ENABLE_AUTHENTICATION_STEPS,
    isAuthenticatorType,
    useMediaQueries
} from "../../../../../shared";

const SelectAuthenticationType = ({
    isLoading,
    type,
    changeType,
    changeStep,
    getSecretKeySubmit
}: SelectAuthenticationMethodProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { isLoading: isLogoutLoading, logoutUser } = useContext(AuthContext);

    const { t } = useTranslation();
    const { fromMd } = useMediaQueries();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        isAuthenticatorType(type)
            ? getSecretKeySubmit()
            : changeStep(ENABLE_AUTHENTICATION_STEPS.GetEmailCode);
    };

    return (
        <form onSubmit={handleSubmit}>
            <BorderFrame>
                <InnerTop
                    title={t(
                        "Security##2FA##select authentication type##title"
                    )}
                    description={t(
                        "Security##2FA##select authentication type##description"
                    )}
                />

                <InnerBottom>
                    <RadioGroup
                        value={type}
                        onChange={e =>
                            changeType(e.target.value as AUTHENTICATION_TYPES)
                        }
                        sx={{
                            flexDirection: "row",
                            flexWrap: "nowrap",

                            "& > label": {
                                flex: "1",
                                alignSelf: "flex-start"
                            },

                            "@media (max-width: 839px)": {
                                flexDirection: "column",
                                flexWrap: "wrap",
                                rowGap: "16px",

                                "& > label": {
                                    flex: "unset",
                                    alignSelf: "unset"
                                }
                            }
                        }}
                    >
                        <Radio
                            value={AUTHENTICATION_TYPES.Authenticator}
                            label={
                                <div
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        rowGap: "4px",
                                        marginTop: "-2px",
                                        marginBottom: "4px",

                                        "& > span": {
                                            display: "inline-block",
                                            marginRight: "10px"
                                        }
                                    })}
                                >
                                    <span>
                                        {t(
                                            "Security##2FA##title##authentication app"
                                        )}
                                    </span>

                                    <Badge color={BADGE_COLORS.Info}>
                                        {t(
                                            "Security##2FA##select authentication type##recommended"
                                        )}
                                    </Badge>
                                </div>
                            }
                            info={t(
                                "Security##2FA##select authentication type##authentication app description"
                            )}
                        />

                        {fromMd && (
                            <div
                                css={css({
                                    background: gray200,
                                    width: "1px",
                                    margin: "0 16px"
                                })}
                            />
                        )}

                        <Radio
                            value={AUTHENTICATION_TYPES.Email}
                            label={
                                <div
                                    css={css({
                                        marginBottom: "4px"
                                    })}
                                >
                                    {t(
                                        "Security##2FA##select authentication type##email"
                                    )}
                                </div>
                            }
                            info={t(
                                "Security##2FA##select authentication type##email description"
                            )}
                        />
                    </RadioGroup>
                </InnerBottom>
            </BorderFrame>

            <Buttons
                isWhite
                isLoadingPrimary={isLoading}
                isLoadingSecondary={isLogoutLoading}
                isPrimaryBtnDisabled={type === null}
                primaryBtnTitle={t("Button##continue")}
                onSecondaryBtnClick={() => logoutUser()}
                secondaryBtnTitle={t("Button##back to sign in")}
            />
        </form>
    );
};

export { SelectAuthenticationType };
