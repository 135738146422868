import { useContext } from "react";
import LanguageContext from "../../context/language/languageContext";

export const useFormattedNumber = () => {
    const { language } = useContext(LanguageContext);

    const renderFormattedNumber = (number: number) =>
        number
            ? number.toLocaleString(language as unknown as string)
            : String(number);

    return { renderFormattedNumber };
};
