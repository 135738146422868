import { useTranslation } from "react-i18next";

import { FeatureControlProps, FeatureValue } from "./types";

import { PAYLOAD_ID } from "../../types";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";

import { DROPDOWN_SIZES } from "../../../../../../../../shared";

const FeatureControl = ({
    isLoading,
    features,
    feature,
    changePayload,
    changeFeature
}: FeatureControlProps) => {
    const { t } = useTranslation();

    const handleChange = (value: FeatureValue) => {
        const payload =
            value !== null
                ? {
                      id: PAYLOAD_ID.Attributes,
                      value: JSON.stringify({ feature: value.type })
                  }
                : null;

        changePayload(payload);
        changeFeature(value);
    };

    return (
        <Autocomplete
            fullWidth
            isRequired
            size={DROPDOWN_SIZES.Medium}
            labelLeft={t("Dialog##feature")}
            textFieldParams={{
                placeholder: "Select"
            }}
            options={features}
            value={feature}
            getOptionLabel={option => t(`Dialog##${option.name}`)}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            readOnly={isLoading}
            onChange={(_, featureType) => handleChange(featureType)}
        />
    );
};

export default FeatureControl;
