/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import TaskAttributes from "./TaskAttributes";
import TaskGeneralData from "./TaskGeneralData";
import TaskFileData from "./TaskFileData";
import { TASK_ATTRIBUTES, TaskDetailsProps, TxFwBundleData } from "./types";

import { DEVICE_ACTIONS } from "../../../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";
import Dialog from "../../../../DesignComponents/Dialog";

import { maxDialogWidth, useMediaQueries } from "../../../../../shared";

const TaskDetails = ({
    data,
    isOpen,
    setTaskData,
    close
}: TaskDetailsProps) => {
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const [mainAttributes, setMainAttributes] = useState<any>(null);

    const [txFwBundleFileData, setTxFwBundleFileData] =
        useState<Partial<TxFwBundleData> | null>(null);

    const getMainAttributesAndUpdateTxFwBundleData = (
        parsedData: any,
        txFwBundleData: Partial<TxFwBundleData>
    ) => {
        const entries = Object.entries(parsedData) as [TASK_ATTRIBUTES, any][];

        const attributesToDelete = [
            TASK_ATTRIBUTES.Price,
            TASK_ATTRIBUTES.Message
        ];

        const txFwBundleFileAttributes = [
            TASK_ATTRIBUTES.FileSizeBytes,
            TASK_ATTRIBUTES.IncludedFileIds,
            TASK_ATTRIBUTES.SameGradeFileIds
        ];

        return entries.filter(([attributeName, value]) => {
            if (attributesToDelete.includes(attributeName)) {
                return false;
            }

            if (txFwBundleFileAttributes.includes(attributeName)) {
                txFwBundleData[attributeName as keyof TxFwBundleData] = value;

                return false;
            }

            return true;
        });
    };

    const distributeAttributes = () => {
        if (data?.attributes) {
            try {
                const parsedData = JSON.parse(data.attributes);
                const txFwBundleData: Partial<TxFwBundleData> = {};

                const mainAttributeEntries =
                    getMainAttributesAndUpdateTxFwBundleData(
                        parsedData,
                        txFwBundleData
                    );

                if (mainAttributeEntries.length > 0) {
                    setMainAttributes(mainAttributeEntries);
                }

                if (Object.keys(txFwBundleData).length > 0) {
                    setTxFwBundleFileData(txFwBundleData);
                }
            } catch (error) {
                setMainAttributes(null);
            }
        }
    };

    const handleCloseDialog = () => close(DEVICE_ACTIONS.TaskDetails);

    const taskTypeName = data ? t(`General##${data.type}`) : "";

    return (
        <Dialog
            data-testid="task-details-dialog"
            isTitleSeparator
            isActionsSeparator
            title={taskTypeName}
            open={isOpen}
            close={handleCloseDialog}
            actions={
                <Button
                    fullWidth
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    onClick={handleCloseDialog}
                >
                    {t("Button##close")}
                </Button>
            }
            TransitionProps={{
                onExited: () => {
                    setTaskData(null);
                    setMainAttributes(null);
                    setTxFwBundleFileData(null);
                },
                onEntering: () => {
                    distributeAttributes();
                }
            }}
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <div
                css={css({
                    display: "grid",
                    gridTemplateColumns: "30% 1fr",
                    gridColumnGap: "24px",
                    gridRowGap: "4px",

                    "& > h4": {
                        fontWeight: "700",
                        letterSpacing: "0.15px",
                        lineHeight: "20px",
                        gridColumn: "1/-1",
                        marginBottom: "4px"
                    },

                    "& > span + h4": {
                        marginTop: "20px"
                    }
                })}
            >
                {mainAttributes && (
                    <TaskAttributes separateAttributes={mainAttributes} />
                )}

                <TaskGeneralData data={data} />

                <TaskFileData
                    generalData={{ id: data?.file_id, file: data?.file }}
                    txFwBundleData={txFwBundleFileData}
                />
            </div>
        </Dialog>
    );
};

export default TaskDetails;
