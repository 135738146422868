/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { TaskGeneralDataProps } from "./types";

import SingleDataItem from "../SingleDataItem";

import DateTime from "../../../../../DateTime";
import { HeadTitleInfo } from "../../../../../Table/components";
import { useTableFunctions } from "../../../../../Table/hooks";

import {
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../shared";

const TaskGeneralData = ({ data }: TaskGeneralDataProps) => {
    const { t } = useTranslation();
    const { scheduleTimeFrom, scheduleTimeTo } = useTableFunctions();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const scheduleTimeRestriction = FEATURE_SETTINGS.TaskScheduleSupport;

    const isScheduleTimeRestricted = isFeatureSettingsRestricted(
        scheduleTimeRestriction
    );

    const list = [
        {
            title: t("Table##task id"),
            value: data.id,
            isAvailable: true
        },
        {
            title: t("Table##batch id"),
            value: data.batch_id,
            isAvailable: data.batch_id !== 0
        },
        {
            title: t("Table##type"),
            value: t(`General##${data.type}`),
            isAvailable: true
        },
        {
            title: t("Table##device imei"),
            value: data.device_imei,
            isAvailable: true
        },
        {
            title: t("Table##company"),
            value: data.company === null ? "-" : data.company.name,
            isAvailable: true
        },
        {
            title: t("Table##created by"),
            value: data.created_by ? data.created_by.email : "-",
            isAvailable: true
        },
        {
            title: t("Table##created at"),
            value: <DateTime date={data.created_at} />,
            isAvailable: true
        },
        {
            title: t("Table##updated by"),
            value: data.updated_by ? data.updated_by.email : "-",
            isAvailable: true
        },
        {
            title: t("Table##updated at"),
            value: <DateTime date={data.updated_at} />,
            isAvailable: true
        },
        {
            title: t("Dialog##schedule time"),
            value: (
                <>
                    <DateTime date={scheduleTimeFrom(data)} format="LT" /> -{" "}
                    <DateTime date={scheduleTimeTo(data)} format="LT" />
                </>
            ),
            valueIcon: (
                <HeadTitleInfo
                    restriction={scheduleTimeRestriction}
                    css={css({
                        marginRight: "4px",

                        "& svg": {
                            margin: "2px 0"
                        }
                    })}
                />
            ),
            isValueRestricted: isScheduleTimeRestricted,
            isAvailable: data.schedule
        },
        {
            title: t("Attributes##Message"),
            value: data.message ?? "-",
            isAvailable: true
        }
    ];

    return (
        data && (
            <>
                <h4>{t("Table##general data")}</h4>

                <>
                    {list
                        .filter(listItem => listItem.isAvailable)
                        .map(
                            ({
                                title,
                                value,
                                valueIcon,
                                isValueRestricted
                            }) => (
                                <SingleDataItem
                                    key={title}
                                    name={title}
                                    data={value}
                                    icon={valueIcon}
                                    isRestricted={isValueRestricted}
                                />
                            )
                        )}
                </>
            </>
        )
    );
};
export default TaskGeneralData;
