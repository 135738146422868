import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { TitleInfo } from "./TitleInfo";

import { renderAvailableData } from "../functions";
import { DetailsData } from "../types";

import { useBootstring } from "../../hooks";
import { CARD_ITEMS, CARDS } from "../../types";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import AuthContext from "../../../../../context/auth/authContext";
import TableContext from "../../../../../context/table/tableContext";

const CanAdapter = () => {
    const { isAdminUser } = useContext(AuthContext);
    const { detailsData } = useContext(TableContext);

    const {
        can_adapter: { bootstring, type }
    }: DetailsData = detailsData;

    const { t } = useTranslation();
    const { parsedBootstringData } = useBootstring();

    const programNumber = parsedBootstringData(bootstring, 1);
    const softwareDate = parsedBootstringData(bootstring, 2);

    const list = [
        {
            title: CARD_ITEMS.CanBootstring,
            value: bootstring,
            isAvailable: isAdminUser
        },
        {
            title: CARD_ITEMS.CanType,
            value: type,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CanProgramNumber,
            value: programNumber,
            isAvailable: programNumber !== null
        },
        {
            title: CARD_ITEMS.CanSoftwareDate,
            value: softwareDate,
            isAvailable: softwareDate !== null
        }
    ];

    return (
        <div>
            <div>
                {t(`Table##${CARDS.CanAdapter}`)}
                <TitleInfo cardName={CARDS.CanAdapter} list={list} />
            </div>

            <div>
                {renderAvailableData(list).map(({ title, value }) => {
                    return (
                        <Fragment key={title}>
                            <span>{t(`Table##${title}`)}:</span>

                            <span>
                                {value}{" "}
                                <CopyButton
                                    size={BUTTON_SIZES.Tiny}
                                    copiedValue={String(value)}
                                />
                            </span>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export { CanAdapter };
