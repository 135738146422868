/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionsFromButtonProps, ACTIONS } from "./types";

import Dialog from "../../../../DesignComponents/Dialog";
import DialogActions from "../../../../Actions/dialogs/components/DialogActions";
import Tag, { TAG_COLORS, TAG_SIZES } from "../../../../DesignComponents/Tag";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../DesignComponents/BannerMessage";
import { useGetSingleTaskGroupEndpoint } from "../../../../TaskGroups/hooks";

import AlertContext from "../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import {
    BackgroundActionData,
    ENDPOINTS,
    FILTER_NAMES,
    getSingleTableRowData,
    maxDialogWidth,
    TABLE_NAMES,
    TRI_SOURCES,
    useApi,
    useMediaQueries
} from "../../../../../shared";

// Cancel all cancelable tasks or Retry failed tasks
const ActionsFromButton = ({
    action,
    taskGroupId,
    data,
    isOpen,
    updateSingleRowData,
    close,
    closeMenu
}: ActionsFromButtonProps) => {
    const { hasCloseBtn, hasAutoHide, removeAlert, setBackgroundActionId } =
        useContext(AlertContext);

    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { bulkCancel, getData, postData, handleResponse } = useApi();
    const { getSingleTaskGroupEndpoint } = useGetSingleTaskGroupEndpoint();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        closeMenu && isOpen && closeMenu();
    }, [isOpen, closeMenu]);

    const isCancelAction = action === ACTIONS.CancelAllCancelableTasks;

    const successCallback = (data: BackgroundActionData) => {
        setBackgroundActionId(
            data,
            () => {
                close();
                setReloadItems(TABLE_NAMES.Tasks);

                getSingleTableRowData(
                    getSingleTaskGroupEndpoint(taskGroupId),
                    updateSingleRowData,
                    getData,
                    handleResponse
                );
            },
            () => setLoading(false),
            () => close()
        );
    };

    const handleSubmit = async () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        try {
            setLoading(true);

            if (isCancelAction) {
                const payload = {
                    source: TRI_SOURCES.Filtered,
                    [TRI_SOURCES.Filtered]: [
                        { [FILTER_NAMES.BatchId]: [String(taskGroupId)] }
                    ]
                };

                const { data }: { data: BackgroundActionData } =
                    await bulkCancel(ENDPOINTS.Tasks, payload);

                successCallback(data);
            } else {
                const { data }: { data: BackgroundActionData } = await postData(
                    `${ENDPOINTS.Batches}/${taskGroupId}/retryFailedTasks`
                );

                successCallback(data);
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    const title = isCancelAction
        ? t("Dialog##cancel tasks in group##title")
        : t("Dialog##retry tasks in group##title");

    const description = isCancelAction
        ? t("Dialog##cancel tasks in group##description")
        : t("Dialog##retry tasks in group##description");

    const loaderText = isCancelAction
        ? t("Button##cancelling")
        : t("Button##retrying");

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={title}
            description={description}
            open={isOpen}
            close={isLoading ? undefined : close}
            submit={handleSubmit}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    loaderText={loaderText}
                    isCancelDisabled={isLoading}
                    isReadyToConfirm={true}
                    confirmationButtonText={t("Button##accept")}
                />
            }
            TransitionProps={{
                onExited: () => {
                    !hasCloseBtn && !hasAutoHide && removeAlert();
                    setLoading(false);
                }
            }}
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            <div
                css={css({
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    margin: "8px 0 16px 0"
                })}
            >
                {data.map(({ status_id, count }) => {
                    const title = `${t(
                        `Task status##task count##${status_id}`
                    )}: ${count}`;

                    return (
                        <Tag
                            key={status_id}
                            size={TAG_SIZES.Medium}
                            color={TAG_COLORS.Primary}
                            title={title}
                        />
                    );
                })}
            </div>

            {isCancelAction && (
                <BannerMessage
                    status={BANNER_MESSAGE_STATUSES.Warning}
                    title={t("Dialog##cancel tasks in group##banner title")}
                    css={css({
                        marginBottom: "16px"
                    })}
                />
            )}
        </Dialog>
    );
};

export default ActionsFromButton;
export { ACTIONS };
