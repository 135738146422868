/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import SingleCard from "./SingleCard";
import { CardsProps } from "./types";

import { renderCanAdapterType, renderVehicleModel } from "../../functions";
import { CUSTOM_SECOND_SELECT } from "../../SecondSelectContent/types";
import { ORDER } from "../../SecondSelectContent/InternalLog/types";

import { TRI_SOURCES, UNKNOWN } from "../../../../../../../../shared";

const Cards = ({
    compatiblePlatforms,
    compatibleCount,
    totalCount,
    taskType,
    activeSource,
    secondSelectName,
    differentData,
    isCancelTasksChecked,
    scheduleTime
}: CardsProps) => {
    const { t } = useTranslation();

    const {
        internalLogProps,
        canAdapterProps,
        canVehiclesProps,
        featureControlProps,
        generalDropdownProps
    } = differentData;

    const areAllCompatible = compatibleCount === totalCount;

    const getActiveSourceTitle = () => {
        if (activeSource === TRI_SOURCES.Selected) {
            return t("Dialog##selected devices");
        }

        if (activeSource === TRI_SOURCES.Filtered) {
            return t("Dialog##filtered devices");
        }

        return t("Dialog##platform compatibility##devices from file");
    };

    const renderCompatiblePlatforms = () => {
        const filteredPlatforms = compatiblePlatforms.filter(
            platform => platform !== UNKNOWN
        );

        return filteredPlatforms.map((platform, i) => {
            const isFirst = i === 0;

            return (
                <span
                    key={platform}
                    css={css({
                        display: "block",
                        marginTop: isFirst ? 0 : "8px"
                    })}
                >
                    {t(`Dialog##platform compatibility##platform`, {
                        title: t(`General##${platform}`)
                    })}
                </span>
            );
        });
    };

    const renderDifferentScenariosData = () => {
        switch (secondSelectName) {
            case undefined: {
                return null;
            }
            case CUSTOM_SECOND_SELECT.Logs: {
                const {
                    additionalData: { inputValue, order }
                } = internalLogProps;

                const filesToDownload =
                    order === ORDER.Specific
                        ? inputValue?.rawValue
                        : inputValue?.count;

                return (
                    <>
                        <SingleCard
                            title={t("Dialog##begin download")}
                            value={t(`Dialog##${order}`)}
                        />

                        <SingleCard
                            title={t("Dialog##files to download")}
                            value={filesToDownload}
                        />
                    </>
                );
            }
            case CUSTOM_SECOND_SELECT.CanAdapters: {
                const {
                    additionalData: {
                        adapter,
                        dateValue: { stringifiedDate }
                    }
                } = canAdapterProps;

                return (
                    <>
                        {adapter && (
                            <SingleCard
                                title={t("Dialog##can adapter")}
                                value={renderCanAdapterType(adapter)}
                            />
                        )}

                        <SingleCard
                            title={t("Dialog##firmware date")}
                            value={stringifiedDate || "-"}
                        />
                    </>
                );
            }
            case CUSTOM_SECOND_SELECT.CanVehicles: {
                const {
                    additionalData: { make, model }
                } = canVehiclesProps;

                return (
                    <>
                        <SingleCard title={t("Dialog##make")} value={make} />

                        <SingleCard
                            title={t("Dialog##vehicle model")}
                            value={renderVehicleModel(model!)}
                        />
                    </>
                );
            }
            case CUSTOM_SECOND_SELECT.FeatureControl: {
                const { feature } = featureControlProps;

                return (
                    <SingleCard
                        title={t("Dialog##feature")}
                        value={t(`Dialog##${feature!.name}`)}
                    />
                );
            }
            default: {
                const {
                    additionalData: { value }
                } = generalDropdownProps;

                return (
                    <SingleCard
                        title={t(`General##${secondSelectName}`)}
                        value={value!.name}
                    />
                );
            }
        }
    };

    const renderScheduleTime = () => {
        if (scheduleTime) {
            const { fromDate, toDate } = scheduleTime;

            return `${fromDate} - ${toDate}`;
        }

        return "-";
    };

    return (
        <div
            css={css({
                "& > div:last-of-type": areAllCompatible && {
                    borderBottom: "none"
                }
            })}
        >
            <SingleCard
                title={t(
                    "Dialog##platform compatibility##compatible platform devices"
                )}
                value={compatibleCount}
            />

            <SingleCard title={getActiveSourceTitle()} value={totalCount} />

            <SingleCard
                title={t("General##task type")}
                value={t(`General##${taskType}`)}
            />

            {renderDifferentScenariosData()}

            <SingleCard
                title={t("Dialog##platform compatibility##task applies")}
                value={renderCompatiblePlatforms()}
            />

            <SingleCard
                title={t("Dialog##cancel pending tasks")}
                value={t(`General##${isCancelTasksChecked ? "yes" : "no"}`)}
            />

            <SingleCard
                title={t("Dialog##schedule time")}
                value={renderScheduleTime()}
            />
        </div>
    );
};

export default Cards;
