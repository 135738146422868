export enum FILE_NAME {
    SampleImportList = "sample_import_list",
    SampleImeiList = "sample_imei_list",
    SampleAddDeviceList = "sample_add_device_list"
}

export enum TRANSFER_DEVICE_WINDOWS {
    Main,
    ReceiveDevices,
    TransferDevices
}

export enum EXPORT_TYPE {
    CSV = "csv",
    XLSX = "xlsx"
}
