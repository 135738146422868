import { useEffect, useState } from "react";

import { SecondSelectData } from "../SecondSelectContent/types";
import { SchedulePayload, SelectedTask, TaskPlatforms } from "../types";

import { useTaskTypes } from "../../hooks";
import { SCHEDULE_TYPE, Task } from "../../types";

import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    FEATURE_SETTINGS,
    UNKNOWN,
    useApi,
    useCompanyLevelSettings,
    useIsMounted
} from "../../../../../../../shared";

const useCreateTaskContent = (setPayload: (data: any) => void) => {
    const { cancelSource, getData, handleResponse, isCanceled } = useApi();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();
    const { visibleTasks } = useTaskTypes();
    const isMounted = useIsMounted();

    const [arePlatformsLoading, setPlatformsLoading] = useState(true);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [selectedTask, setSelectedTask] = useState<SelectedTask>(null);

    const [secondSelectData, setSecondSelectData] =
        useState<SecondSelectData | null>(null);

    const [isScheduled, setScheduled] = useState(false);
    const [isCancelTasksChecked, setIsCancelTasksChecked] = useState(true);
    const [scheduledTime, setScheduledTime] = useState<SchedulePayload | null>(
        null
    );

    useEffect(() => {
        const getSupportedPlatforms = async () => {
            try {
                const {
                    data: { platform_support_table }
                } = await getData(ENDPOINTS.TaskPlatforms);

                if (isMounted) {
                    const addPlatforms = (platforms: TaskPlatforms) => {
                        const updatedTasks = visibleTasks.map(task => {
                            const actualPlatforms = platforms[task.type];

                            if (actualPlatforms) {
                                const filteredActualPlatforms =
                                    actualPlatforms.filter(
                                        platform => platform !== UNKNOWN
                                    );

                                return {
                                    ...task,
                                    platforms: filteredActualPlatforms
                                };
                            }

                            return task;
                        });

                        setTasks(updatedTasks);
                    };

                    addPlatforms(platform_support_table);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    setTasks(visibleTasks);
                    handleResponse(error);
                }
            }

            setPlatformsLoading(false);
        };

        getSupportedPlatforms();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            selectedTask &&
            (selectedTask.secondSelect ? secondSelectData !== null : true)
        ) {
            const data = {
                type: selectedTask.type,
                secondSelectData,
                schedule:
                    isScheduled && scheduledTime !== null
                        ? {
                              type: SCHEDULE_TYPE.Type,
                              attributes: {
                                  from_minutes: scheduledTime.from,
                                  duration_minutes: scheduledTime.duration
                              }
                          }
                        : null,
                expire_existing_tasks: isCancelTasksChecked
            };

            setPayload(data);
        } else {
            setPayload(null);
        }
        // eslint-disable-next-line
    }, [
        selectedTask,
        secondSelectData,
        isScheduled,
        scheduledTime,
        isCancelTasksChecked
    ]);

    const handleChangeValue = (taskType: Task) => {
        setSelectedTask(taskType);
        setSecondSelectData(null);
    };

    const handleSecondSelectData = (data: SecondSelectData | null) =>
        setSecondSelectData(data);

    const toggleTaskCancellation = () =>
        setIsCancelTasksChecked(!isCancelTasksChecked);

    const toggleScheduled = () => setScheduled(!isScheduled);

    const handleScheduledTime = (data: SchedulePayload) =>
        setScheduledTime(data);

    const isRestricted = isFeatureSettingsRestricted(
        FEATURE_SETTINGS.TaskScheduleSupport
    );

    return {
        arePlatformsLoading,
        isCancelTasksChecked,
        isRestricted,
        isScheduled,
        selectedTask,
        tasks,
        handleChangeValue,
        handleScheduledTime,
        handleSecondSelectData,
        toggleScheduled,
        toggleTaskCancellation
    };
};

export { useCreateTaskContent };
