/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { TaskStatusIconProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../DesignComponents/Tooltips";
import Badge, {
    BADGE_COLORS,
    BadgeData
} from "../../../../DesignComponents/Badge";

import ThemeContext from "../../../../../context/theme/themeContext";
import SuccessIcon from "../../../../../assets/customIcons/status/SuccessIcon";
import { TASK_STATUSES } from "../../../../../shared/types/enums";

const TaskStatusIcon = ({
    id,
    count,
    verifyAttemptCount
}: TaskStatusIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const getBadgeData = (statusId: TASK_STATUSES): BadgeData => {
        switch (statusId) {
            case TASK_STATUSES.Pending:
            case TASK_STATUSES.Verifying:
                return { color: BADGE_COLORS.Premium, isInverted: true };
            case TASK_STATUSES.Running:
                return { color: BADGE_COLORS.Info, isInverted: true };
            case TASK_STATUSES.Completed:
                return { color: BADGE_COLORS.Success, isInverted: true };
            case TASK_STATUSES.CompletedWithErrors:
                return { color: BADGE_COLORS.Warning, isInverted: true };
            case TASK_STATUSES.Cancelled:
                return { color: BADGE_COLORS.Neutral, isInverted: false };
            case TASK_STATUSES.Failed:
                return { color: BADGE_COLORS.Critical, isInverted: true };
            case TASK_STATUSES.PendingApproval:
                return { color: BADGE_COLORS.Light, isInverted: false };
            default:
                return { color: BADGE_COLORS.Light, isInverted: true };
        }
    };

    const color = getBadgeData(id).color;
    const isInverted = getBadgeData(id).isInverted;

    const statusName = t(`Task status##${id}`);

    const name = count ? `${count} ${statusName}` : statusName;

    const isCompleted = id === TASK_STATUSES.Completed;
    const hasAttempts = verifyAttemptCount && verifyAttemptCount > 0;
    const isVerified = isCompleted && hasAttempts;

    return (
        <TooltipGeneral
            title={isVerified ? t("Task status##verified tooltip title") : ""}
            placement={TOOLTIP_PLACEMENT.Top}
        >
            <div
                css={css({
                    display: "inline-flex",
                    cursor: isVerified ? "pointer" : "default"
                })}
            >
                <Badge
                    color={color}
                    inverted={isInverted}
                    iconRight={
                        isVerified ? (
                            <SuccessIcon htmlColor={white} />
                        ) : undefined
                    }
                >
                    {name}
                </Badge>
            </div>
        </TooltipGeneral>
    );
};

export default TaskStatusIcon;
