import General from "./General";
import OtherInfo from "./OtherInfo";

import DetailsContainer from "../../components/DetailsContainer";

const Details = () => {
    return (
        <DetailsContainer>
            <General />
            <OtherInfo />
        </DetailsContainer>
    );
};

export default Details;
