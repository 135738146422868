import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { CUSTOM_RESOURCE_LIMITS } from "./constants";
import { RevertLimitsProps } from "./types";

import { isUserAndRootSameCompany, Revert } from "../../shared";

import ParamsContext from "../../../../../../context/params/paramsContext";
import AuthContext from "../../../../../../context/auth/authContext";

const RevertLimits = ({ setDefaultValues }: RevertLimitsProps) => {
    const { rootCompany, changeCustomResourceLimits } =
        useContext(ParamsContext);

    const { user, changeUserCustomResourceLimits } = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <Revert
            title={t(
                "Dialog##custom resource limits##revert custom resource limits title"
            )}
            description={t(
                "Dialog##custom resource limits##revert custom resource limits description"
            )}
            buttonText={t(
                "Dialog##custom resource limits##revert custom resource limits"
            )}
            confirmationTitle={t(
                "Dialog##custom resource limits##revert custom resource limits title confirmation"
            )}
            confirmationDescription={t(
                "Dialog##custom resource limits##revert custom resource limits description confirmation"
            )}
            deleteDataQuery={CUSTOM_RESOURCE_LIMITS}
            successMessage={t(
                "Flash##custom resource limits successfully reverted"
            )}
            successCallback={() => {
                changeCustomResourceLimits(null);

                isUserAndRootSameCompany(user?.company_id, rootCompany?.id) &&
                    changeUserCustomResourceLimits(null);

                setDefaultValues();
            }}
        />
    );
};

export default RevertLimits;
