import MainContainer from "../MainContainer";
import Table from "../Table";
import DeviceStatistics from "../DeviceStatistics";

import {
    ID_TYPE,
    TABLE_NAMES,
    DEVICE_COLUMNS as COLUMNS,
    TABLE_NAMES_WITH_FILTERS
} from "../../shared";

const Devices = () => {
    return (
        <>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.Devices}
                    nameForFilters={TABLE_NAMES_WITH_FILTERS.Devices}
                    initialColumns={COLUMNS}
                    idType={ID_TYPE.Imei}
                    hasActionBar
                    hasDetails
                />
            </MainContainer>

            <DeviceStatistics />
        </>
    );
};

export default Devices;
