/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import ColumnsToShow from "./ColumnsToShow";
import RowsPerPage from "./RowsPerPage";
import InitialContent from "./InitialContent";
import { TABLE_VIEW_CONTENT, TableViewProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import Menu from "../../DesignComponents/Menu";
import TableViewIcon from "../../../assets/customIcons/actions/TableViewIcon";
import { MenuAnchorEl, RectangleLoader } from "../../../shared";

const TableView = ({ isInitialLoading }: TableViewProps) => {
    const { t } = useTranslation();

    const [content, setContent] = useState<TABLE_VIEW_CONTENT>(
        TABLE_VIEW_CONTENT.Initial
    );

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setContent(TABLE_VIEW_CONTENT.Initial);
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    const handleSetContent = (newContent: TABLE_VIEW_CONTENT) =>
        setContent(newContent);

    const renderContent = () => {
        switch (content) {
            case TABLE_VIEW_CONTENT.Columns:
                return <ColumnsToShow setContent={handleSetContent} />;
            case TABLE_VIEW_CONTENT.Rows:
                return <RowsPerPage setContent={handleSetContent} />;
            default:
                return <InitialContent setContent={handleSetContent} />;
        }
    };

    return isInitialLoading ? (
        <RectangleLoader
            width={104.22}
            height={24}
            customStyle={{
                marginLeft: "24px",
                marginRight: "12px",
                "@media(max-width: 599px)": {
                    marginLeft: "0",
                    marginTop: "8px"
                }
            }}
        />
    ) : (
        <>
            <Button
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconLeft}
                icon={<TableViewIcon />}
                onClick={handleOpenMenu}
                css={css({
                    marginLeft: "16px",

                    "@media(max-width: 599px)": {
                        marginTop: "2px",
                        marginLeft: "0"
                    }
                })}
            >
                {t("Button##table view")}
            </Button>

            <Menu
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                MenuListProps={{ disableListWrap: true }}
                css={css({
                    "& .MuiMenu-paper": {
                        maxHeight: "384px",
                        minWidth: "276px"
                    }
                })}
            >
                {renderContent()}
            </Menu>
        </>
    );
};

export default TableView;
