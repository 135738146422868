/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Trans, useTranslation } from "react-i18next";

import { PLATFORMS } from "../../../../shared";

const PlatformInfo = () => {
    const { t } = useTranslation();

    const platforms = Object.values(PLATFORMS).filter(
        platform => platform !== PLATFORMS.Unknown
    );

    return (
        <div
            css={css({
                p: {
                    margin: "0",
                    fontWeight: "700"
                }
            })}
        >
            <p>{t(`Table##head title info##platform##title`)}:</p>

            <ul
                css={css({
                    paddingLeft: "24px",

                    li: {
                        listStyle: "disc",

                        span: {
                            fontWeight: "700"
                        }
                    }
                })}
            >
                {platforms.map(platform => {
                    const platformKey = platform.toLowerCase();

                    return (
                        <li key={platformKey}>
                            <Trans
                                i18nKey={`Table##head title info##platform##${platformKey} platform`}
                                components={{
                                    1: <span />
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default PlatformInfo;
