import { useContext } from "react";

import Table from "../../../Table";
import TableContext from "../../../../context/table/tableContext";
import {
    ID_TYPE,
    SORT_RESOURCES,
    TABLE_NAMES,
    DEVICE_DETAILS_CHANGES_COLUMNS as COLUMNS,
    ORDERS,
    ENDPOINTS
} from "../../../../shared";

const Changes = () => {
    const { detailsData } = useContext(TableContext);

    const { imei } = detailsData;

    return (
        <Table
            key={imei}
            name={TABLE_NAMES.Changes}
            customResource={`${ENDPOINTS.Devices}/${imei}/changes`}
            isInnerTable
            isNotSelectable
            hideSearch
            hideFilters
            initialColumns={COLUMNS}
            idType={ID_TYPE.Id}
            innerTableQueryParams={{
                order: ORDERS.Descending,
                sort: SORT_RESOURCES.CreatedAt
            }}
            customStyle={{
                marginTop: "-52px",

                "@media (max-width: 1439px)": {
                    marginTop: "0"
                }
            }}
        />
    );
};

export default Changes;
