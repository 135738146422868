import { useEffect, useState } from "react";

import {
    AdapterValue,
    DateValue
} from "../SecondSelectContent/CanAdapters/types";
import { SelectedTask } from "../types";

const useCanAdapters = (selectedTask: SelectedTask) => {
    const [dateValue, setDateValue] = useState<DateValue>({
        rawDate: null,
        stringifiedDate: null
    });

    const [adapter, setAdapter] = useState<AdapterValue>(null);

    useEffect(() => {
        setDateValue({
            rawDate: null,
            stringifiedDate: null
        });

        setAdapter(null);
    }, [selectedTask?.type]);

    const changeDateValue = (newValue: DateValue) => setDateValue(newValue);

    const changeAdapterValue = (adapterInfo: AdapterValue) =>
        setAdapter(adapterInfo);

    return {
        additionalData: { dateValue, adapter },
        changeDateValue,
        changeAdapterValue
    };
};

export { useCanAdapters };
