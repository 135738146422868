import { useEffect, useState } from "react";

import {
    AdditionalData,
    ChangeDataParams
} from "../SecondSelectContent/GeneralDropdown/types";
import { SelectedTask } from "../types";

const useGeneralDropdown = (selectedTask: SelectedTask) => {
    const [data, setData] = useState<AdditionalData>({
        value: null,
        fileAttributes: null
    });

    useEffect(() => {
        setData({ value: null, fileAttributes: null });
    }, [selectedTask?.secondSelect]);

    const changeData = ({ value, fileAttributes }: ChangeDataParams) =>
        setData({
            value,
            fileAttributes: fileAttributes || null
        });

    return {
        additionalData: data,
        changeAdditionalData: changeData
    };
};

export { useGeneralDropdown };
