/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Trans } from "react-i18next";

import { CountTextProps } from "./types";

import ThemeContext from "../../../../../../../../context/theme/themeContext";

const CountText = ({
    compatibleCount,
    totalCount,
    hasCreateForIncompatible
}: CountTextProps) => {
    const {
        colorsFacelift: { gray700, textDark }
    } = useContext(ThemeContext);

    return (
        <p
            css={css({
                color: gray700,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                margin: "12px 0 0 0",

                "& > span": {
                    color: textDark,
                    fontWeight: "700"
                }
            })}
        >
            <Trans
                i18nKey="Dialog##platform compatibility##count text"
                values={{
                    compatibleCount: hasCreateForIncompatible
                        ? totalCount
                        : compatibleCount,
                    totalCount
                }}
                components={{
                    1: <span />
                }}
            />
        </p>
    );
};

export default CountText;
