import { SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FirmwareConfigurationDropdown from "../FirmwareConfigurationDropdown";

import DialogActions from "../../components/DialogActions";
import { DropdownValue } from "../../types";

import { DialogProps, GROUP_ACTIONS } from "../../../types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../DesignComponents/BannerMessage";
import Dialog from "../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import QueryDropdown from "../../../../QueryDropdown";

import ParamsContext from "../../../../../context/params/paramsContext";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    FILE_TYPES,
    ID_TYPE,
    Query,
    TABLE_NAMES,
    useApi
} from "../../../../../shared";

const CreateGroup = ({ isOpen, close }: DialogProps) => {
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();

    const [companyDropdownValue, setCompanyDropdownValue] =
        useState<DropdownValue | null>(null);

    const [firmwareDropdownValue, setFirmwareDropdownValue] =
        useState<DropdownValue | null>(null);

    const [configurationDropdownValue, setConfigurationDropdownValue] =
        useState<DropdownValue | null>(null);

    const [newGroupName, setNewGroupName] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Remove firmware and configuration on company change
    useEffect(() => {
        setFirmwareDropdownValue(null);
        setConfigurationDropdownValue(null);
    }, [companyDropdownValue]);

    const payload = {
        company_id: companyDropdownValue?.id,
        name: newGroupName?.trim(),
        firmware_file_id: firmwareDropdownValue?.id,
        configuration_file_id: configurationDropdownValue?.id
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const response = await postData(ENDPOINTS.Groups, payload);

            handleCloseDialog();
            setReloadItems(TABLE_NAMES.DeviceGroups);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
            setIsLoading(false);
        }
    };

    const handleDropdownChange = (
        chosenValue: Query | null,
        setValue: (value: SetStateAction<Query | null>) => void
    ) => {
        setValue(chosenValue);
    };

    const changeNameValue = (e: { target: { value: string } }) =>
        setNewGroupName(e.target.value);

    const handleCloseDialog = () => close(GROUP_ACTIONS.CreateGroup);

    return (
        <Dialog
            title={t("Dialog##create group")}
            description={t("Dialog##create or edit group description")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setNewGroupName(null);
                    setCompanyDropdownValue(null);
                    setFirmwareDropdownValue(null);
                    setConfigurationDropdownValue(null);
                    setIsLoading(false);
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={
                        companyDropdownValue && newGroupName?.trim()
                    }
                    confirmationButtonText={t("Button##create")}
                    cancelButtonTestId="cancel-create-group"
                    confirmationButtonTestId="confirm-create-group"
                />
            }
            extendToMaxWidth
        >
            <InputField
                data-testid="group-name-input-field"
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                required
                readOnly={isLoading}
                onChange={changeNameValue}
                labelLeft={t("Dialog##name")}
                placeholder={t("Dialog##enter name")}
                customStyle={{ marginBottom: "16px" }}
            />

            <QueryDropdown
                data-testid="company-query-dropdown"
                resource={ENDPOINTS.Companies}
                idType={ID_TYPE.Id}
                value={companyDropdownValue}
                size={DROPDOWN_SIZES.Medium}
                fullWidth
                isRequired
                readOnly={isLoading}
                change={(_, val) =>
                    handleDropdownChange(val, setCompanyDropdownValue)
                }
                labelLeft={t("Dialog##company")}
                textFieldParams={{ placeholder: t("Dialog##select") }}
                customStyle={{ marginBottom: "16px" }}
                isRootRequired
            />

            <FirmwareConfigurationDropdown
                dropdownFileType={FILE_TYPES.Firmware}
                fileTypeValue={firmwareDropdownValue}
                companyDropdownValue={companyDropdownValue}
                isLoading={isLoading}
                testId={`${FILE_TYPES.Firmware}-query-dropdown`}
                handleChange={data =>
                    handleDropdownChange(data, setFirmwareDropdownValue)
                }
            />

            <FirmwareConfigurationDropdown
                dropdownFileType={FILE_TYPES.Configuration}
                fileTypeValue={configurationDropdownValue}
                companyDropdownValue={companyDropdownValue}
                isLoading={isLoading}
                testId={`${FILE_TYPES.Configuration}-query-dropdown`}
                handleChange={data =>
                    handleDropdownChange(data, setConfigurationDropdownValue)
                }
            />

            <div>
                <BannerMessage
                    status={BANNER_MESSAGE_STATUSES.Info}
                    title={t("Dialog##create group banner message")}
                    textWidth="min-content"
                    externalCustomStyle={{ margin: "16px 0 16px 0" }}
                />
            </div>
        </Dialog>
    );
};

export default CreateGroup;
