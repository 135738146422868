import { BACKGROUND_ACTIONS } from "../types";

const BACKROUND_ACTION_TITLES = {
    [BACKGROUND_ACTIONS.CompanyBulkDelete]: {
        failed: "CompanyBulkDeleteFailed",
        success: "CompaniesSuccessfullyDeleted"
    },
    [BACKGROUND_ACTIONS.CompanyMerge]: {
        failed: "CompanyMergeFailed",
        success: "CompanySuccessfullyMerged"
    },
    [BACKGROUND_ACTIONS.DeviceBulkAttributeUpdate]: {
        failed: "DeviceBulkAttributeUpdateFailed",
        success: "DeviceAttributesSuccessfullyUpdated"
    },
    [BACKGROUND_ACTIONS.DeviceBulkUpdate]: {
        failed: "DeviceBulkUpdateFailed",
        success: "DevicesSuccessfullyUpdated"
    },
    [BACKGROUND_ACTIONS.DeviceExport]: {
        failed: "DeviceExportFailed",
        success: "ExportSucceeded"
    },
    [BACKGROUND_ACTIONS.DeviceTransfer]: {
        failed: "DeviceTransferFailed",
        success: "DevicesSuccessfullyTransferred"
    },
    [BACKGROUND_ACTIONS.FailedTasksRetry]: {
        failed: "FailedTasksRetryFailed",
        success: "FailedTasksSuccessfullyRetried"
    },
    [BACKGROUND_ACTIONS.FileBulkDelete]: {
        failed: "FileBulkDeleteFailed",
        success: "FilesSuccessfullyDeleted"
    },
    [BACKGROUND_ACTIONS.GroupBulkDelete]: {
        failed: "GroupBulkDeleteFailed",
        success: "DeviceGroupsSuccessfullyDeleted"
    },
    [BACKGROUND_ACTIONS.ProductCodeBulkDelete]: {
        failed: "ProductCodeBulkDeleteFailed",
        success: "ProductCodesSuccessfullyDeleted"
    },
    [BACKGROUND_ACTIONS.TaskBulkCancel]: {
        failed: "TaskBulkCancelFailed",
        success: "TasksSuccessfullyCanceled"
    },
    [BACKGROUND_ACTIONS.TaskBulkCreate]: {
        failed: "TaskBulkCreateFailed",
        success: "TaskGroupSuccessfullyCreated"
    },
    [BACKGROUND_ACTIONS.TaskBulkUpdate]: {
        failed: "TaskBulkUpdateFailed",
        success: "TasksSuccessfullyUpdated"
    },
    [BACKGROUND_ACTIONS.UserBulkDelete]: {
        failed: "UserBulkDeleteFailed",
        success: "UsersSuccessfullyDeleted"
    }
};

export { BACKROUND_ACTION_TITLES };
