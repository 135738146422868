import { useContext } from "react";

import { WS_CHANNEL_NAMES, WS_EVENT_NAMES } from "./types";

import AuthContext from "../../../context/auth/authContext";

export const useBroadcasting = () => {
    const { user } = useContext(AuthContext);

    const listenForEvents = (
        channelName: string,
        eventName: string,
        callback: () => void
    ) => {
        const channel = window.Echo.channel(channelName);

        if (channel && channel.events && channel.events[eventName]) {
            return; // Skip subscribing if already listening
        }

        channel.listen(eventName, callback);
    };

    const receiveNotification = (callbackFunc: () => void) => {
        const fullChannelName = `${WS_CHANNEL_NAMES.User}.${user?.id}`;
        const fullEventName = "._creating";

        listenForEvents(fullChannelName, fullEventName, callbackFunc);
    };

    const receiveBackgroundActionCompletion = (
        id: string,
        callbackFunc: () => void
    ) => {
        const fullChannelName = `${WS_CHANNEL_NAMES.BackgroundActions}.${id}`;
        const fullEventName = `.${WS_EVENT_NAMES.BackgroundAction}_updating`;

        listenForEvents(fullChannelName, fullEventName, callbackFunc);
    };

    const leaveChannel = (
        channelName: WS_CHANNEL_NAMES,
        id: number | string
    ) => {
        const fullChannelName = `${channelName}.${id}`;

        if (window.Echo.channel(fullChannelName)) {
            window.Echo.leaveChannel(fullChannelName);
        }
    };

    return {
        receiveBackgroundActionCompletion,
        receiveNotification,
        leaveChannel
    };
};

export { WS_CHANNEL_NAMES };
