import { SetStateAction, useContext, useEffect, useState } from "react";

import ParamsContext from "../../../../context/params/paramsContext";
import AuthContext from "../../../../context/auth/authContext";
import { UserWithCompanyAttributes } from "../../../../context/auth/types";
import {
    API_IS_BEING_CANCELLED,
    Company,
    ENDPOINTS,
    FILTER_NAMES,
    Filter,
    MenuAnchorEl,
    filteredExistingItems,
    getCustomResourceLimits,
    useApi,
    useMediaQueries,
    useParamsFromUrlAndBrowser
} from "../../../../shared";

const useCompanyFilterContent = () => {
    const {
        queryParams,
        companyFilteredValues,
        filterDropdownOptions,
        setCompanyFilteredValues,
        setQueryParams
    } = useContext(ParamsContext);

    const { isSupportUser, user } = useContext(AuthContext);

    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const { toSm, toMd } = useMediaQueries();

    const { intermediateRootCompanyId, companyIdFromUrlAndBrowser } =
        useParamsFromUrlAndBrowser();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

    useEffect(() => {
        checkAndSetRootCompany();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let isSubscribed = true;

        const getRootCompanyList = async () => {
            try {
                setIsLoading(true);

                const { data } = await getData(
                    `${ENDPOINTS.Companies}/filterList?field=${FILTER_NAMES.CompanyId}`
                );

                getFilteredValues(isSubscribed, rootCompanyId as string, data);
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    handleResponse(error);
                    setIsLoading(false);
                }
            }
        };

        const rootCompanyOptions =
            filterDropdownOptions[FILTER_NAMES.RootCompany];

        if (rootCompanyId) {
            if (isSupportUser) {
                rootCompanyOptions === undefined
                    ? getRootCompanyList()
                    : getFilteredValues(
                          isSubscribed,
                          rootCompanyId as string,
                          rootCompanyOptions
                      );
            } else {
                getFilteredValues(isSubscribed);
            }
        }

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);

            setIsLoading(false);
        };

        // eslint-disable-next-line
    }, [rootCompanyId]);

    // Close Menu and Dialog when the screen size changes
    useEffect(() => {
        closeMenu();
        closeDialog();
    }, [toMd]);

    const openMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) =>
        setAnchorEl(e.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    const checkAndSetRootCompany = () => {
        const {
            company,
            company_id,
            company_level,
            company_type,
            root_client_company_id,
            customResourceLimitSettings
        } = user as UserWithCompanyAttributes;

        const getRootCompany = (company: Company): Filter => ({
            id: company.id,
            name: company.name
        });

        const getCompany = async () => {
            try {
                const endpoints = [
                    getData(
                        `${ENDPOINTS.Companies}/${intermediateRootCompanyId}`
                    ),
                    getData(
                        `${ENDPOINTS.Companies}/${intermediateRootCompanyId}/attributes`
                    )
                ];

                const [{ data }, rootCompanyAttributes] =
                    await Promise.all(endpoints);

                const customResourceLimits = await getCustomResourceLimits(
                    intermediateRootCompanyId as string,
                    getData
                );

                setQueryParams({
                    params: {
                        [FILTER_NAMES.RootCompany]: intermediateRootCompanyId
                    },
                    rootInfo: {
                        name: getRootCompany(data),
                        level: rootCompanyAttributes.data.level,
                        type: rootCompanyAttributes.data.type,
                        rootClientCompanyId:
                            rootCompanyAttributes.data.root_client_company_id,
                        customResourceLimitSettings: customResourceLimits
                    }
                });
            } catch (error) {
                setQueryParams({
                    params: {
                        [FILTER_NAMES.RootCompany]: String(company_id),
                        [FILTER_NAMES.CompanyId]: []
                    },
                    resetPage: true,
                    rootInfo: {
                        name: getRootCompany(company),
                        level: company_level,
                        type: company_type,
                        rootClientCompanyId: root_client_company_id,
                        customResourceLimitSettings
                    }
                });
            }
        };

        const isEmpty =
            intermediateRootCompanyId === null ||
            intermediateRootCompanyId === undefined ||
            intermediateRootCompanyId === "";

        const isNumber = Number.isInteger(Number(intermediateRootCompanyId));

        const isSameAsUserCompany =
            String(company_id) === intermediateRootCompanyId;

        if (!isSupportUser || isSameAsUserCompany) {
            setQueryParams({
                params: {
                    [FILTER_NAMES.RootCompany]: String(company_id)
                },

                rootInfo: {
                    name: getRootCompany(company),
                    level: company_level,
                    type: company_type,
                    rootClientCompanyId: root_client_company_id,
                    customResourceLimitSettings
                }
            });
        } else if (isEmpty || !isNumber) {
            setQueryParams({
                params: {
                    [FILTER_NAMES.RootCompany]: String(company_id),
                    [FILTER_NAMES.CompanyId]: []
                },
                resetPage: true,
                rootInfo: {
                    name: getRootCompany(company),
                    level: company_level,
                    type: company_type,
                    rootClientCompanyId: root_client_company_id,
                    customResourceLimitSettings
                }
            });
        } else {
            getCompany();
        }
    };

    const getFilteredValues = async (
        isSubscribed: boolean,
        rootId?: string,
        rootOptions?: any
    ) => {
        try {
            setIsLoading(true);

            const rootIdParams = rootId
                ? `&${FILTER_NAMES.RootCompany}=${rootId}`
                : "";

            const { data: companies } = await getData(
                `${ENDPOINTS.Companies}/filterList?field=${FILTER_NAMES.CompanyId}${rootIdParams}`
            );

            const values = filteredExistingItems(
                companies,
                companyIdFromUrlAndBrowser
            );

            const dropdownOptions = {
                ...(rootOptions
                    ? { [FILTER_NAMES.RootCompany]: rootOptions }
                    : {}),
                [FILTER_NAMES.CompanyId]: companies
            };

            setCompanyFilteredValues(values, dropdownOptions);
        } catch (error) {
            if (isSubscribed && !isCanceled(error)) {
                handleResponse(error);
            }
        }

        setIsLoading(false);
    };

    return {
        isLoading,
        isDialogOpen,
        anchorEl,
        rootCompanyId,
        companyFilteredValues,
        toSm,
        toMd,
        openDialog,
        closeDialog,
        openMenu,
        closeMenu
    };
};

export default useCompanyFilterContent;
