import { SingleInfoItem } from "./Cards/TitleInfo/types";

import { FILTER_NAMES, PLATFORMS } from "../../../../shared";

const getFilteredValues = (
    filterName: FILTER_NAMES,
    id: number,
    name: string
) => ({
    filterName,
    value: [{ id, name }]
});

const isFtPlatform = (platform: PLATFORMS) => platform === PLATFORMS.Ft;

const renderAvailableData = (list: SingleInfoItem[]) =>
    list.filter(item => item.isAvailable);

export { getFilteredValues, isFtPlatform, renderAvailableData };
