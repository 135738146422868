import { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useDialog } from "../../ActionBar/hooks";
import CancelInvite from "../../dialogs/Users/CancelInvite";
import { USER_ACTIONS } from "../../types";
import ResendInvitationIcon from "../../../../assets/customIcons/actions/ResendInvitationIcon";
import { ENDPOINTS, useApi } from "../../../../shared";

const useUserInvitationInlineActions = () => {
    const { isOpenDialog, openDialog, closeDialog } = useDialog();
    const { postData, handleResponse } = useApi();

    const [userData, setUserData] = useState<any>(null);

    const handleResendInvitation = async (
        data: any,
        callbackFn: () => void
    ) => {
        try {
            const response = await postData(
                `${ENDPOINTS.UserInvite}/${data.id}/resend`
            );

            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        callbackFn();
    };

    const inviteInlineActions = [
        {
            title: "cancel invite",
            icon: CloseOutlinedIcon,
            clickAction: (data: any) => {
                setUserData(data);
                openDialog(USER_ACTIONS.DeleteUsers);
            }
        },
        {
            title: "resend invite",
            hasLoader: true,
            icon: ResendInvitationIcon,
            clickAction: (data: any, callbackFn: () => void) =>
                handleResendInvitation(data, callbackFn)
        }
    ];

    const ActionDialogs = () => {
        return (
            <CancelInvite
                isOpen={isOpenDialog[USER_ACTIONS.DeleteUsers]}
                close={closeDialog}
                data={userData}
            />
        );
    };

    return { inviteInlineActions, ActionDialogs };
};

export default useUserInvitationInlineActions;
