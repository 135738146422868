/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import { SetContentProps, TABLE_VIEW_CONTENT } from "./types";

import ListChoice from "../../DesignComponents/ListChoice";

import ThemeContext from "../../../context/theme/themeContext";

const GoBack = ({ setContent }: SetContentProps) => {
    const {
        colorsFacelift: { blue700, gray300, white }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <ListChoice
            title={t("Button##go back")}
            iconLeft={<ChevronLeftRoundedIcon />}
            onClick={() => setContent(TABLE_VIEW_CONTENT.Initial)}
            css={css({
                position: "sticky",
                top: "0",
                background: white,
                zIndex: "1",
                color: blue700,
                borderBottom: `1px solid ${gray300}`,
                paddingBottom: "11px"
            })}
        />
    );
};

export default GoBack;
