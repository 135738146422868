/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@mui/material";

import { DESCRIPTION_TYPE, TriSourceProps } from "./types";
import Selected from "./Selected/Selected";
import Filtered from "./Filtered/Filtered";
import FromFile from "./FromFile/FromFile";
import TriSourceConfirmationDialog from "./components/TriSourceConfirmationDialog/TriSourceConfirmationDialog";
import { ALL_TABS } from "./constants";

import SegmentedToggle, {
    TAB_SIZES
} from "../../../../DesignComponents/SegmentedToggle";
import { TooltipGeneral } from "../../../../DesignComponents/Tooltips";

import Dialog, {
    CombinedDialogProps
} from "../../../../DesignComponents/Dialog";

import AlertContext from "../../../../../context/alert/alertContext";
import DropzoneContext from "../../../../../context/dropzone/dropzoneContext";
import TriSourceContext from "../../../../../context/triSource/triSourceContext";
import { TRI_SOURCES, useMediaQueries } from "../../../../../shared";

const CloneProps = ({ children, ...other }: any) => children(other);

const TriSource = ({
    confirmationDescription,
    isLoading,
    hideTriSource,
    deviceIdentifier,
    modalName,
    isBulkActionLoading,
    hasIgnoreConfirmation,
    setCount,
    ...props
}: TriSourceProps & CombinedDialogProps) => {
    const { hasCloseBtn, hasAutoHide, removeAlert } = useContext(AlertContext);

    const { removeAllFiles } = useContext(DropzoneContext);

    const {
        activeSource,
        needConfirmation,
        getTooltipText,
        getDisabledStatus,
        handleTabChange,
        handleShowConfirmation,
        resetToInitialState
    } = useContext(TriSourceContext);

    const { t } = useTranslation();
    const { fromMd } = useMediaQueries();

    const modalDescription = modalName || DESCRIPTION_TYPE.General;

    const handleSubmit = () => {
        !hasCloseBtn && !hasAutoHide && removeAlert();

        if (!hasIgnoreConfirmation && needConfirmation) {
            handleShowConfirmation(true);
        } else {
            const { submit } = props;

            return submit && submit();
        }
    };

    const handleCount = useCallback(
        (count: number) => setCount && setCount(count),
        [setCount]
    );

    return (
        <>
            <Dialog
                {...props}
                extendToMaxWidth
                isTitleSeparator
                isActionsSeparator
                title={props.title}
                description={props.description}
                close={isBulkActionLoading ? undefined : props.close}
                submit={handleSubmit}
                TransitionProps={{
                    onExited: node => {
                        !hasCloseBtn && !hasAutoHide && removeAlert();
                        resetToInitialState();
                        removeAllFiles();

                        props.TransitionProps?.onExited &&
                            props.TransitionProps?.onExited(node);
                    }
                }}
            >
                {!hideTriSource && (
                    <>
                        <SegmentedToggle
                            data-testid="trisource-tabs"
                            value={activeSource}
                            size={TAB_SIZES.Small}
                            fullWidth={fromMd}
                            tabWidth="263px"
                            onChange={handleTabChange}
                        >
                            {ALL_TABS.map(tab => {
                                const { source, label, icon: Icon } = tab;

                                const isStatusDisabled =
                                    getDisabledStatus(source);

                                const renderTooltip =
                                    !isLoading && isStatusDisabled;

                                const isDisabled =
                                    isLoading || isStatusDisabled;

                                const tooltipText = getTooltipText(source);

                                return (
                                    <CloneProps key={source} value={source}>
                                        {(tabProps: any) => (
                                            <TooltipGeneral
                                                title={
                                                    renderTooltip
                                                        ? tooltipText
                                                        : ""
                                                }
                                            >
                                                <div
                                                    css={css({
                                                        display: "inherit"
                                                    })}
                                                >
                                                    <Tab
                                                        {...tabProps}
                                                        label={t(
                                                            `Dialog##${label}`
                                                        )}
                                                        icon={<Icon />}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </TooltipGeneral>
                                        )}
                                    </CloneProps>
                                );
                            })}
                        </SegmentedToggle>

                        <div
                            css={css({
                                margin: "16px 0"
                            })}
                        >
                            {activeSource === TRI_SOURCES.Selected && (
                                <Selected
                                    description={modalDescription}
                                    setCount={handleCount}
                                />
                            )}

                            {activeSource === TRI_SOURCES.Filtered && (
                                <Filtered
                                    description={modalDescription}
                                    setCount={handleCount}
                                />
                            )}

                            {activeSource === TRI_SOURCES.FromFile && (
                                <FromFile
                                    deviceId={deviceIdentifier}
                                    isLoading={isLoading}
                                    description={modalDescription}
                                    setCount={handleCount}
                                />
                            )}
                        </div>
                    </>
                )}

                {props.children}
            </Dialog>

            <TriSourceConfirmationDialog
                isOpenMainDialog={props.open}
                description={confirmationDescription}
                onSubmit={props.submit}
            />
        </>
    );
};

export default TriSource;
