/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteLicenseProps, LicenseData } from "./types";

import { useUpdateDesktopLicense } from "../hooks";

import DialogActions from "../../../components/DialogActions";

import Dialog from "../../../../../DesignComponents/Dialog";

import ParamsContext from "../../../../../../context/params/paramsContext";
import TableContext from "../../../../../../context/table/tableContext";

import {
    ENDPOINTS,
    maxDialogWidth,
    TABLE_NAMES,
    useApi,
    useMediaQueries
} from "../../../../../../shared";

const DeleteLicense = ({
    data,
    isOpen,
    close,
    isDesktopLicense
}: DeleteLicenseProps) => {
    const { setReloadItems } = useContext(ParamsContext);
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { deleteData, handleResponse } = useApi();
    const { updateLicenseDetails } = useUpdateDesktopLicense();

    const [isLoading, setIsLoading] = useState(false);

    const [licenseData, setLicenseData] = useState<LicenseData>({
        id: null,
        name: null
    });

    useEffect(() => {
        if (data) {
            const { id, name } = data;

            setLicenseData({
                id,
                name
            });
        }
    }, [data]);

    const { id, name } = licenseData;

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const response = await deleteData(
                isDesktopLicense
                    ? ENDPOINTS.DesktopLicenses
                    : ENDPOINTS.OfflineLicenses,
                id!
            );

            close();

            if (isDesktopLicense) {
                setReloadItems(TABLE_NAMES.DesktopLicenses);
            } else {
                updateLicenseDetails(detailsData.id);
            }

            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            isActionsSeparator
            title={t(
                `General##delete ${isDesktopLicense ? "desktop" : "offline"} license`
            )}
            description={t(
                `Dialog##${isDesktopLicense ? "desktop" : "offline"} license delete description`,
                {
                    name
                }
            )}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setLicenseData({
                        id: null,
                        name: null
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={id !== null && name}
                    confirmationButtonText={t("Button##delete")}
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        />
    );
};

export default DeleteLicense;
