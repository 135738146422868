import { useTranslation } from "react-i18next";

import { CheckboxOptionProps } from "./types";

import Checkbox from "../../../../../../../DesignComponents/Checkbox";

const CheckboxOption = ({
    value,
    isLoading,
    onChange
}: CheckboxOptionProps) => {
    const { t } = useTranslation();

    return (
        <Checkbox
            label={t(
                "Dialog##platform compatibility##create tasks for incompatible devices"
            )}
            checked={value}
            onChange={(_, isChecked) => onChange(isChecked)}
            disabled={isLoading}
            customStyle={{
                marginTop: "16px"
            }}
        />
    );
};

export default CheckboxOption;
