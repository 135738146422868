/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

import { DoughnutChartProps } from "./types";

import { deviceStatsValues } from "../constants";

import { emptyDoughnut } from "../../../Dashboard/LeftSide/Statistics/constants";

import ThemeContext from "../../../../context/theme/themeContext";
import { useFormattedNumber } from "../../../../shared";

Chart.register(Tooltip, Legend, ArcElement);

export const DoughnutChart = ({
    statItem,
    diameter,
    cutout,
    numberFontStyle,
    centerTitle,
    centerTitleFontStyle,
    topAlignment,
    centerItemsLineHeight
}: DoughnutChartProps) => {
    const {
        colorsFacelift: { gray300, green600, red600 }
    } = useContext(ThemeContext);

    const { renderFormattedNumber } = useFormattedNumber();

    const [offlineStat, setOfflineStat] = useState<number>(0);
    const [inactiveStat, setInactiveStat] = useState<number>(0);
    const [onlineStat, setOnlineStat] = useState<number>(0);

    useEffect(() => {
        setOfflineStat(0);
        setInactiveStat(0);
        setOnlineStat(0);

        statItem &&
            statItem.stats.map((i: any) => {
                if (i[1].name === deviceStatsValues.offline) {
                    setOfflineStat(i[1].count);
                }
                if (i[1].name === deviceStatsValues.inactive) {
                    setInactiveStat(i[1].count);
                }
                if (i[1].name === deviceStatsValues.online) {
                    setOnlineStat(i[1].count);
                }
                return null;
            });
    }, [statItem, onlineStat, offlineStat, inactiveStat]);

    const data: any = {
        datasets: [
            {
                data: [onlineStat, offlineStat, inactiveStat],
                backgroundColor: [green600, red600, gray300],
                borderRadius: 4
            }
        ]
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                display: false
            },
            emptyDoughnut: {
                color: gray300,
                width: 10,
                radiusDecrease: 6,
                cutout: cutout
            }
        },
        cutout: cutout,
        hover: { mode: null }
    };

    return (
        <div
            style={{
                width: `${diameter}px`,
                height: `${diameter}px`,
                position: "relative"
            }}
        >
            <Doughnut
                data={data}
                options={options}
                width={+diameter}
                height={+diameter}
                plugins={[emptyDoughnut]}
            />

            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    top: `${topAlignment}px`,
                    left: 0,
                    textAlign: "center",
                    lineHeight: `${centerItemsLineHeight}px`
                }}
            >
                <span css={css(numberFontStyle)}>
                    {renderFormattedNumber(statItem.total)}
                </span>

                <br />

                <span css={css(centerTitleFontStyle)}>{centerTitle}</span>
            </div>
        </div>
    );
};
