import { useState } from "react";

import {
    AdditionalDataValue,
    ChangeOrderParams,
    ORDER
} from "../SecondSelectContent/InternalLog/types";

const useInternalLog = () => {
    const [order, setOrder] = useState(ORDER.Newest);
    const [inputValue, setInputValue] = useState<AdditionalDataValue>(null);

    const changeOrder = (e: ChangeOrderParams) => {
        const value = e.target.value as ORDER;

        setInputValue(null);
        setOrder(value);
    };

    const changeInputValue = (value: AdditionalDataValue) =>
        setInputValue(value);

    return {
        additionalData: { order, inputValue },
        changeOrder,
        changeInputValue
    };
};

export { useInternalLog };
