import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useConstants from "./useConstants";

import { LIMIT_TYPES, LOADERS } from "../types";

import { isUserAndRootSameCompany } from "../../shared";

import { COMPANY_ACTIONS, Close } from "../../../../types";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import AuthContext from "../../../../../../context/auth/authContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    RESOURCE_LIMIT_SETTINGS,
    isNumericValue,
    isNumericIntegerValue,
    useApi,
    useFormattedNumber,
    useIsMounted,
    getCustomResourceLimits
} from "../../../../../../shared";

export const useCustomResourceLimits = (isOpen: boolean, close: Close) => {
    const { setAlert } = useContext(AlertContext);

    const { rootCompany, changeCustomResourceLimits } =
        useContext(ParamsContext);

    const { user, changeUserCustomResourceLimits } = useContext(AuthContext);

    const { t } = useTranslation();
    const { defaultValues, maxValues } = useConstants();
    const { renderFormattedNumber } = useFormattedNumber();
    const isMounted = useIsMounted();

    const { getData, postData, handleResponse, isCanceled, cancelSource } =
        useApi();

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [limitType, setLimitType] = useState("");

    const [data, setData] = useState({
        [RESOURCE_LIMIT_SETTINGS.Companies]: "",
        [RESOURCE_LIMIT_SETTINGS.Users]: "",
        [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: "",
        [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: ""
    });

    const [error, setError] = useState({
        [RESOURCE_LIMIT_SETTINGS.Companies]: null,
        [RESOURCE_LIMIT_SETTINGS.Users]: null,
        [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: null,
        [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: null
    });

    const [isLoading, setIsLoading] = useState({
        [LOADERS.Data]: true,
        [LOADERS.Submit]: false
    });

    const {
        companies: companiesDefault,
        users: usersDefault,
        tasks_per_group: tasksPerGroupDefault,
        configuration_files_per_device: configPerDeviceDefault
    } = defaultValues;

    const {
        companies,
        users,
        tasks_per_group,
        configuration_files_per_device
    } = data;

    const {
        companies: companiesError,
        users: usersError,
        tasks_per_group: tasksPerGroupError,
        configuration_files_per_device: configPerDeviceError
    } = error;

    const rootId = rootCompany?.id;

    useEffect(() => {
        const getLimits = async () => {
            const customResourceLimits = await getCustomResourceLimits(
                rootId as number,
                getData,
                () =>
                    setIsLoading(prev => ({
                        ...prev,
                        [LOADERS.Data]: true
                    }))
            );

            if (customResourceLimits !== null) {
                if (isMounted) {
                    const {
                        companies,
                        users,
                        tasks_per_group,
                        configuration_files_per_device
                    } = customResourceLimits;

                    setLimitType(LIMIT_TYPES.Custom);

                    setData({
                        [RESOURCE_LIMIT_SETTINGS.Companies]:
                            companies.toString(),
                        [RESOURCE_LIMIT_SETTINGS.Users]: users.toString(),
                        [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]:
                            tasks_per_group.toString(),
                        [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]:
                            configuration_files_per_device.toString()
                    });
                }
            } else {
                if (isMounted && !isCanceled(error)) {
                    setDefaultValues();
                }
            }

            setIsLoading(prev => ({ ...prev, [LOADERS.Data]: false }));
        };

        isOpen && getLimits();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isOpen, rootId]);

    const handleCloseDialog = () => close(COMPANY_ACTIONS.CustomResourceLimits);

    const setDefaultValues = () => {
        setLimitType(LIMIT_TYPES.Automatic);

        setData({
            [RESOURCE_LIMIT_SETTINGS.Companies]: companiesDefault.toString(),
            [RESOURCE_LIMIT_SETTINGS.Users]: usersDefault.toString(),
            [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]:
                tasksPerGroupDefault.toString(),
            [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]:
                configPerDeviceDefault.toString()
        });
    };

    const handleChangeData = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name as RESOURCE_LIMIT_SETTINGS;
        const value = e.target.value;

        const minValue = defaultValues[name];
        const maxValue = maxValues[name];

        const getErrorValue = () => {
            const currentValue = Number(value);

            const isConfigFilesPerDevice =
                name === RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice;

            if (value.length !== 0) {
                if (!isConfigFilesPerDevice && !isNumericIntegerValue(value)) {
                    return t("Dialog##only integers");
                }

                if (isConfigFilesPerDevice && !isNumericValue(value)) {
                    return t("Dialog##only numbers");
                }
            }

            if (value.length === 0 || currentValue < minValue) {
                return t("Dialog##minimum value is", {
                    value: renderFormattedNumber(minValue)
                });
            }

            if (currentValue > maxValue) {
                return t("Dialog##maximum value is", {
                    value: renderFormattedNumber(maxValue)
                });
            }

            return null;
        };

        const errorValue = getErrorValue();

        setData({
            ...data,
            [name]: value
        });

        setError({ ...error, [name]: errorValue });
    };

    const resetData = () => {
        setLimitType("");

        setData({
            [RESOURCE_LIMIT_SETTINGS.Companies]: "",
            [RESOURCE_LIMIT_SETTINGS.Users]: "",
            [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: "",
            [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: ""
        });

        setError({
            [RESOURCE_LIMIT_SETTINGS.Companies]: null,
            [RESOURCE_LIMIT_SETTINGS.Users]: null,
            [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: null,
            [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: null
        });

        setIsLoading({
            [LOADERS.Data]: true,
            [LOADERS.Submit]: false
        });
    };

    const handleSubmit = async () => {
        const newCustomResourceLimits = {
            [RESOURCE_LIMIT_SETTINGS.Companies]: Number(companies),
            [RESOURCE_LIMIT_SETTINGS.Users]: Number(users),
            [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: Number(tasks_per_group),
            [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: Number(
                configuration_files_per_device
            )
        };

        const payload = {
            company_id: String(rootId),
            ...newCustomResourceLimits
        };

        try {
            setIsLoading(prev => ({
                ...prev,
                [LOADERS.Submit]: true
            }));

            await postData(ENDPOINTS.CustomResourceLimits, payload);

            handleCloseDialog();

            changeCustomResourceLimits(newCustomResourceLimits);

            isUserAndRootSameCompany(user?.company_id, rootId) &&
                changeUserCustomResourceLimits(newCustomResourceLimits);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##company resource limits changed")
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(prev => ({
            ...prev,
            [LOADERS.Submit]: false
        }));
    };

    const openConfirmationDialog = () => setConfirmationOpen(true);
    const closeConfirmationDialog = () => setConfirmationOpen(false);

    const isAtLeastOneHigher =
        !isLoading[LOADERS.Data] &&
        (Number(companies) > companiesDefault ||
            Number(users) > usersDefault ||
            Number(tasks_per_group) > tasksPerGroupDefault ||
            Number(configuration_files_per_device) > configPerDeviceDefault);

    const hasNoErrors =
        companiesError === null &&
        usersError === null &&
        tasksPerGroupError === null &&
        configPerDeviceError === null;

    return {
        rootCompany,
        limitType,
        companies,
        users,
        tasks_per_group,
        configuration_files_per_device,
        isAtLeastOneHigher,
        hasNoErrors,
        error,
        isLoading,
        isConfirmationOpen,
        handleCloseDialog,
        setDefaultValues,
        handleChangeData,
        resetData,
        handleSubmit,
        openConfirmationDialog,
        closeConfirmationDialog
    };
};
