import SingleInfo from "./SingleInfo";
import { TitleInfoProps } from "./types";

import { renderAvailableData } from "../../functions";

import { InfoTooltip } from "../../../../../Table/components";

const TitleInfo = ({ cardName, list }: TitleInfoProps) => {
    return (
        <InfoTooltip
            title={
                <ul>
                    {renderAvailableData(list).map(({ title }) => {
                        return (
                            <SingleInfo
                                key={title}
                                cardName={cardName}
                                title={title}
                            />
                        );
                    })}
                </ul>
            }
        />
    );
};

export { TitleInfo };
