import { useTranslation } from "react-i18next";

import SingleAttribute from "./SingleAttribute";
import { TaskAttributesProps } from "./types";

const TaskAttributes = ({ separateAttributes }: TaskAttributesProps) => {
    const { t } = useTranslation();

    return (
        <>
            <h4>{t("Table##attributes")}</h4>

            {separateAttributes.map(([key, value]: any) => {
                return (
                    <SingleAttribute key={key} keyName={key} value={value} />
                );
            })}
        </>
    );
};

export default TaskAttributes;
