/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { DetailsContainerProps } from "./types";

import { useSharedStyles } from "../../hooks";

import ThemeContext from "../../../../context/theme/themeContext";

const DetailsContainer = ({ children }: DetailsContainerProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { sharedStyles } = useSharedStyles();

    return (
        <div
            css={css({
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(33.33%, 1fr))",
                marginTop: "8px",
                paddingBottom: "12px",

                "& > div:not(.configuration-and-sim)": {
                    padding: "16px 24px",
                    fontSize: "14px",
                    letterSpacing: "0.1px",

                    ...sharedStyles
                },

                "& > div:nth-of-type(1)": {
                    border: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(2), & > div:nth-of-type(3)": {
                    borderTop: `1px solid ${gray200}`,
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(4)": {
                    borderLeft: `1px solid ${gray200}`,
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "& > div:nth-of-type(5)": {
                    borderRight: `1px solid ${gray200}`,
                    borderBottom: `1px solid ${gray200}`
                },

                "@media (max-width:1439px)": {
                    gridTemplateColumns: "repeat(2, 1fr)",

                    "& > div:nth-of-type(3)": {
                        borderTop: "none",
                        borderLeft: `1px solid ${gray200}`
                    },

                    "& > div:nth-of-type(4)": {
                        borderLeft: "none"
                    },

                    "& > div:nth-of-type(5)": {
                        borderLeft: `1px solid ${gray200}`
                    }
                },

                "@media (max-width:839px)": {
                    gridTemplateColumns: "1fr",

                    "& > div:nth-of-type(2)": {
                        borderTop: "none",
                        borderLeft: `1px solid ${gray200}`
                    },

                    "& > div:nth-of-type(4)": {
                        borderLeft: `1px solid ${gray200}`
                    }
                }
            })}
        >
            {children}
        </div>
    );
};

export default DetailsContainer;
