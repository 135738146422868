import TxFwBundleFileData from "./TxFwBundleFileData";
import GeneralFileData from "./GeneralFileData ";
import { TaskFileDataProps } from "./types";

const TaskFileData = ({ generalData, txFwBundleData }: TaskFileDataProps) => {
    const { id, file } = generalData;

    const hasId = id !== undefined && id !== null;

    const renderData = () => {
        if (hasId) {
            return txFwBundleData !== null ? (
                <TxFwBundleFileData
                    id={id}
                    mainFile={file}
                    additionalData={txFwBundleData}
                />
            ) : (
                <GeneralFileData id={id} file={file} />
            );
        }

        return null;
    };

    return renderData();
};

export default TaskFileData;
