/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReactElement, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import CompanySettingsIcon from "@mui/icons-material/ExpandMoreRounded";

import { useDialog, useMenu } from "./hooks";
import ContentFrameWithButton from "./components/ContentFrameWithButton";

import CreateCompany from "../dialogs/Companies/CreateCompany";
import TransferCompanies from "../dialogs/Companies/TransferCompanies";
import CompanyLevelManualChange from "../dialogs/Companies/CompanyLevelManualChange";
import CustomResourceLimits from "../dialogs/Companies/CustomResourceLimits";
import { COMPANY_ACTIONS } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../DesignComponents/Menu";
import ListChoice from "../../DesignComponents/ListChoice";

import ParamsContext from "../../../context/params/paramsContext";
import AuthContext from "../../../context/auth/authContext";
import TableContext from "../../../context/table/tableContext";
import CompanyLevelIcon from "../../../assets/customIcons/actions/CompanyLevelIcon";
import CustomResourceLimitsIcon from "../../../assets/customIcons/actions/CustomResourceLimitsIcon";
import {
    MenuAnchorEl,
    isClientTypeCompany,
    isPremiumLevel,
    useMediaQueries
} from "../../../shared";

const CompaniesBar = () => {
    const { rootCompanyLevel, rootCompanyType } = useContext(ParamsContext);
    const { isAdminUser, isSystemUser } = useContext(AuthContext);
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm, toMd } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();

    const renderCompanySettingsBtn = () => {
        const isClientType = isClientTypeCompany(rootCompanyType);

        if (isSystemUser) {
            const isPremium = isPremiumLevel(rootCompanyLevel);

            if (isClientType && isPremium) {
                return renderIndividualButton(
                    t("Button##company settings"),
                    <CompanySettingsIcon />,
                    e => handleOpenMenu(e),
                    false
                );
            }

            if (isClientType) {
                return renderIndividualButton(
                    t("General##company level"),
                    <CompanyLevelIcon />,
                    () => openDialog(COMPANY_ACTIONS.CompanyLevelManualChange)
                );
            }

            if (isPremium) {
                return renderIndividualButton(
                    t("General##custom resource limits"),
                    <CustomResourceLimitsIcon />,
                    () => openDialog(COMPANY_ACTIONS.CustomResourceLimits)
                );
            }
        }

        if (isAdminUser && isClientType) {
            return renderIndividualButton(
                t("General##company level"),
                <CompanyLevelIcon />,
                () => openDialog(COMPANY_ACTIONS.CompanyLevelManualChange)
            );
        }

        return null;
    };

    const renderIndividualButton = (
        title: string,
        icon: ReactElement,
        onClick: (e: { currentTarget: SetStateAction<MenuAnchorEl> }) => void,
        iconLeft: boolean = true
    ) => (
        <>
            {toMd ? (
                <Button
                    variant={BUTTON_VARIANTS.IconOnly}
                    size={BUTTON_SIZES.Small}
                    color={BUTTON_COLORS.White}
                    icon={icon}
                    onClick={onClick}
                />
            ) : (
                <Button
                    variant={
                        iconLeft
                            ? BUTTON_VARIANTS.IconLeft
                            : BUTTON_VARIANTS.IconRight
                    }
                    size={BUTTON_SIZES.Small}
                    color={BUTTON_COLORS.White}
                    icon={icon}
                    onClick={onClick}
                >
                    {title}
                </Button>
            )}

            {!iconLeft && (
                <Menu
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorEl={anchorEl}
                    anchorOrigin={centeredAnchorOrigin}
                    transformOrigin={centeredTransformOrigin}
                    css={css({
                        "& .MuiMenu-paper": {
                            minWidth: "211px"
                        }
                    })}
                >
                    <ListChoice
                        title={t("General##company level")}
                        iconLeft={<CompanyLevelIcon />}
                        onClick={() => {
                            handleCloseMenu();
                            openDialog(
                                COMPANY_ACTIONS.CompanyLevelManualChange
                            );
                        }}
                    />

                    <ListChoice
                        title={t("General##custom resource limits")}
                        iconLeft={<CustomResourceLimitsIcon />}
                        onClick={() => {
                            handleCloseMenu();
                            openDialog(COMPANY_ACTIONS.CustomResourceLimits);
                        }}
                    />
                </Menu>
            )}
        </>
    );

    return (
        <>
            <ContentFrameWithButton button={renderCompanySettingsBtn()}>
                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.TextOnly}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.Primary}
                        onClick={() =>
                            openDialog(COMPANY_ACTIONS.CreateCompany)
                        }
                    >
                        {t("Button##create")}
                    </Button>
                </ActionToolbarButtonWrapper>

                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.TextOnly}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.White}
                        onClick={() =>
                            openDialog(COMPANY_ACTIONS.TransferCompanies)
                        }
                    >
                        {t("Button##transfer")}
                    </Button>
                </ActionToolbarButtonWrapper>
            </ContentFrameWithButton>

            <CreateCompany
                isOpen={isOpenDialog[COMPANY_ACTIONS.CreateCompany]}
                close={closeDialog}
            />

            <TransferCompanies
                isOpen={isOpenDialog[COMPANY_ACTIONS.TransferCompanies]}
                close={closeDialog}
            />

            <CompanyLevelManualChange
                isOpen={isOpenDialog[COMPANY_ACTIONS.CompanyLevelManualChange]}
                close={closeDialog}
            />

            <CustomResourceLimits
                isOpen={isOpenDialog[COMPANY_ACTIONS.CustomResourceLimits]}
                close={closeDialog}
            />
        </>
    );
};

export default CompaniesBar;
