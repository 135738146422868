export const API_IS_BEING_CANCELLED = "Api is being cancelled";
export const CARRIER = "carrier";
export const COMPANY_FILTER = "companyFilter";
export const DESKTOP_DRAWER_OPEN = "DesktopDrawerOpen";
export const DEVICE_EXPORT = "DeviceExport";
export const FIND_TOOL_FILTER = "findToolFilter";
export const INVALID = "Invalid";
export const INVALID_DATE = "invalidDate";
export const IS_DEVICE_STATISTICS_OPEN = "isDeviceStatisticsOpen";
export const I18_NEXT_LANG = "i18nextLng";
export const LEGACY = "Legacy";
export const NOT_ASSIGNED = "not assigned";
export const ROOT_COMPANY = "rootCompany";
export const SPEC_ID_NOT_ASSIGNED_VALUE = "null,0,1";
export const UNKNOWN = "Unknown";
