/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { InfoBannerProps } from "./types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../DesignComponents/BannerMessage";

const InfoBanner = ({ title }: InfoBannerProps) => {
    return (
        <BannerMessage
            status={BANNER_MESSAGE_STATUSES.Warning}
            title={title}
            css={css({
                marginTop: "16px"
            })}
        />
    );
};

export default InfoBanner;
