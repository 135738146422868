import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FirmwareConfigurationDropdownProps } from "./CreateGroup/types";
import { useDisabled } from "./hooks";

import UploadFile from "../Files/UploadFile";

import { useDialog } from "../../ActionBar/hooks";
import { FILE_ACTIONS } from "../../types";

import QueryDropdown from "../../../QueryDropdown";
import { DROPDOWN_SIZES, ENDPOINTS, ID_TYPE } from "../../../../shared";

const FirmwareConfigurationDropdown = ({
    companyDropdownValue,
    dropdownFileType,
    fileTypeValue,
    isLoading,
    testId,
    handleChange
}: FirmwareConfigurationDropdownProps) => {
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const { isRestricted, getMissingFileTooltipTitle, getTooltipTitle } =
        useDisabled();

    const { t } = useTranslation();

    const [tooltipTitle, setTooltipTitle] = useState(
        getMissingFileTooltipTitle(dropdownFileType)
    );

    const [hasUploadedFile, setHasUploadedFile] = useState(false);

    useEffect(() => {
        setTooltipTitle(getMissingFileTooltipTitle(dropdownFileType));
    }, [getMissingFileTooltipTitle, dropdownFileType]);

    const getLabelLeft = useCallback(() => {
        const labelLeftMain = t(`Dialog##${dropdownFileType}`);

        return `${labelLeftMain} (${t("Dialog##optional")})`;
    }, [dropdownFileType, t]);

    const renderNoOptionsTooltip =
        !isLoading && !isRestricted && companyDropdownValue !== null;

    return (
        <>
            <QueryDropdown
                data-testid={testId}
                fullWidth
                noOptionsTooltip={
                    renderNoOptionsTooltip
                        ? {
                              title: tooltipTitle,
                              fileType: dropdownFileType,
                              hasUploadedFile,
                              buttonText: t("Button##upload file"),
                              onButtonClick: () =>
                                  openDialog(FILE_ACTIONS.UploadFiles)
                          }
                        : undefined
                }
                idType={ID_TYPE.Id}
                size={DROPDOWN_SIZES.Medium}
                labelLeft={getLabelLeft()}
                value={fileTypeValue}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                resource={
                    companyDropdownValue
                        ? `${ENDPOINTS.Files}?type=${dropdownFileType}&company_id=${companyDropdownValue?.id}`
                        : ""
                }
                change={(_, fileValue) => handleChange(fileValue)}
                customStyle={{ margin: "16px 0 16px 0" }}
                readOnly={isRestricted || isLoading}
                disabled={!isRestricted && !companyDropdownValue}
                tooltipTitle={getTooltipTitle(companyDropdownValue)}
            />

            <UploadFile
                isOpen={isOpenDialog[FILE_ACTIONS.UploadFiles]}
                close={closeDialog}
                onEnter={() => setTooltipTitle("")}
                onExited={() =>
                    setTooltipTitle(
                        getMissingFileTooltipTitle(dropdownFileType)
                    )
                }
                successCallback={() => setHasUploadedFile(!hasUploadedFile)}
            />
        </>
    );
};

export default FirmwareConfigurationDropdown;
