import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import ContentFrame from "./components/ContentFrame";
import { useDialog } from "./hooks";

import UploadFile from "../dialogs/Files/UploadFile";
import { FILE_ACTIONS } from "../types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

const FilesBar = () => {
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    return (
        <Fragment>
            <ContentFrame>
                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.TextOnly}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.Primary}
                        onClick={() => openDialog(FILE_ACTIONS.UploadFiles)}
                    >
                        {t("Button##upload")}
                    </Button>
                </ActionToolbarButtonWrapper>
            </ContentFrame>

            <UploadFile
                isOpen={isOpenDialog[FILE_ACTIONS.UploadFiles]}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default FilesBar;
