/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";

import SpecificLogs from "./SpecificLogs";
import SortableLogs from "./SortableLogs";
import { isSortableValueCorrect, isSpecificValueCorrect } from "./functions";
import { InternalLogProps, ORDER } from "./types";

import { PAYLOAD_ID } from "../../types";

import Radio from "../../../../../../../DesignComponents/Radio";

import ThemeContext from "../../../../../../../../context/theme/themeContext";

const InternalLog = ({
    isLoading,
    additionalData,
    changePayload,
    changeOrder,
    changeInputValue
}: InternalLogProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { inputValue, order } = additionalData;

    useEffect(() => {
        const isLogValueCorrect =
            inputValue !== null &&
            (order === ORDER.Specific
                ? isSpecificValueCorrect(inputValue.rawValue)
                : isSortableValueCorrect(inputValue.count));

        if (isLogValueCorrect) {
            const logPayload = {
                order,
                ...inputValue
            };

            changePayload({
                id: PAYLOAD_ID.Attributes,
                value: JSON.stringify(logPayload)
            });
        } else {
            changePayload(null);
        }

        // eslint-disable-next-line
    }, [order, inputValue]);

    const specificLogsValue = inputValue?.rawValue ? inputValue.rawValue : "";
    const sortableLogsValue = inputValue ? inputValue.count : "";

    return (
        <div>
            <div
                css={css({
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    marginBottom: "6px"
                })}
            >
                {t("Dialog##begin download")}:
            </div>

            <RadioGroup
                value={order}
                onChange={changeOrder}
                sx={{
                    marginBottom: "14px",

                    "& > label + label": {
                        marginTop: "6px"
                    }
                }}
            >
                <Radio
                    value={ORDER.Newest}
                    label={t(`Dialog##${ORDER.Newest}`)}
                    disabled={isLoading}
                />

                <Radio
                    value={ORDER.Oldest}
                    label={t(`Dialog##${ORDER.Oldest}`)}
                    disabled={isLoading}
                />

                <Radio
                    value={ORDER.Specific}
                    label={t(`Dialog##${ORDER.Specific}`)}
                    disabled={isLoading}
                />
            </RadioGroup>

            {order === ORDER.Specific ? (
                <SpecificLogs
                    order={order}
                    value={specificLogsValue}
                    isLoading={isLoading}
                    change={changeInputValue}
                />
            ) : (
                <SortableLogs
                    order={order}
                    value={sortableLogsValue}
                    isLoading={isLoading}
                    change={changeInputValue}
                />
            )}
        </div>
    );
};

export default InternalLog;
