import { RectangleLoader } from "../../../../../../../../shared";

const Loader = () => {
    return (
        <>
            <RectangleLoader height={20} customStyle={{ margin: "8px 0" }} />
            <RectangleLoader height={36} />
        </>
    );
};

export default Loader;
