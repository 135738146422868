import { useEffect } from "react";

import Vehicles from "./Vehicles";
import { CanVehiclesProps } from "./types";

import { PAYLOAD_ID } from "../../types";

const CanVehicles = ({
    isLoading,
    additionalData,
    changePayload,
    changeMake,
    changeModel
}: CanVehiclesProps) => {
    const { isVehiclesLoading, vehicles, makes, make, model } = additionalData;

    useEffect(() => {
        const modelId = model ? model.vehicle_id : null;

        if (modelId !== null) {
            changePayload({
                id: PAYLOAD_ID.Attributes,
                value: JSON.stringify({ vehicle_id: modelId })
            });
        } else {
            changePayload(null);
        }
        // eslint-disable-next-line
    }, [model]);

    return (
        <Vehicles
            isSubmitLoading={isLoading}
            isVehiclesLoading={isVehiclesLoading}
            vehicles={vehicles}
            makes={makes}
            make={make}
            model={model}
            changeMake={changeMake}
            changeModel={changeModel}
        />
    );
};

export default CanVehicles;
