export const AUTH_ERROR = "AUTH_ERROR";
export const CHANGE_USER_COMPANY_LEVEL = "CHANGE_USER_COMPANY_LEVEL";
export const CHANGE_USER_CUSTOM_RESOURCE_LIMITS =
    "CHANGE_USER_CUSTOM_RESOURCE_LIMITS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE";
export const REMOVE_LOADING = "REMOVE_LOADING";
export const RESET_AUTH_TYPE = "RESET_AUTH_TYPE";
export const SET_COOKIE_CONSENT = "SET_COOKIE_CONSENT";
export const SET_LOADING = "SET_LOADING";
export const SET_USER = "SET_USER";
export const TOGGLE_2FA_ENABLE = "TOGGLE_2FA_ENABLE";
export const TOGGLE_2FA_VERIFICATION = "TOGGLE_2FA_VERIFICATION";
