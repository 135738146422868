/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import {
    DateValidationError,
    PickerChangeHandlerContext
} from "@mui/x-date-pickers";

import { EDIT_VALUE_NAMES, STATUS, Value } from "./types";

import { CREATE_VALUE_NAMES } from "../../CreateDesktopLicense/types";
import { useLicenseValidation } from "../../hooks";

import DialogActions from "../../../../components/DialogActions";
import { EditInlineDialogProps } from "../../../../types";

import Dialog from "../../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../DesignComponents/InputField";
import Autocomplete from "../../../../../../DesignComponents/Autocomplete";
import DatePicker, { useDatePicker } from "../../../../../../DatePicker";

import ParamsContext from "../../../../../../../context/params/paramsContext";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    TABLE_NAMES,
    disableDates,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const EditDesktopLicense = ({ data, isOpen, close }: EditInlineDialogProps) => {
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { parseDatePickerValue } = useDatePicker();
    const { handleResponse, updateData } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [hasExpiresAtError, setHasExpiresAtError] = useState(false);

    const [value, setValue] = useState<Value>({
        [EDIT_VALUE_NAMES.Name]: "",
        [EDIT_VALUE_NAMES.Enabled]: false,
        [EDIT_VALUE_NAMES.ExpiresAt]: null,
        [EDIT_VALUE_NAMES.ClientIp]: ""
    });

    const { name, enabled, expires_at, client_ip } = value;

    const {
        nameError,
        ipv4Error,
        MAX_EXPIRATION_YEARS,
        isValidIpv4,
        validateHardwareKeyOrIpv4,
        resetError
    } = useLicenseValidation(name);

    useEffect(() => {
        if (data) {
            const { name, enabled, expires_at, client_ip } = data;

            setValue({
                [EDIT_VALUE_NAMES.Name]: name,
                [EDIT_VALUE_NAMES.Enabled]: enabled,
                [EDIT_VALUE_NAMES.ExpiresAt]: expires_at,
                [EDIT_VALUE_NAMES.ClientIp]: client_ip
            });
        }
    }, [data]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const newValue = e.target.value;
        const isIpInput = name === EDIT_VALUE_NAMES.ClientIp;

        resetError(false, isIpInput);

        setValue(prevValue => ({
            ...prevValue,
            [name]: newValue
        }));
    };

    const handleExpiresAtChange = (
        dateValue: Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>
    ) => {
        const newValue = parseDatePickerValue(dateValue, context, expires_at);

        setValue(prevValue => ({
            ...prevValue,
            [EDIT_VALUE_NAMES.ExpiresAt]: newValue
        }));
    };

    const handleExpiresAtError = (error: string | null) =>
        setHasExpiresAtError(error !== null);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const payload = {
                name,
                enabled,
                expires_at,
                client_ip: client_ip || null
            };

            const response = await updateData(
                ENDPOINTS.DesktopLicenses,
                data.id,
                payload
            );

            close();
            setReloadItems(TABLE_NAMES.DesktopLicenses);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }
        setIsLoading(false);
    };

    return (
        <Dialog
            isTitleSeparator
            isActionsSeparator
            title={t("General##edit desktop license")}
            open={isOpen}
            close={close}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setValue({
                        [EDIT_VALUE_NAMES.Name]: "",
                        [EDIT_VALUE_NAMES.Enabled]: false,
                        [EDIT_VALUE_NAMES.ExpiresAt]: null,
                        [EDIT_VALUE_NAMES.ClientIp]: ""
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={close}
                    isLoading={isLoading}
                    isReadyToConfirm={
                        name.trim() &&
                        !nameError &&
                        expires_at !== null &&
                        !hasExpiresAtError &&
                        isValidIpv4(client_ip || "")
                    }
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            <div
                css={css({
                    "& > div + div": {
                        marginTop: "16px"
                    },

                    marginBottom: "16px"
                })}
            >
                <InputField
                    fullWidth
                    required
                    size={INPUT_FIELD_SIZES.Medium}
                    name={EDIT_VALUE_NAMES.Name}
                    value={name}
                    readOnly={isLoading}
                    labelLeft={t("Dialog##name")}
                    placeholder={t("Dialog##enter")}
                    onChange={handleInputChange}
                    errorText={nameError}
                />

                <Autocomplete
                    labelLeft={t("Table##status")}
                    textFieldParams={{ placeholder: t("Dialog##select") }}
                    fullWidth
                    disableClearable
                    size={DROPDOWN_SIZES.Medium}
                    options={Object.values(STATUS)}
                    value={enabled ? STATUS.Enabled : STATUS.Disabled}
                    loading={isLoading}
                    readOnly={isLoading}
                    getOptionLabel={option => t(`Table##license ${option}`)}
                    onChange={(_, val: STATUS) =>
                        setValue(prevValue => ({
                            ...prevValue,
                            [EDIT_VALUE_NAMES.Enabled]: val === STATUS.Enabled
                        }))
                    }
                />

                <DatePicker
                    label={t("Dialog##expires at")}
                    disablePast
                    readOnly={isLoading}
                    value={expires_at ? moment.utc(expires_at) : null}
                    shouldDisableYear={date =>
                        disableDates(date, MAX_EXPIRATION_YEARS, "years")
                    }
                    shouldDisableMonth={date =>
                        disableDates(date, MAX_EXPIRATION_YEARS, "years")
                    }
                    shouldDisableDate={date =>
                        disableDates(date, MAX_EXPIRATION_YEARS, "years")
                    }
                    onChange={handleExpiresAtChange}
                    getError={handleExpiresAtError}
                    textFieldParams={{
                        required: true
                    }}
                />

                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    name={EDIT_VALUE_NAMES.ClientIp}
                    value={client_ip || ""}
                    readOnly={isLoading}
                    labelLeft={`${t("General##client ip")} (${t("Dialog##optional")})`}
                    placeholder={t("Dialog##enter")}
                    onChange={handleInputChange}
                    onBlur={() =>
                        validateHardwareKeyOrIpv4(
                            CREATE_VALUE_NAMES.ClientIp,
                            client_ip || ""
                        )
                    }
                    errorText={ipv4Error}
                />
            </div>
        </Dialog>
    );
};

export default EditDesktopLicense;
