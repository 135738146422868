import { isNumericIntegerValue } from "../../../../../../../../shared";

export const isSortableValueCorrect = (value: string) =>
    isNumericIntegerValue(value) && Number(value) >= 1 && Number(value) <= 10;

export const isSpecificValueCorrect = (value: string | undefined) => {
    const isUndefined = value === undefined;
    const regexValue = /^(?:\d+,){0,9}\d+$/;

    return isUndefined ? false : regexValue.test(value);
};
