/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import ImportIcon from "@mui/icons-material/FileDownloadOutlined";
import ExportIcon from "@mui/icons-material/FileUploadOutlined";
import TransferIcon from "@mui/icons-material/SwapHorizRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import ContentFrameWithButton from "./components/ContentFrameWithButton";
import { useDialog, useMenu } from "./hooks";

import CreateTask from "../dialogs/Devices/CreateTask";
import AddDevice from "../dialogs/Devices/AddDevice";
import MoveDevices from "../dialogs/Devices/MoveDevices";
import ImportData from "../dialogs/Devices/ImportData";
import TransferDevice from "../dialogs/Devices/TransferDevice";
import AssignCanPackage from "../dialogs/Devices/AssignCanPackage";
import ExportDeviceData from "../dialogs/Devices/ExportDeviceData";
import { DEVICE_ACTIONS } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../DesignComponents/Menu";
import ListChoice from "../../DesignComponents/ListChoice";
import {
    TooltipGeneral,
    TOOLTIP_PLACEMENT
} from "../../DesignComponents/Tooltips";
import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";

import AuthContext from "../../../context/auth/authContext";
import RoutesContext from "../../../context/routes/routesContext";
import TableContext from "../../../context/table/tableContext";
import AddDeviceIcon from "../../../assets/customIcons/actions/AddDeviceIcon";
import MoveIcon from "../../../assets/customIcons/actions/MoveIcon";
import ProductCodesIcon from "../../../assets/customIcons/navigation/ProductCodesIcon";
import { useMediaQueries } from "../../../shared";

const DevicesBar = () => {
    const { isAdminUser, isSupportUser } = useContext(AuthContext);
    const { toggleStatistics } = useContext(RoutesContext);
    const { totalRows, isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm, toMd, fromLg, toLg } = useMediaQueries();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();

    const { isOpenDialog, openDialog, closeDialog } =
        useDialog(handleCloseMenu);

    const renderTableStatisticsButton = () =>
        toMd ? (
            <TooltipGeneral
                disableInteractive
                disableTouchListener
                placement={TOOLTIP_PLACEMENT.Top}
                title={t("Button##table statistics")}
            >
                <Button
                    variant={BUTTON_VARIANTS.IconOnly}
                    size={BUTTON_SIZES.Small}
                    color={BUTTON_COLORS.White}
                    icon={<BarChartRoundedIcon />}
                    onClick={toggleStatistics}
                    disabled={totalRows === 0}
                />
            </TooltipGeneral>
        ) : (
            <Button
                variant={BUTTON_VARIANTS.IconLeft}
                size={BUTTON_SIZES.Small}
                color={BUTTON_COLORS.White}
                icon={<BarChartRoundedIcon />}
                onClick={toggleStatistics}
                disabled={totalRows === 0}
            >
                {t("Button##table statistics")}
            </Button>
        );

    return (
        <Fragment>
            <ContentFrameWithButton
                button={!isSupportUser && renderTableStatisticsButton()}
            >
                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.TextOnly}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.Primary}
                        onClick={() => openDialog(DEVICE_ACTIONS.CreateTask)}
                    >
                        {t("Button##create task")}
                    </Button>
                </ActionToolbarButtonWrapper>

                {fromLg && (
                    <ActionToolbarButtonWrapper>
                        <Button
                            variant={BUTTON_VARIANTS.TextOnly}
                            size={BUTTON_SIZES.Small}
                            color={BUTTON_COLORS.White}
                            onClick={() =>
                                openDialog(DEVICE_ACTIONS.MoveDevices)
                            }
                        >
                            {t("Button##move")}
                        </Button>
                    </ActionToolbarButtonWrapper>
                )}

                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.IconRight}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.White}
                        icon={<ExpandMoreRoundedIcon />}
                        onClick={handleOpenMenu}
                    >
                        {t("Button##more actions")}
                    </Button>
                </ActionToolbarButtonWrapper>
            </ContentFrameWithButton>

            <Menu
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                anchorOrigin={centeredAnchorOrigin}
                transformOrigin={centeredTransformOrigin}
                css={css({
                    "& .MuiMenu-paper": {
                        minWidth: "222px"
                    }
                })}
            >
                {isAdminUser && (
                    <ListChoice
                        title={t("Button##add device")}
                        iconLeft={<AddDeviceIcon />}
                        onClick={() => openDialog(DEVICE_ACTIONS.AddDevice)}
                    />
                )}

                {toLg && (
                    <ListChoice
                        title={t("Button##move")}
                        iconLeft={<MoveIcon />}
                        onClick={() => openDialog(DEVICE_ACTIONS.MoveDevices)}
                    />
                )}

                <ListChoice
                    title={t("Button##import")}
                    iconLeft={<ImportIcon />}
                    onClick={() => openDialog(DEVICE_ACTIONS.ImportDevices)}
                />

                <ListChoice
                    title={t("Button##export")}
                    iconLeft={<ExportIcon />}
                    onClick={() => openDialog(DEVICE_ACTIONS.ExportDeviceData)}
                />

                {isAdminUser && (
                    <ListChoice
                        title={t("General##assign can package")}
                        iconLeft={<ProductCodesIcon />}
                        onClick={() =>
                            openDialog(DEVICE_ACTIONS.AssignCanPackage)
                        }
                    />
                )}

                {!isSupportUser && (
                    <ListChoice
                        title={t("Button##transfer")}
                        iconLeft={<TransferIcon />}
                        onClick={() =>
                            openDialog(DEVICE_ACTIONS.TransferDevice)
                        }
                    />
                )}
            </Menu>

            <CreateTask
                isOpen={isOpenDialog[DEVICE_ACTIONS.CreateTask]}
                close={closeDialog}
            />

            <AddDevice
                isOpen={isOpenDialog[DEVICE_ACTIONS.AddDevice]}
                close={closeDialog}
            />

            <MoveDevices
                isOpen={isOpenDialog[DEVICE_ACTIONS.MoveDevices]}
                close={closeDialog}
            />

            <ImportData
                isOpen={isOpenDialog[DEVICE_ACTIONS.ImportDevices]}
                close={closeDialog}
            />

            <ExportDeviceData
                isOpen={isOpenDialog[DEVICE_ACTIONS.ExportDeviceData]}
                close={closeDialog}
            />

            <AssignCanPackage
                isOpen={isOpenDialog[DEVICE_ACTIONS.AssignCanPackage]}
                close={closeDialog}
            />

            <TransferDevice
                isOpen={isOpenDialog[DEVICE_ACTIONS.TransferDevice]}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default DevicesBar;
