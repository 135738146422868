import { CHANGE_VALUE, CHANGE_EDITABLE } from "./generalActions";
import { Action, GeneralInitialState } from "./types";

const GeneralReducer = (
    state: GeneralInitialState,
    action: Action
): GeneralInitialState => {
    switch (action.type) {
        case CHANGE_EDITABLE: {
            const { name, value, description, group_id, group_name } =
                action.payload;

            return {
                ...state,
                isEditable: {
                    group: false,
                    description: false,
                    [name]: value
                },
                value: {
                    group:
                        group_id !== null && group_name !== null
                            ? { id: group_id, name: group_name }
                            : null,
                    description: description || ""
                }
            };
        }
        case CHANGE_VALUE: {
            const { name, value, isUpdate } = action.payload;

            return {
                ...state,
                isEditable: {
                    ...state.isEditable,
                    [name]: isUpdate ? false : state.isEditable[name]
                },
                value: {
                    ...state.value,
                    [name]: value
                }
            };
        }
        default:
            return state;
    }
};

export default GeneralReducer;
