import { useContext } from "react";

import CompanyHeader from "./CompanyHeader";
import CompanyLinks from "./CompanyLinks";

import CompanyHierarchy from "../../CompanyHierarchy/CompanyHierarchy";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";

const CompanyDetails = () => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const titleCSS = {
        color: textDark,
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px"
    };

    return (
        <>
            <CompanyHeader />

            <CompanyLinks titleCSS={titleCSS} />

            <CompanyHierarchy
                titleCSS={titleCSS}
                companyId={detailsData.id}
                header={"Table##company hierarchy"}
            />
        </>
    );
};

export default CompanyDetails;
