import { useContext } from "react";

import TableContext from "../../../../../../context/table/tableContext";
import {
    ENDPOINTS,
    getSingleTableRowData,
    useApi
} from "../../../../../../shared";

export const useUpdateDesktopLicense = () => {
    const { updateSingleRowData } = useContext(TableContext);

    const { getData, handleResponse } = useApi();

    const updateLicenseDetails = async (licenseId: number) => {
        getSingleTableRowData(
            `${ENDPOINTS.DesktopLicenses}/${licenseId}`,
            updateSingleRowData,
            getData,
            handleResponse
        );
    };

    return { updateLicenseDetails };
};
