import { TFunction } from "i18next";

import { DescriptionTemplateVariable } from "../Notifications/types";

const templateWithReplacedPlaceholders = (
    template: string,
    variables: Record<string, string | number>
) => {
    let result = template;

    for (const key in variables) {
        if (Object.prototype.hasOwnProperty.call(variables, key)) {
            result = result.split(`{{${key}}}`).join(String(variables[key]));
        }
    }

    return result;
};

const renderTranslatedDescription = (
    description: string,
    t: TFunction<"translation", undefined>
) => {
    try {
        const parsedData = JSON.parse(description);

        if (parsedData.template && parsedData.variables) {
            const { template, variables } = parsedData;

            return t(`Notifications##description##${template}`, {
                ...(variables as DescriptionTemplateVariable),
                defaultValue: templateWithReplacedPlaceholders(
                    template,
                    variables
                )
            });
        }

        return description;
    } catch (error) {
        return t(`Notifications##description##${description}`, {
            defaultValue: description
        });
    }
};

export { renderTranslatedDescription };
