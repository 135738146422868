import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import {
    DateValidationError,
    PickerChangeHandlerContext
} from "@mui/x-date-pickers";

import { LEVEL_CHANGES } from "../constants";
import {
    ADDITIONAL_DATA,
    LEVEL_TYPES,
    LOADERS,
    TIME_PERIODS,
    TimePeriodOption
} from "../types";

import { isUserAndRootSameCompany } from "../../shared";

import { COMPANY_ACTIONS, Close } from "../../../../types";

import { useDatePicker } from "../../../../../DatePicker";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import AuthContext from "../../../../../../context/auth/authContext";
import {
    API_IS_BEING_CANCELLED,
    COMPANY_LEVELS,
    ENDPOINTS,
    useApi,
    useEmailValidation,
    useIsMounted
} from "../../../../../../shared";

export const useCompanyLevelManualChange = (isOpen: boolean, close: Close) => {
    const { setAlert } = useContext(AlertContext);

    const { rootCompany, rootCompanyLevel, triggerRootCompany } =
        useContext(ParamsContext);

    const { isSystemUser, user, changeUserCompanyLevel } =
        useContext(AuthContext);

    const { t } = useTranslation();
    const { parseDatePickerValue } = useDatePicker();
    const isMounted = useIsMounted();

    const { getData, postData, handleResponse, isCanceled, cancelSource } =
        useApi();

    const [levelType, setLevelType] = useState(LEVEL_TYPES.Automatic);
    const [level, setLevel] = useState(rootCompanyLevel);
    const [defaultLevel, setDefaultLevel] = useState(rootCompanyLevel);
    const [timePeriod, setTimePeriod] = useState<TimePeriodOption | null>(null);

    const [validUntil, setValidUntil] = useState<string | null>(null);
    const [hasValidUntilError, setHasValidUntilError] = useState(false);

    const [additionalData, setAdditionalData] = useState({
        [ADDITIONAL_DATA.ClientEmail]: "",
        [ADDITIONAL_DATA.SalesEmail]: "",
        [ADDITIONAL_DATA.Explanation]: ""
    });

    const [isLoading, setIsLoading] = useState({
        [LOADERS.LevelType]: true,
        [LOADERS.Submit]: false
    });

    const MAX_EXPIRATION_DAYS = 400;

    const { clientEmail, salesEmail, explanation } = additionalData;

    const {
        isEmailValid: isClientEmailValid,
        emailError: clientEmailError,
        clearEmailError: clearClientEmailError,
        validateEmail: validateClientEmail
    } = useEmailValidation(clientEmail);

    const {
        isEmailValid: isSalesEmailValid,
        emailError: salesEmailError,
        clearEmailError: clearSalesEmailError,
        validateEmail: validateSalesEmail
    } = useEmailValidation(salesEmail);

    useEffect(() => {
        const areDatesEqual = (date1: string, date2: string) => {
            const firstDate = moment(date1);
            const secondDate = moment(date2);

            const startOfDayDate1 = firstDate.startOf("day");
            const startOfDayDate2 = secondDate.startOf("day");

            return startOfDayDate1.isSame(startOfDayDate2);
        };

        const getLevelType = async () => {
            try {
                setIsLoading(prev => ({
                    ...prev,
                    [LOADERS.LevelType]: true
                }));

                const {
                    data: {
                        client_representative_email,
                        default_company_level,
                        description,
                        sales_manager_email,
                        valid_until
                    }
                } = await getData(
                    `${ENDPOINTS.Companies}/${rootCompany?.id}/${LEVEL_CHANGES}`
                );

                if (isMounted) {
                    setLevelType(LEVEL_TYPES.Manual);
                    setDefaultLevel(default_company_level);

                    const january31NextYear = getJanuary31NextYear();

                    const isEqualToJanuary31NextYear = areDatesEqual(
                        valid_until,
                        january31NextYear
                    );

                    setTimePeriod({
                        id: getTimePeriodOptions()[
                            Number(!isEqualToJanuary31NextYear)
                        ].id,
                        value: getTimePeriodOptions()[
                            Number(!isEqualToJanuary31NextYear)
                        ].value
                    });

                    setValidUntil(valid_until);

                    setAdditionalData({
                        [ADDITIONAL_DATA.ClientEmail]:
                            client_representative_email || "",
                        [ADDITIONAL_DATA.SalesEmail]: sales_manager_email || "",
                        [ADDITIONAL_DATA.Explanation]: description || ""
                    });
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    setLevelType(LEVEL_TYPES.Automatic);
                    setDefaultLevel(rootCompanyLevel);
                    setTimePeriod(null);
                    setValidUntil(null);

                    setAdditionalData({
                        [ADDITIONAL_DATA.ClientEmail]: "",
                        [ADDITIONAL_DATA.SalesEmail]: "",
                        [ADDITIONAL_DATA.Explanation]: ""
                    });
                }
            }

            setIsLoading(prev => ({ ...prev, [LOADERS.LevelType]: false }));
        };

        isOpen && getLevelType();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isOpen, rootCompany?.id]);

    useEffect(() => {
        setLevel(rootCompanyLevel);
        setDefaultLevel(rootCompanyLevel);
    }, [rootCompanyLevel]);

    const getJanuary31NextYear = () => {
        const nextYear = moment().add(1, "years").format("YYYY");

        return moment(`${nextYear}-01-31`).format("L");
    };

    const getTimePeriodOptions = () => [
        {
            id: TIME_PERIODS.January31NextYear,
            value: getJanuary31NextYear()
        },
        { id: TIME_PERIODS.Custom, value: t("Dialog##time period custom") }
    ];

    const handleCloseDialog = () =>
        close(COMPANY_ACTIONS.CompanyLevelManualChange);

    const handleSubmit = async () => {
        try {
            setIsLoading(prev => ({ ...prev, [LOADERS.Submit]: true }));

            const payload = {
                company_id: rootCompany?.id,
                company_level: level,
                valid_until: validUntil,
                client_representative_email: clientEmail || null,
                sales_manager_email: salesEmail || null,
                description: explanation || null
            };

            await postData(`${ENDPOINTS.Companies}/${LEVEL_CHANGES}`, payload);

            handleCloseDialog();

            isUserAndRootSameCompany(user?.company_id, rootCompany?.id) &&
                changeUserCompanyLevel(level);

            triggerRootCompany(false);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##Company level successfully changed")
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(prev => ({ ...prev, [LOADERS.Submit]: false }));
    };

    const resetData = (resetLevel: boolean) => {
        setLevelType(LEVEL_TYPES.Automatic);

        if (resetLevel) {
            setLevel(rootCompanyLevel);
            setDefaultLevel(rootCompanyLevel);
        }

        setTimePeriod(null);
        setValidUntil(null);

        setAdditionalData({
            [ADDITIONAL_DATA.ClientEmail]: "",
            [ADDITIONAL_DATA.SalesEmail]: "",
            [ADDITIONAL_DATA.Explanation]: ""
        });

        clearClientEmailError();
        clearSalesEmailError();
    };

    const getLevelOptions = () => {
        const allLevels = Object.values(COMPANY_LEVELS);

        return allLevels.filter(level => level !== COMPANY_LEVELS.Internal);
    };

    const isLevelOptionDisabled = (level: COMPANY_LEVELS) => {
        if (isSystemUser) {
            return false;
        }

        switch (level) {
            case COMPANY_LEVELS.Basic:
                return (
                    rootCompanyLevel === COMPANY_LEVELS.Standard ||
                    rootCompanyLevel === COMPANY_LEVELS.Premium
                );
            case COMPANY_LEVELS.Standard:
                return rootCompanyLevel === COMPANY_LEVELS.Premium;
            case COMPANY_LEVELS.Premium:
                return false;
            default:
                return true;
        }
    };

    const handleEndDateChange = (
        dateValue: Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>
    ) => {
        const newValue = parseDatePickerValue(dateValue, context, validUntil);

        setValidUntil(newValue);
    };

    const handleEndDateError = (error: string | null) =>
        setHasValidUntilError(error !== null);

    const handleChangeAdditionalData = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name as ADDITIONAL_DATA;

        name === ADDITIONAL_DATA.ClientEmail && clearClientEmailError();
        name === ADDITIONAL_DATA.SalesEmail && clearSalesEmailError();

        setAdditionalData({
            ...additionalData,
            [name]:
                name === ADDITIONAL_DATA.Explanation
                    ? e.target.value
                    : e.target.value.trim()
        });
    };

    const isCustomTimePeriod = timePeriod?.id === TIME_PERIODS.Custom;

    return {
        isLoading,
        level,
        defaultLevel,
        validUntil,
        hasValidUntilError,
        levelType,
        rootCompany,
        rootCompanyLevel,
        timePeriod,
        isCustomTimePeriod,
        clientEmail,
        salesEmail,
        explanation,
        isClientEmailValid,
        isSalesEmailValid,
        clientEmailError,
        salesEmailError,
        MAX_EXPIRATION_DAYS,
        validateClientEmail,
        validateSalesEmail,
        handleCloseDialog,
        handleSubmit,
        resetData,
        getLevelOptions,
        isLevelOptionDisabled,
        setLevel,
        getTimePeriodOptions,
        setTimePeriod,
        setValidUntil,
        handleEndDateChange,
        handleEndDateError,
        handleChangeAdditionalData
    };
};
