/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { MainItemDetails } from "./types";

import DialogWithConfirmation from "../../components/DialogWithConfirmation";
import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import Tag, { TAG_COLORS, TAG_SIZES } from "../../../../DesignComponents/Tag";

import AlertContext from "../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import TableContext from "../../../../../context/table/tableContext";
import {
    ENDPOINTS,
    getSingleTableRowData,
    maxDialogWidth,
    TABLE_NAMES,
    TRI_SOURCES,
    useApi,
    useFormattedNumber,
    useMediaQueries
} from "../../../../../shared";

const CancelSelectedTasks = ({ isOpen, close }: DialogProps) => {
    const { setBackgroundActionId } = useContext(AlertContext);
    const { setReloadItems } = useContext(ParamsContext);

    const { outerDetailsData, selectedRows, deselectAllRows } =
        useContext(TableContext);

    const { bulkCancel, getData, handleResponse, updateData } = useApi();
    const { renderFormattedNumber } = useFormattedNumber();
    const { fromMd, toMd } = useMediaQueries();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);

    const resourceCount = selectedRows?.length;
    const isSingle = resourceCount === 1;

    const getPayload = (singleSelected: boolean) => {
        return singleSelected
            ? { status_id: 4 }
            : {
                  source: TRI_SOURCES.Selected,
                  [TRI_SOURCES.Selected]: selectedRows
              };
    };

    const handleCloseCancelTasksDialog = () =>
        close(DEVICE_ACTIONS.CancelTasks);

    const deselectAndClose = () => {
        deselectAllRows();
        handleCloseCancelTasksDialog();
    };

    const submitCancelTask = async () => {
        const { endpointForSingleUpdate, updateMainRowData } =
            outerDetailsData as MainItemDetails;

        try {
            setLoading(true);

            const response = await (isSingle
                ? updateData(
                      ENDPOINTS.Tasks,
                      selectedRows[0],
                      getPayload(isSingle)
                  )
                : bulkCancel(ENDPOINTS.Tasks, getPayload(isSingle)));

            if (isSingle) {
                deselectAndClose();
                setReloadItems(TABLE_NAMES.Tasks);

                getSingleTableRowData(
                    endpointForSingleUpdate,
                    updateMainRowData,
                    getData,
                    handleResponse
                );

                handleResponse(response);
            } else {
                setBackgroundActionId(
                    response.data,
                    () => {
                        deselectAndClose();
                        setReloadItems(TABLE_NAMES.Tasks);

                        getSingleTableRowData(
                            endpointForSingleUpdate,
                            updateMainRowData,
                            getData,
                            handleResponse
                        );
                    },
                    () => setLoading(false),
                    () => deselectAndClose()
                );
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    const description = t(
        "Dialog##confirmations##cancel task confirmation##first part"
    );

    const confirmationDescription = `
        ${description} 
        ${t("Dialog##confirmations##cancel task confirmation##second part")}
    `;

    const isBulkActionLoading = !isSingle && isLoading;

    return (
        <DialogWithConfirmation
            data-testid="cancel-tasks-for-selection-dialog"
            title={t("Dialog##cancel tasks")}
            description={t(
                "Dialog##confirmations##cancel task confirmation##first part"
            )}
            isActionsSeparator
            isTitleSeparator
            open={isOpen}
            count={resourceCount}
            source={TRI_SOURCES.Selected}
            confirmationDescription={confirmationDescription}
            submit={submitCancelTask}
            close={handleCloseCancelTasksDialog}
            isBulkActionLoading={isBulkActionLoading}
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
            actions={
                <>
                    <DialogActions
                        onClose={handleCloseCancelTasksDialog}
                        isLoading={isLoading}
                        loaderText={
                            !isSingle ? t("Button##cancelling") : undefined
                        }
                        isCancelDisabled={isBulkActionLoading}
                        isReadyToConfirm
                        confirmationButtonText={t("Button##confirm")}
                        cancelButtonTestId="cancel-cancel-tasks"
                        confirmationButtonTestId="confirm-cancel-tasks"
                    />
                </>
            }
            TransitionProps={{
                onExited: () => setLoading(false)
            }}
        >
            <Tag
                data-testid="cancel-tasks-number"
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                title={`${t("Dialog##selected tasks")}: ${renderFormattedNumber(
                    resourceCount
                )}`}
            />
        </DialogWithConfirmation>
    );
};
export default CancelSelectedTasks;
