import { useTranslation } from "react-i18next";

import PlatformInfo from "./PlatformInfo";

import { InfoTooltip } from "../InfoTooltip";
import { HeadTitleInfoProps } from "../types";

import {
    COLUMN_NAMES,
    COMPANY_LEVEL_SETTINGS,
    useCompanyLevelSettings
} from "../../../../shared";

const HeadTitleInfo = ({
    restriction,
    columnWithAdditionalInfo,
    ...props
}: HeadTitleInfoProps) => {
    const { t } = useTranslation();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const isRestricted = isFeatureSettingsRestricted(restriction);

    const getAdditionalInfo = (columnName: COLUMN_NAMES) => {
        if (columnName === COLUMN_NAMES.ModelPlatform) {
            return <PlatformInfo />;
        }

        return "";
    };

    const getTitle = () => {
        if (isRestricted) {
            return t(
                `Restrictions##${COMPANY_LEVEL_SETTINGS.FeatureSettings}##${restriction}##table head`
            );
        }

        if (columnWithAdditionalInfo) {
            return getAdditionalInfo(columnWithAdditionalInfo);
        }

        return "";
    };

    return getTitle() ? <InfoTooltip {...props} title={getTitle()} /> : null;
};

export { HeadTitleInfo };
