import axios, { AxiosRequestConfig } from "axios";

import useResponse from "./useResponse";

import { useDecoder } from "../useDecoder";

import { ENDPOINTS } from "../../types";

export const useApi = () => {
    const { showResponse, getCriticalMessage } = useResponse();
    const { decodeStrings } = useDecoder();

    const CancelToken = axios.CancelToken;
    const cancelSource = CancelToken.source();
    const isCanceled = axios.isCancel;

    const api = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        cancelToken: cancelSource.token,
        withCredentials: true,
        xsrfHeaderName: "X-XSRF-TOKEN",
        xsrfCookieName: "XSRF-TOKEN"
    });

    const getData = (resource: string, config?: AxiosRequestConfig<any>) =>
        api.get(resource, config);

    const postData = (resource: string, payload?: any) =>
        api.post(resource, payload);

    const bulkPostData = (resource: string, payload: any) =>
        api.post(`${resource}/bulkCreate`, payload);

    const updateData = (resource: string, id: number | string, payload: any) =>
        api.patch(`${resource}/${id}`, payload);

    const bulkUpdateData = (resource: string, payload: any) =>
        api.post(`${resource}/bulkUpdate`, payload);

    const bulkCancel = (resource: string, payload: any) =>
        api.post(`${resource}/bulkCancel`, payload);

    const deleteData = (
        resource: string,
        id: number | string,
        query?: string
    ) => {
        const queryString = query ? `/${query}` : "";

        return api.delete(`${resource}/${id}${queryString}`);
    };

    const bulkDeleteData = (resource: string, payload: any) =>
        api.post(`${resource}/bulkDelete`, payload);

    const logout = () => api.post(ENDPOINTS.Logout);

    const queryData = (
        resource: string,
        stringParams: string,
        hasDefaultSort: boolean = false
    ) => {
        const stringParamsExist = stringParams.length > 0;
        const ampersand = stringParamsExist ? "&" : "";
        const ampersandOrQuestion = resource.includes("?") ? "&" : "?";
        const queryExists = stringParamsExist || hasDefaultSort;
        const sortById = hasDefaultSort ? `${ampersand}order=desc&sort=id` : "";

        const stringParamsWithQuotes = decodeStrings(stringParams);

        return api.get(
            `${resource}${
                queryExists ? ampersandOrQuestion : ""
            }${stringParamsWithQuotes}${sortById}`
        );
    };

    const queryStats = (resource: string, stringParams: string) =>
        api.get(
            `${resource}${stringParams.length > 0 ? "&" : ""}${stringParams}`
        );

    const handleResponse = (res: any) => showResponse(res);

    return {
        cancelSource,
        bulkCancel,
        isCanceled,
        getData,
        postData,
        bulkPostData,
        updateData,
        bulkUpdateData,
        deleteData,
        bulkDeleteData,
        logout,
        queryData,
        queryStats,
        handleResponse,
        getCriticalMessage
    };
};
