import { useContext } from "react";
import prettyBytes from "pretty-bytes";

import LanguageContext from "../../context/language/languageContext";

const useFileSize = () => {
    const { language } = useContext(LanguageContext);

    const renderFileSize = (value: string | number) =>
        prettyBytes(Number(value), {
            maximumFractionDigits: 2,
            locale: language
        });

    return { renderFileSize };
};

export { useFileSize };
