import { useState } from "react";

import CompanyAndGroupDropdowns from "../components/CompanyAndGroupDropdowns";
import DeviceRadioGroup from "../components/DeviceRadioGroup";
import { useTriSourceAction } from "../hooks";
import { CompanyGroupPayloadProps } from "../types";

import TriSource from "../../components/TriSource/TriSource";
import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { FILE_ID_TYPE, TABLE_NAMES, TRI_SOURCES } from "../../../../../shared";

const MoveDevices = ({ isOpen, close }: DialogProps) => {
    const {
        t,
        rootCompany,
        isSupportUser,
        activeSource,
        sourcePayload,
        isSourceReady,
        isSelectedSingleItem,
        updateDevices,
        setReloadItems
    } = useTriSourceAction();

    const [isLoading, setLoading] = useState(false);
    const [deviceIdType, setDeviceIdType] = useState(FILE_ID_TYPE.Imei);

    const [payloadInfo, setPayloadInfo] =
        useState<CompanyGroupPayloadProps | null>(null);

    const isReady =
        isSourceReady &&
        sourcePayload !== null &&
        payloadInfo !== null &&
        payloadInfo.company !== null;

    const handleCloseDialog = () => close(DEVICE_ACTIONS.MoveDevices);

    const handlePayload = (data: CompanyGroupPayloadProps | null) =>
        setPayloadInfo(data);

    const getFormData = (company: number, group: number | null) => {
        if (isReady) {
            const formData = new FormData();

            formData.append("source", activeSource);
            formData.append("id_type", deviceIdType);
            formData.append("file", sourcePayload as Blob);

            isSupportUser &&
                formData.append("root_id", String(rootCompany?.id as number));

            formData.append("data[company_id]", String(company));
            formData.append("data[group_id]", String(group));

            return formData;
        }

        return null;
    };

    const getPayloadData = (company: number, group: number | null) => {
        if (isReady) {
            const data = {
                company_id: company,
                group_id: group
            };

            const payload = {
                source: activeSource,
                [activeSource]: sourcePayload,
                data
            };

            return isSelectedSingleItem ? data : payload;
        }
    };

    const submitMoveDevices = () => {
        if (isReady) {
            const { company, group } = payloadInfo;

            const payloadData =
                activeSource === TRI_SOURCES.FromFile
                    ? getFormData(company!, group)
                    : getPayloadData(company!, group);

            updateDevices(payloadData, setLoading, handleCloseDialog, () =>
                setReloadItems(TABLE_NAMES.Devices)
            );
        }
    };

    const handleDeviceIdChange = (e: { target: { value: FILE_ID_TYPE } }) => {
        const { value } = e.target;
        setDeviceIdType(value);
    };

    const isBulkActionLoading = !isSelectedSingleItem && isLoading;

    return (
        <TriSource
            title={t("Dialog##move devices")}
            description={t("Dialog##move device description")}
            confirmationDescription={t(
                "Dialog##confirmations##move devices confirmation"
            )}
            close={handleCloseDialog}
            isBulkActionLoading={isBulkActionLoading}
            isLoading={isLoading}
            open={isOpen}
            submit={submitMoveDevices}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    loaderText={
                        !isSelectedSingleItem ? t("Button##moving") : undefined
                    }
                    isCancelDisabled={isBulkActionLoading}
                    isReadyToConfirm={isReady}
                    confirmationButtonText={t("Button##move")}
                    cancelButtonTestId="cancel-move-devices"
                    confirmationButtonTestId="move-devices"
                />
            }
            TransitionProps={{
                onExited: () => {
                    setLoading(false);
                    setPayloadInfo(null);
                }
            }}
            deviceIdentifier={
                <DeviceRadioGroup
                    target={deviceIdType}
                    firstRadioLabel={t("Dialog##imei")}
                    firstRadioValue={FILE_ID_TYPE.Imei}
                    secondRadioLabel={t("Dialog##sn")}
                    secondRadioValue={FILE_ID_TYPE.SerialId}
                    isLoading={isLoading}
                    onChange={handleDeviceIdChange}
                />
            }
        >
            <CompanyAndGroupDropdowns
                isLoading={isLoading}
                isCompanyRequired
                hasClearHistory
                setPayload={handlePayload}
            />
        </TriSource>
    );
};

export default MoveDevices;
