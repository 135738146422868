export enum BACKGROUND_ACTION_STATUS {
    Completed = "Completed",
    Executing = "Executing",
    Failed = "Failed",
    Pending = "Pending"
}

export enum BACKGROUND_ACTIONS {
    CompanyBulkDelete = "CompanyBulkDelete",
    CompanyMerge = "CompanyMerge",
    DeviceBulkAttributeUpdate = "DeviceBulkAttributeUpdate",
    DeviceBulkUpdate = "DeviceBulkUpdate",
    DeviceExport = "DeviceExport",
    DeviceTransfer = "DeviceTransfer",
    FailedTasksRetry = "FailedTasksRetry",
    FileBulkDelete = "FileBulkDelete",
    GroupBulkDelete = "GroupBulkDelete",
    ProductCodeBulkDelete = "ProductCodeBulkDelete",
    TaskBulkCancel = "TaskBulkCancel",
    TaskBulkCreate = "TaskBulkCreate",
    TaskBulkUpdate = "TaskBulkUpdate",
    UserBulkDelete = "UserBulkDelete"
}
