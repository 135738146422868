import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { renderAvailableData } from "../functions";
import { DetailsData } from "../types";

import { CARD_ITEMS, CARDS } from "../../types";

import CopyButton from "../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../DesignComponents/Button";

import TableContext from "../../../../../context/table/tableContext";

const Carrier = () => {
    const { detailsData } = useContext(TableContext);

    const {
        carrier: {
            unit_status,
            unit_type,
            module_id,
            serial_id,
            version,
            filename
        }
    }: DetailsData = detailsData;

    const { t } = useTranslation();

    const list = [
        {
            title: CARD_ITEMS.CarrierUnitStatus,
            value: unit_status,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CarrierUnitType,
            value: unit_type,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CarrierModuleId,
            value: module_id,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CarrierSerialId,
            value: serial_id,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CarrierRevision,
            value: version,
            isAvailable: true
        },
        {
            title: CARD_ITEMS.CarrierSettingsFilename,
            value: filename,
            isAvailable: true
        }
    ];

    return (
        <div>
            <div>{t(`Dialog##${CARDS.Carrier}`)}</div>

            <div>
                {renderAvailableData(list).map(({ title, value }) => {
                    return (
                        <Fragment key={title}>
                            <span>{t(`Table##${title}`)}:</span>

                            <span>
                                {value}

                                <CopyButton
                                    size={BUTTON_SIZES.Tiny}
                                    copiedValue={String(value)}
                                />
                            </span>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export { Carrier };
