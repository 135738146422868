import { useState } from "react";

import { TokenData, TransferDevicesValidationProps } from "../types";

import { ENDPOINTS, useApi } from "../../../../../shared";

const useTokenValidation = ({
    token,
    removeResize
}: TransferDevicesValidationProps) => {
    const { getData } = useApi();

    const [tokenError, setTokenError] = useState("");
    const [isTokenLoading, setTokenLoading] = useState(false);

    const [validationData, setValidationData] = useState<TokenData | null>(
        null
    );

    const clearTokenError = () => setTokenError("");

    const validateToken = async () => {
        if (token) {
            try {
                setTokenLoading(true);

                const { data } = await getData(`${ENDPOINTS.Tokens}/${token}`);

                setTokenError("");
                setValidationData(data);
                removeResize(false);
            } catch (error) {
                const errorMessage = (error as any).response.data.message;

                setTokenError(errorMessage);
                setValidationData(null);
            }
        }

        setTokenLoading(false);
    };

    const clearValidationData = () => {
        setValidationData(null);
        removeResize(true);
    };

    return {
        tokenError,
        isTokenLoading,
        validationData,
        validateToken,
        clearTokenError,
        clearValidationData
    };
};

export { useTokenValidation };
