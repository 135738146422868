import { useState } from "react";

import CreateTaskContent from "./CreateTaskContent";
import { SecondSelectData } from "./CreateTaskContent/SecondSelectContent/types";
import { CompatibilityDataValue, PayloadInfo, ScheduleData } from "./types";

import { useTriSourceAction } from "../hooks";

import TriSource from "../../components/TriSource/TriSource";
import DialogActions from "../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { ALERT_STATUS } from "../../../../../context/alert/alertContext";

import {
    COMPANY_LEVEL_SETTINGS,
    ENDPOINTS,
    RESOURCE_LIMIT_SETTINGS,
    TABLE_NAMES,
    TASK_TYPES,
    TRI_SOURCES,
    useCompanyLevelSettings,
    useFormattedNumber
} from "../../../../../shared";

const CreateTask = ({ isOpen, close }: DialogProps) => {
    const {
        t,
        rootCompany,
        isSupportUser,
        activeSource,
        sourcePayload,
        isSourceReady,
        isSelectedSingleItem,
        setAlert,
        setBackgroundActionId,
        deselectAllRows,
        postData,
        bulkPostData,
        handleResponse,
        setReloadItems
    } = useTriSourceAction();

    const { getResourceLimit } = useCompanyLevelSettings();
    const { renderFormattedNumber } = useFormattedNumber();

    const [isLimitNotReached, setLimitNotReached] = useState<boolean | null>(
        null
    );

    const [isFinalStep, setFinalStep] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [compatibilityData, setCompatibilityData] =
        useState<CompatibilityDataValue>(null);

    const [payloadInfo, setPayloadInfo] = useState<PayloadInfo | null>(null);

    const [hasCreateForIncompatible, setHasCreateForIncompatible] =
        useState(false);

    const areAllDevicesIncompatible = hasCreateForIncompatible
        ? false
        : compatibilityData?.compatible_platform_device_count === 0;

    const isUnsupportedFirmware = Boolean(
        payloadInfo?.secondSelectData?.isUnsupportedFile
    );

    const isReady =
        isSourceReady &&
        isLimitNotReached &&
        sourcePayload &&
        payloadInfo !== null &&
        !areAllDevicesIncompatible &&
        !isUnsupportedFirmware;

    const resourceLimit = getResourceLimit(
        RESOURCE_LIMIT_SETTINGS.TasksPerGroup
    );

    const checkIfLimitIsNotReached = (count: number) => {
        const isNotReached = resourceLimit === -1 || count <= resourceLimit;

        setLimitNotReached(isNotReached);
    };

    const handleCloseDialog = () => close(DEVICE_ACTIONS.CreateTask);

    const handlePayload = (data: PayloadInfo | null) => setPayloadInfo(data);

    const changeFinalStep = (isFinalStepValue: boolean) =>
        setFinalStep(isFinalStepValue);

    const handleCreateForIncompatible = (value: boolean) =>
        setHasCreateForIncompatible(value);

    const getFormData = (
        type: TASK_TYPES,
        secondSelectData: SecondSelectData | null,
        schedule: ScheduleData | null,
        expire_existing_tasks: boolean
    ) => {
        const formData = new FormData();

        const getSchedulePayload = () => {
            if (schedule === null) {
                formData.append("data[schedule]", "null");
            } else {
                formData.append("data[schedule][type]", schedule.type);

                formData.append(
                    "data[schedule][attributes][from_minutes]",
                    String(schedule.attributes.from_minutes)
                );

                formData.append(
                    "data[schedule][attributes][duration_minutes]",
                    String(schedule.attributes.duration_minutes)
                );
            }
        };

        formData.append("source", activeSource);
        formData.append("file", sourcePayload as Blob);

        isSupportUser &&
            formData.append("root_id", String(rootCompany?.id as number));

        formData.append("data[type]", type);

        secondSelectData &&
            formData.append(
                `data[${secondSelectData.id}]`,
                secondSelectData.value as Blob
            );

        formData.append(
            "data[expire_existing_tasks]",
            String(expire_existing_tasks)
        );

        formData.append(
            "data[create_incompatible_platform_task]",
            String(hasCreateForIncompatible)
        );

        getSchedulePayload();

        return formData;
    };

    const getPayloadData = (
        type: TASK_TYPES,
        secondSelectData: SecondSelectData | null,
        schedule: ScheduleData | null,
        expire_existing_tasks: boolean
    ) => {
        const data = {
            type,
            schedule,
            expire_existing_tasks,
            create_incompatible_platform_task: hasCreateForIncompatible,
            ...(secondSelectData
                ? { [secondSelectData.id]: secondSelectData.value }
                : {}),
            ...(isSelectedSingleItem
                ? { device_imei: (sourcePayload as number[])[0] }
                : {})
        };

        const payload = {
            source: activeSource,
            [activeSource]: sourcePayload,
            data
        };

        if (isFinalStep) {
            return isSelectedSingleItem ? data : payload;
        }

        return payload;
    };

    const getPayload = () => {
        if (isReady) {
            const { type, secondSelectData, schedule, expire_existing_tasks } =
                payloadInfo;

            return activeSource === TRI_SOURCES.FromFile
                ? getFormData(
                      type,
                      secondSelectData,
                      schedule,
                      expire_existing_tasks
                  )
                : getPayloadData(
                      type,
                      secondSelectData,
                      schedule,
                      expire_existing_tasks
                  );
        }

        return null;
    };

    const deselectAndClose = () => {
        deselectAllRows();
        handleCloseDialog();
    };

    const submitCreateTask = async () => {
        try {
            setLoading(true);

            const payloadData = getPayload();

            const { data } = await (isSelectedSingleItem
                ? postData(ENDPOINTS.Tasks, payloadData)
                : bulkPostData(ENDPOINTS.Tasks, payloadData));

            if (isSelectedSingleItem) {
                deselectAndClose();
                setReloadItems(TABLE_NAMES.Devices);

                setAlert({
                    status: ALERT_STATUS.Success,
                    title: t("Flash##task was created", {
                        imei: (payloadData as any).device_imei,
                        taskType: t(`General##${(payloadData as any).type}`)
                    })
                });
            } else {
                setBackgroundActionId(
                    data,
                    () => {
                        deselectAndClose();
                        setReloadItems(TABLE_NAMES.Devices);
                    },
                    () => setLoading(false),
                    () => deselectAndClose()
                );
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    const checkCompatibility = async () => {
        try {
            setLoading(true);

            const payloadData = getPayload();

            const { data } = await postData(
                `${ENDPOINTS.Tasks}/bulkCreate/compatibilityCheck`,
                payloadData
            );

            setCompatibilityData(data);

            changeFinalStep(true);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const handleSubmit = () => {
        if (isFinalStep) {
            submitCreateTask();
        } else {
            checkCompatibility();
        }
    };

    const isBulkActionLoading = !isSelectedSingleItem && isLoading;

    const description = isFinalStep
        ? t("Dialog##create task description last step")
        : t("Dialog##create task description first step");

    const confirmationButtonText = t(
        `Button##${isFinalStep ? "create" : "next"}`
    );

    const secondaryButtonText = t(`Button##${isFinalStep ? "back" : "cancel"}`);

    const onSecondaryBtnClick = () => {
        if (isFinalStep) {
            changeFinalStep(false);
            setCompatibilityData(null);
        } else {
            handleCloseDialog();
        }
    };

    const getSubmitBtnTooltipText = () => {
        if (isLimitNotReached === false) {
            return t(
                `Restrictions##${COMPANY_LEVEL_SETTINGS.ResourceLimitSettings}##${RESOURCE_LIMIT_SETTINGS.TasksPerGroup}`,
                {
                    limitCount: renderFormattedNumber(resourceLimit)
                }
            );
        }

        if (areAllDevicesIncompatible) {
            return t("Dialog##platform compatibility##task cannot be created");
        }

        return "";
    };

    return (
        <TriSource
            title={t("Button##create task")}
            description={description}
            confirmationDescription={t(
                "Dialog##confirmations##create task confirmation"
            )}
            setCount={checkIfLimitIsNotReached}
            modalName={DEVICE_ACTIONS.CreateTask}
            close={handleCloseDialog}
            isBulkActionLoading={isBulkActionLoading}
            isLoading={isLoading}
            open={isOpen}
            submit={handleSubmit}
            hideTriSource={isFinalStep}
            hasIgnoreConfirmation={isFinalStep}
            actions={
                <DialogActions
                    onClose={onSecondaryBtnClick}
                    isLoading={isLoading}
                    loaderText={
                        !isFinalStep || isSelectedSingleItem
                            ? undefined
                            : t("Button##creating")
                    }
                    isCancelDisabled={isBulkActionLoading}
                    isReadyToConfirm={isReady}
                    submitBtnTooltipText={getSubmitBtnTooltipText()}
                    confirmationButtonText={confirmationButtonText}
                    secondaryButtonText={secondaryButtonText}
                    cancelButtonTestId="cancel-create-task-button"
                    confirmationButtonTestId="confirm-create-task-button"
                />
            }
            TransitionProps={{
                onExited: () => {
                    setFinalStep(false);
                    setLoading(false);
                    setCompatibilityData(null);
                    setPayloadInfo(null);
                    setHasCreateForIncompatible(false);
                }
            }}
        >
            <CreateTaskContent
                isLoading={isLoading}
                isFinalStep={isFinalStep}
                activeSource={activeSource}
                compatibilityData={compatibilityData}
                hasCreateForIncompatible={hasCreateForIncompatible}
                setPayload={handlePayload}
                handleCreateForIncompatible={handleCreateForIncompatible}
            />
        </TriSource>
    );
};

export default CreateTask;
