import {
    SET_ALERT,
    REMOVE_ALERT,
    SET_BACKGROUND_ACTION_ID
} from "./alertActions";
import { Action, AlertInitialState } from "./types";

const AlertReducer = (state: AlertInitialState, action: Action) => {
    switch (action.type) {
        case SET_ALERT: {
            const {
                status,
                title,
                description,
                primaryBtn,
                secondaryBtn,
                hasCloseBtn,
                hasAutoHide
            } = action.payload;

            return {
                ...state,
                status,
                title,
                description,
                primaryBtn,
                secondaryBtn,
                hasCloseBtn,
                hasAutoHide
            };
        }
        case REMOVE_ALERT: {
            return {
                ...state,
                status: null,
                title: null,
                description: null,
                primaryBtn: null,
                secondaryBtn: null,
                hasCloseBtn: true,
                hasAutoHide: true,
                backgroundActionId: null,
                backgroundActionSuccessCallback: null,
                backgroundActionFailureCallback: null,
                backgroundActionNotifyMeLaterCallback: null
            };
        }
        case SET_BACKGROUND_ACTION_ID: {
            const {
                data: { background_action_id },
                successCallbackFn,
                failureCallbackFn,
                notifyMeLaterCallbackFn
            } = action.payload;

            return {
                ...state,
                backgroundActionId: background_action_id ?? null,
                backgroundActionSuccessCallback: successCallbackFn,
                backgroundActionFailureCallback: failureCallbackFn,
                backgroundActionNotifyMeLaterCallback: notifyMeLaterCallbackFn
            };
        }
        default:
            return state;
    }
};

export default AlertReducer;
