import {
    RESOURCE_LIMIT_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../shared";

const useConstants = () => {
    const { getResourceLimit } = useCompanyLevelSettings();

    const defaultValues = {
        [RESOURCE_LIMIT_SETTINGS.Companies]: getResourceLimit(
            RESOURCE_LIMIT_SETTINGS.Companies,
            true
        ),
        [RESOURCE_LIMIT_SETTINGS.Users]: getResourceLimit(
            RESOURCE_LIMIT_SETTINGS.Users,
            true
        ),
        [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: getResourceLimit(
            RESOURCE_LIMIT_SETTINGS.TasksPerGroup,
            true
        ),
        [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: getResourceLimit(
            RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice,
            true
        )
    };

    const maxValues = {
        [RESOURCE_LIMIT_SETTINGS.Companies]: 1000,
        [RESOURCE_LIMIT_SETTINGS.Users]: 1000,
        [RESOURCE_LIMIT_SETTINGS.TasksPerGroup]: 100000,
        [RESOURCE_LIMIT_SETTINGS.ConfigurationFilesPerDevice]: 10
    };

    return {
        defaultValues,
        maxValues
    };
};

export default useConstants;
