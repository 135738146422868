const UNSUPPORTED_FIRMWARE_LIST = [
    { fwVersion: "3.30.1.0", specId: null },
    { fwVersion: "3.30.1.0", specId: 1 },
    { fwVersion: "3.30.1.1", specId: null },
    { fwVersion: "3.30.1.1", specId: 1 },
    { fwVersion: "3.30.1.2", specId: null },
    { fwVersion: "3.30.1.2", specId: 1 },
    { fwVersion: "3.30.1.3", specId: null },
    { fwVersion: "3.30.1.3", specId: 1 },
    { fwVersion: "3.30.1.4", specId: null },
    { fwVersion: "3.30.1.4", specId: 1 },
    { fwVersion: "3.30.1.5", specId: null },
    { fwVersion: "3.30.1.5", specId: 1 },
    { fwVersion: "3.30.1.50", specId: null },
    { fwVersion: "3.30.1.50", specId: 1 },
    { fwVersion: "3.30.1.60", specId: null },
    { fwVersion: "3.30.1.60", specId: 1 },
    { fwVersion: "3.30.1.70", specId: 227 },
    { fwVersion: "3.30.1.71", specId: 227 },
    { fwVersion: "3.30.1.72", specId: 227 },
    { fwVersion: "3.30.1.73", specId: 227 },
    { fwVersion: "3.30.1.74", specId: 227 },
    { fwVersion: "3.30.1.75", specId: 227 },
    { fwVersion: "3.30.1.90", specId: null },
    { fwVersion: "3.30.1.90", specId: 1 },
    { fwVersion: "3.30.1.100", specId: null },
    { fwVersion: "3.30.1.100", specId: 1 },
    { fwVersion: "3.30.1.101", specId: null },
    { fwVersion: "3.30.1.101", specId: 1 },
    { fwVersion: "3.30.1.102", specId: null },
    { fwVersion: "3.30.1.102", specId: 1 },
    { fwVersion: "3.30.1.110", specId: null },
    { fwVersion: "3.30.1.110", specId: 1 },
    { fwVersion: "4.0.0.0", specId: null },
    { fwVersion: "4.0.0.0", specId: 1 },
    { fwVersion: "4.0.0.1", specId: null },
    { fwVersion: "4.0.0.1", specId: 1 },
    { fwVersion: "4.0.0.2", specId: null },
    { fwVersion: "4.0.0.2", specId: 1 },
    { fwVersion: "4.0.0.3", specId: null },
    { fwVersion: "4.0.0.3", specId: 1 },
    { fwVersion: "4.0.0.4", specId: null },
    { fwVersion: "4.0.0.4", specId: 1 },
    { fwVersion: "4.0.0.5", specId: null },
    { fwVersion: "4.0.0.5", specId: 1 },
    { fwVersion: "4.0.0.6", specId: null },
    { fwVersion: "4.0.0.6", specId: 1 },
    { fwVersion: "4.0.0.7", specId: null },
    { fwVersion: "4.0.0.7", specId: 1 },
    { fwVersion: "4.0.0.8", specId: null },
    { fwVersion: "4.0.0.8", specId: 1 },
    { fwVersion: "4.0.0.8", specId: -2147483546 },
    { fwVersion: "4.0.0.50", specId: null },
    { fwVersion: "4.0.0.50", specId: 1 },
    { fwVersion: "4.0.0.51", specId: null },
    { fwVersion: "4.0.0.51", specId: 1 },
    { fwVersion: "4.0.0.60", specId: null },
    { fwVersion: "4.0.0.60", specId: 1 },
    { fwVersion: "4.0.0.61", specId: null },
    { fwVersion: "4.0.0.61", specId: 1 },
    { fwVersion: "4.0.0.70", specId: null },
    { fwVersion: "4.0.0.70", specId: 1 },
    { fwVersion: "4.0.0.71", specId: null },
    { fwVersion: "4.0.0.71", specId: 1 },
    { fwVersion: "4.0.0.80", specId: null },
    { fwVersion: "4.0.0.80", specId: 1 },
    { fwVersion: "4.0.0.81", specId: null },
    { fwVersion: "4.0.0.81", specId: 1 },
    { fwVersion: "4.0.0.82", specId: null },
    { fwVersion: "4.0.0.82", specId: 1 },
    { fwVersion: "4.0.0.83", specId: null },
    { fwVersion: "4.0.0.83", specId: 1 },
    { fwVersion: "4.0.0.84", specId: null },
    { fwVersion: "4.0.0.84", specId: 1 },
    { fwVersion: "4.0.0.90", specId: 229 },
    { fwVersion: "4.0.0.100", specId: null },
    { fwVersion: "4.0.0.100", specId: 1 },
    { fwVersion: "4.0.0.110", specId: null },
    { fwVersion: "4.0.0.110", specId: 1 },
    { fwVersion: "4.0.0.120", specId: null },
    { fwVersion: "4.0.0.120", specId: 1 },
    { fwVersion: "4.0.0.121", specId: null },
    { fwVersion: "4.0.0.121", specId: 1 },
    { fwVersion: "4.0.0.130", specId: null },
    { fwVersion: "4.0.0.130", specId: 1 },
    { fwVersion: "4.0.0.140", specId: 213 },
    { fwVersion: "4.0.0.150", specId: null },
    { fwVersion: "4.0.0.150", specId: 1 },
    { fwVersion: "4.0.0.160", specId: 138 },
    { fwVersion: "4.0.0.161", specId: 138 },
    { fwVersion: "4.0.0.162", specId: 138 },
    { fwVersion: "4.0.0.170", specId: null },
    { fwVersion: "4.0.0.170", specId: 1 },
    { fwVersion: "4.0.0.180", specId: 223 },
    { fwVersion: "4.0.0.190", specId: 216 },
    { fwVersion: "4.0.0.200", specId: 166 },
    { fwVersion: "4.0.0.210", specId: 142 },
    { fwVersion: "4.0.0.220", specId: 146 },
    { fwVersion: "4.0.0.230", specId: null },
    { fwVersion: "4.0.0.230", specId: 1 },
    { fwVersion: "4.0.0.240", specId: 145 },
    { fwVersion: "4.0.0.8500", specId: null },
    { fwVersion: "4.0.0.8500", specId: 1 },
    { fwVersion: "4.0.0.8501", specId: null },
    { fwVersion: "4.0.0.8501", specId: 1 },
    { fwVersion: "4.1.0.0", specId: null },
    { fwVersion: "4.1.0.0", specId: 1 },
    { fwVersion: "4.1.0.1", specId: null },
    { fwVersion: "4.1.0.1", specId: 1 },
    { fwVersion: "4.1.0.80", specId: null },
    { fwVersion: "4.1.0.80", specId: 1 }
];

export { UNSUPPORTED_FIRMWARE_LIST };
