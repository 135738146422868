/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { MobileCardProps } from "./types";

import { HeadTitleInfo } from "../../components";
import { useTableFunctions } from "../../hooks";

import Checkbox from "../../../DesignComponents/Checkbox";
import InlineActionIconButton from "../../../DesignComponents/Button/InlineActionIconButton";

import ThemeContext from "../../../../context/theme/themeContext";
import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";
import TaskDetailsIcon from "../../../../assets/customIcons/actions/TaskDetailsIcon";
import {
    Column,
    parseResource,
    useCompanyLevelSettings
} from "../../../../shared";

const MobileCard = ({
    data,
    isChecked,
    isActiveHead,
    isFirst,
    isDisabled,
    onCheckboxChange
}: MobileCardProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark, textDarkDisabled, white }
    } = useContext(ThemeContext);

    const { user, isSystemUser } = useContext(AuthContext);

    const {
        tableName,
        columns,
        inlineActions,
        hasDetails,
        outerDetailsData,
        setDetailsData
    } = useContext(TableContext);

    const { t } = useTranslation();
    const { renderValue } = useTableFunctions();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const [isInlineBtnLoading, setInlineBtnLoading] = useState(false);

    const handleDetailsClick = () => setDetailsData(data);

    const actions = inlineActions.filter(
        ({ renderConditions }: any) =>
            renderConditions === undefined ||
            renderConditions({
                data,
                outerDetailsData: outerDetailsData?.detailsData || null,
                user,
                isSystemUser
            })
    );

    return (
        <div
            css={css({
                background: white,
                borderTop: isFirst ? "none" : `1px solid ${gray200}`,
                display: "flex"
            })}
        >
            {isActiveHead && (
                <div
                    css={css({
                        padding: "16px 8px 16px 16px"
                    })}
                >
                    <Checkbox
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={onCheckboxChange}
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            )}

            <div
                css={css({
                    flex: "1",
                    overflow: "hidden",

                    "& > div": {
                        display: "flex",
                        height: "56px",
                        boxSizing: "border-box",
                        borderBottom: `1px solid ${gray200}`,
                        padding: "0 24px"
                    }
                })}
            >
                {columns.map(
                    ({
                        name,
                        resource,
                        isBadge,
                        isActive,
                        isTranslatable,
                        customDateTimeFormat,
                        restriction
                    }: Column) => {
                        const isRestricted =
                            isFeatureSettingsRestricted(restriction);

                        const parsedValue = parseResource(resource, data);

                        const textColor =
                            isRestricted && parsedValue
                                ? textDarkDisabled
                                : textDark;

                        const value = renderValue(
                            name,
                            resource,
                            data,
                            customDateTimeFormat,
                            isTranslatable
                        );

                        return (
                            isActive && (
                                <div
                                    key={name}
                                    css={css({
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        columnGap: "8px"
                                    })}
                                >
                                    <div
                                        css={css({
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            overflow: "hidden",

                                            "& > div": {
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }
                                        })}
                                    >
                                        <div
                                            css={css({
                                                color: gray700,
                                                fontSize: "10px",
                                                fontWeight: "600",
                                                lineHeight: "12px",
                                                letterSpacing: "1px"
                                            })}
                                        >
                                            {t(`Table##${name}`)}
                                        </div>

                                        <div
                                            css={css({
                                                color: textColor,
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                letterSpacing: "0.1px",
                                                marginTop: isBadge ? "2px" : "0"
                                            })}
                                        >
                                            {value}
                                        </div>
                                    </div>

                                    <HeadTitleInfo
                                        restriction={restriction}
                                        columnWithAdditionalInfo={name}
                                        isLarge
                                    />
                                </div>
                            )
                        );
                    }
                )}

                {/* Inline actions */}
                {(actions.length > 0 || hasDetails) && (
                    <div
                        css={css({
                            flexDirection: "column",
                            justifyContent: "center"
                        })}
                    >
                        <div
                            css={css({
                                display: "flex",

                                "& > span": {
                                    display: "inline-flex",
                                    width: "100%"
                                },

                                "& > span + span": {
                                    marginLeft: "16px"
                                }
                            })}
                        >
                            {actions.map(
                                ({
                                    title,
                                    icon,
                                    hasLoader,
                                    clickAction,
                                    getDisabledText
                                }) => {
                                    const Icon = icon;

                                    const disabledText =
                                        getDisabledText &&
                                        getDisabledText(data);

                                    const onClick = () => {
                                        hasLoader && setInlineBtnLoading(true);

                                        clickAction(data, () =>
                                            setInlineBtnLoading(false)
                                        );
                                    };

                                    return (
                                        <InlineActionIconButton
                                            key={title}
                                            title={t(`General##${title}`)}
                                            icon={<Icon />}
                                            onButtonClick={onClick}
                                            disabledText={
                                                disabledText
                                                    ? t(
                                                          `General##${disabledText}`
                                                      )
                                                    : undefined
                                            }
                                            isLoading={
                                                hasLoader && isInlineBtnLoading
                                            }
                                        />
                                    );
                                }
                            )}

                            {hasDetails && (
                                <InlineActionIconButton
                                    title={t(
                                        `Table##show details##${tableName}`
                                    )}
                                    icon={<TaskDetailsIcon />}
                                    onButtonClick={handleDetailsClick}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileCard;
